export const footerMenuItems = [
    { footerName: "Home", footerLInks: "/" },
    { footerName: "About Us", footerLInks: "/about-us" },
    { footerName: "Brand Lidkar", footerLInks: "/lidkarBrand" },
    { footerName: "Contact Us", footerLInks: "/contact-us" },
  ];
  export const footerQuickLinks = [
    { name: "Accessories", id: 3 },
    { name: "Jackets", id: 7 },
    { name: "Bags", id: 2 },
    { name: "Footwear", id: 1 },
  ];
export const  FooterCustomerInfo = ["Privacy Policy", "Terms and Conditions", "Cancellation Policy", "Shipping and Delivery"]
export const UsefulLinks = ["FAQs", "Find the Nearest Store", "Track Your Order", "Download Mobile App"]
 


