import React, { useState } from 'react';
import { Button } from '../components/ui/button';
import { IoLocationOutline } from "react-icons/io5";
import { TbPhonePause } from "react-icons/tb";
import { CiMail } from "react-icons/ci";
import { FaClockRotateLeft } from "react-icons/fa6";
import { createContactUs } from '../services/operations/contactusApi';
import toast from 'react-hot-toast';
import { number } from 'zod';
import Flag from "react-world-flags";
import Select from 'react-select'


const CustomizedPage = () => {
  const [name, setName] = useState('');
  const [emailId, setEmailId] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [typingField, setTypingField] = useState(null)
  const [mobileNumberPlaceHolder, setMobileNumberPlaceHolder] = useState('Mobile Number')
  const [namePlaceHolder, setNamePlaceHolder] = useState('Name')
  const [emailPlaceHolder, setEmailPlaceHolder] = useState('Email')
  const [subjectPlaceHolder, setSubjectPlaceHolder] = useState('Subject')
  const [messagePlaceHolder, setMessagePlaceHolder] = useState('Message')
  const handleChange = (setValue, value, field) => {

    if(field=='mobileNumber'){
      if (/^\d*$/.test(value)) {
        setValue(value);
        setTypingField(field)
        setErrors(({ [setValue]: '' }));
      }
    }else{
      setValue(value);
      setTypingField(field)
      setErrors(({ [setValue]: '' }));
    }
  };
  const handleKeyDown = (e, setValue, field) => {
    const keyCode = e.keyCode || e.which;

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 37 ||
        keyCode === 39 ||
        keyCode === 46 ||
        keyCode === 110 ||
        keyCode === 190
      )
    ) {
      e.preventDefault();
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: 'Phone number must contain only digits',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: '',
      }));
    }
  };


  const validateForm = () => {
    const errors = {};

    // Regex for email with no additional text after .com
    const emailRegex = /^[^\s@]+@[^\s@]+\.com$/;
    const phoneRegex = /^[6-9]\d{9}$/;

    if (!name.trim()) {
      errors.name = 'Name is required';
      return errors;
    }
    if (!emailId.trim()) {
      errors.emailId = 'Email is required';
      return errors;
    } else if (!emailRegex.test(emailId)) {
      errors.emailId = 'Email is invalid';
      return errors;
    }
    if (!mobileNumber.trim()) {
      errors.mobileNumber = 'Phone number is required';
      return errors;
    } else if (!phoneRegex.test(mobileNumber)) {
      errors.mobileNumber = 'Phone number is invalid';
      return errors;
    }
    if (!subject.trim()) {
      errors.subject = 'Subject is required';
      return errors;
    }
    if (!message.trim()) {
      errors.message = 'Message is required';
      return errors;
    } else if (message.length > 100) {
      errors.message = 'Message must be less than 100 characters';
      return errors;
    } else if (message.length < 10) {
      errors.message = 'Message must be more than 10 characters';
      return errors;
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        await createContactUs(name, emailId, mobileNumber, subject, message);
        toast.success('Thank you for contacting us! Your message has been successfully sent. We will get back to you as soon as possible.');
        setName('');
        setEmailId('');
        setMobileNumber('');
        setSubject('');
        setMessage('');
        setErrors({});
      } catch (error) {
        toast.error('Something went wrong. Please try again later.');
      }
    }


  };
  const handleBlur = (placeholderSetter, value, initialValue) => {
    if (!value) {
      placeholderSetter(initialValue);
    }
  };
  const handleFocus = (placeholderSetter) => {
    placeholderSetter('');
  };
  const countries = [
    { value: "+1", label: "USA", code: "US" },
    { value: "+44", label: "UK", code: "GB" },
    { value: "+91", label: "India", code: "IN" },
    { value: "+61", label: "Australia", code: "AU" },
    { value: "+33", label: "France", code: "FR" },
    { value: "+49", label: "Germany", code: "DE" },
    { value: "+81", label: "Japan", code: "JP" },
    { value: "+34", label: "Spain", code: "ES" },
    { value: "+55", label: "Brazil", code: "BR" },
    { value: "+86", label: "China", code: "CN" },
    { value: "+7", label: "Russia", code: "RU" },
    { value: "+39", label: "Italy", code: "IT" },
    { value: "+31", label: "Netherlands", code: "NL" },
    { value: "+27", label: "South Africa", code: "ZA" },
    { value: "+64", label: "New Zealand", code: "NZ" },
    { value: "+351", label: "Portugal", code: "PT" },
    { value: "+41", label: "Switzerland", code: "CH" },
    { value: "+45", label: "Denmark", code: "DK" },
    { value: "+46", label: "Sweden", code: "SE" },
    { value: "+47", label: "Norway", code: "NO" },
    { value: "+48", label: "Poland", code: "PL" },
    { value: "+53", label: "Cuba", code: "CU" },
    { value: "+54", label: "Argentina", code: "AR" },
    { value: "+57", label: "Colombia", code: "CO" },
    { value: "+60", label: "Malaysia", code: "MY" },
    { value: "+62", label: "Indonesia", code: "ID" },
    { value: "+63", label: "Philippines", code: "PH" },
    { value: "+65", label: "Singapore", code: "SG" },
    { value: "+66", label: "Thailand", code: "TH" },
    { value: "+94", label: "Sri Lanka", code: "LK" },
    { value: "+98", label: "Iran", code: "IR" },
    { value: "+995", label: "Georgia", code: "GE" },
    { value: "+998", label: "Uzbekistan", code: "UZ" },
    { value: "+230", label: "Mauritius", code: "MU" },
    { value: "+264", label: "Namibia", code: "NA" },
    { value: "+355", label: "Albania", code: "AL" },
    { value: "+380", label: "Ukraine", code: "UA" },
    { value: "+356", label: "Malta", code: "MT" },
    { value: "+972", label: "Israel", code: "IL" },
    { value: "+353", label: "Ireland", code: "IE" },
    { value: "+254", label: "Kenya", code: "KE" },
    { value: "+502", label: "Guatemala", code: "GT" },
    { value: "+503", label: "El Salvador", code: "SV" },
    { value: "+504", label: "Honduras", code: "HN" },
    { value: "+505", label: "Nicaragua", code: "NI" },
    { value: "+506", label: "Costa Rica", code: "CR" },
    { value: "+507", label: "Panama", code: "PA" },
    { value: "+509", label: "Haiti", code: "HT" },
    { value: "+90", label: "Turkey", code: "TR" },
    { value: "+880", label: "Bangladesh", code: "BD" },
    { value: "+977", label: "Nepal", code: "NP" },
    { value: "+84", label: "Vietnam", code: "VN" },
    { value: "+93", label: "Afghanistan", code: "AF" },
    { value: "+964", label: "Iraq", code: "IQ" },
    { value: "+967", label: "Yemen", code: "YE" },
  ];
  const [selectedCountry, setSelectedCountry] = useState( countries.find((country) => country.code === "IN"));
  const handleChangeCountry = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };
  return (
    <div className="w-full px-[1rem] xl:px-[5rem] py-3">
      <div className=' mt-5 h-[700px] xl:h-[600px] p-3'>
        <div className='flex flex-col justify-center items-center'>
            <h1 className='text-2xl font-helvetica text-center  mb-1 md:text-3xl text-black-700'>Request For Customized Product</h1>
          <div className='bg-white border border-gray-300 rounded-lg p-6 mt-5 mx-auto inline-block w-full xl:w-[500px] '>
            <form className="" onSubmit={handleSubmit}>
              <div className="flex flex-col lg:flex-row gap-x-3 ">
                <div className="flex flex-col w-full">
                <label className="font-semibold text-base text-gray-700 mb-2" placeholder="Enter Password">
                Name <span className="text-red-500">*</span>
              </label>
                  <input
                    type="text"
                    value={name}
                    maxLength={50}
                    onChange={(e) => handleChange(setName, e.target.value, 'name')}
                    onFocus={() => handleFocus(setNamePlaceHolder)}
                    onBlur={() => handleBlur(setNamePlaceHolder, name, 'Name')}

                    className={` ${errors.name ? "border-red-500" : "border-gray-200"}  border p-2 placeholder-gray-400 pl-4`}
                    placeholder={namePlaceHolder}
                  />
                  {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
                  {typingField === "name" && (
                    <small className="text-gray-500 self-end">({name.length}/50)</small>
                  )}
                </div>
                <div className="flex flex-col w-full">
                <label className="font-semibold text-base text-gray-700 mb-2" placeholder="Enter Password">
                Email Id <span className="text-red-500">*</span>
              </label>
                  <input
                    type="text"
                    value={emailId}
                    maxLength='50'
                    onChange={(e) => handleChange(setEmailId, e.target.value, 'emailId')}
                    onFocus={() => handleFocus(setEmailPlaceHolder)}
                    onBlur={() => handleBlur(setEmailPlaceHolder, emailId, 'Email')}

                    className={` ${errors.emailId ? "border-red-500" : "border-gray-200"}  border p-2 placeholder-gray-400 pl-4`}
                    placeholder={emailPlaceHolder}
                  />
                  {errors.emailId && <p className="text-red-500 text-xs">{errors.emailId}</p>}
                  {/* {typingField === "emailId" && (
                    <small className="text-gray-500 self-end">({emailId.length}/50)</small>
                  )} */}
                </div>
              </div>
              <div className='flex flex-col gap-3 mt-1'>
              <label className="font-semibold text-base text-gray-700 " placeholder="Enter Password">
                Mobile Number <span className="text-red-500">*</span>
              </label>
              <div className='flex w-full'>
      <Select
        options={countries}
        value={selectedCountry}
        onChange={handleChangeCountry}
        defaultValue={countries.find((country) => country.code === "IN")} 
        styles={{
          control: (base) => ({
            ...base,
            // backgroundColor: "rgba(251, 211, 164, 0.7)",
            // border: "1px solid #F4B574",
            borderRadius:"0px",
            fontSize: "15px", 
            height: "42px",
            borderRight:"none",
            minHeight: "30px", 
            width: "70px",
          }),
          menu: (base) => ({
            ...base,
            width: "300px", 
             marginTop: "5px",
          }),
          valueContainer: (base) => ({
            ...base,
            height: "30px", 
            padding: "0 8px", 
          }),
          indicatorsContainer: (base) => ({
            ...base,
            height: "30px", 
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: "4px", 
          }),
          option: (base, { isFocused }) => ({
            ...base,
            backgroundColor: isFocused ? "#F4B574" : "#FFF",
            color: "#333",
            display: "flex",
            // width:"200px",
            alignItems: "center",
            fontSize: "12px", 
            padding: "5px 10px", 
          }),
        }}
        getOptionLabel={(e) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Flag
              code={e.code}
              style={{ width: "20px", height: "15px", marginRight: "10px" }}
            />
            {e.label} ({e.value})
          </div>
        )}
        getOptionValue={(e) => e.value}
      />
                <div className="flex flex-col w-full">
                  <input
                    type='text'
                    value={mobileNumber}

                    maxLength='10'
                    onChange={(e) => handleChange(setMobileNumber, e.target.value, 'mobileNumber')}
                    onFocus={() => handleFocus(setMobileNumberPlaceHolder)}
                    onBlur={() => handleBlur(setMobileNumberPlaceHolder, mobileNumber, 'PhoneNumber')}

                    onKeyDown={(e) => handleKeyDown(e, setMobileNumber, 'mobileNumber')}
                    className={` w-full ${errors.mobileNumber ? "border-red-500" : "border-gray-200"}  border p-2 placeholder-gray-400 pl-4`}
                    placeholder={mobileNumberPlaceHolder}
                  />
                  {errors.mobileNumber && <p className="text-red-500 text-xs">{errors.mobileNumber}</p>}
                  {typingField === "mobileNumber" && (
                    <small className="text-gray-500 self-end">({mobileNumber.length}/10)</small>
                  )}
    </div>

                </div>
                <div className="flex flex-col">
                <label className="font-semibold text-base text-gray-700 mb-2" placeholder="Enter Password">
                Subject <span className="text-red-500">*</span>
              </label>
                  <input
                    type="text"
                    value={subject}
                    maxLength='50'
                    onChange={(e) => handleChange(setSubject, e.target.value, 'subject')}
                    onFocus={() => handleFocus(setSubjectPlaceHolder)}
                    onBlur={() => handleBlur(setSubjectPlaceHolder, subject, 'Subject')}

                    className={` ${errors.subject ? "border-red-500" : "border-gray-200"}  border p-2 placeholder-gray-400 pl-4`}
                    placeholder={subjectPlaceHolder}
                  />
                  {errors.subject && <p className="text-red-500 text-xs">{errors.subject}</p>}
                  {typingField === "subject" && (
                    <small className="text-gray-500 self-end">({subject.length}/50)</small>
                  )}
                </div>
                <div className="flex flex-col">
                <label className="font-semibold text-base text-gray-700 mb-2" placeholder="Enter Password">
                Message <span className="text-red-500">*</span>
              </label>
                  <textarea
                    value={message}
                    onChange={(e) => handleChange(setMessage, e.target.value, 'message')}
                    onFocus={() => handleFocus(setMessagePlaceHolder)}
                    onBlur={() => handleBlur(setMessagePlaceHolder, message, 'Message')}

                    className="shadow appearance-none border  w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="message"
                    maxLength='150'
                    rows="5"
                    placeholder={messagePlaceHolder}
                  ></textarea>

                  {errors.message && <p className="text-red-500 text-xs">{errors.message}</p>}
                  {typingField === "message" && (
                    <small className="text-gray-500 self-end">({message.length}/150)</small>
                  )}
                </div>
                <Button
                  type="submit"
                  className="bg-[#F9C88F] hover:bg-[#F6B97B]  w-full px-2 py-2 rounded-[15px] mt-4 text-black"
                >
                  Send Message
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default CustomizedPage;
