import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";

const MoreInfoTab = () => {
  return (
    <>
      <div className="p-1 transition-all text-sm duration-1000 ease-in-out">
        <ul className="list-disc pl-5 space-y-1">
          <li>
            Imported And Packed by: LIDKAR (Dr.Babu JagjivanRam Leather Industries Development Corporation Ltd.)
          </li>
          <li>Country of Origin: India</li>
          <li className="flex flex-col">
            <li>

            Customer Care:
            </li>
            <div className="flex gap-1 items-center">
              <p className=" text-black">Email:</p>
            <a href="mailto:customercare@lidkar.com" className="text-blue-500 hover:underline">customercare@lidkar.com</a> 
              </div> 
            <div className="flex gap-1 items-center">
              <p className=" text-black">Phone Number:</p>
            <a  className="text-black">+91 84477 46959</a> 
              </div> 
            

            <div>

            No. 32, Dr. Babu Jagjivan Ram Lidkar Bhavana, Millers Bund Area, Vasantha Nagara, Bengaluru, Karnataka - 560 052
            
            {/* <a href="mailto:customercare@lidkar.com" className="text-blue-500 hover:underline"> customercare@lidkar.com</a> */}
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MoreInfoTab;
