import React,{useState,useEffect} from 'react';
import { getUserDetailsByVerifyToken } from '../../services/operations/authApi';
import { useNavigate,useParams } from 'react-router-dom';
import { BASE_URL } from '../../services/api';
import axios from 'axios';
const VerifyEmail = () => {
    const { verifyToken } = useParams();
    const [emailId,setEmailId]=useState('')
    const [loading, setLoading] = useState(null);
const navigate=useNavigate()


useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setLoading(true);
        

        const response = await getUserDetailsByVerifyToken(verifyToken);
        console.log(response)
        setEmailId(response); 
        
      
        if (response) {
          const verificationResponse = await axios.post(`${BASE_URL}/verifyUser`, {
            emailId:response, 
            token: verifyToken,
          });
          
            
        }
      } catch (err) {
       
      } finally {
        setLoading(false);
      }
    };
    fetchUserDetails()
},[verifyToken])


  const handleLoginRedirect = () => {
   navigate('/login')
  };

  return (
    <div className=" bg-gray-50 flex item-center justify-center my-4">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full  sm:w-[580px]">
        <div className="text-center">
        
          <div className="mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-16 h-16 mx-auto text-green-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>

       
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">
            Email Verified!
          </h2>
          <p className="text-lg text-gray-600 mb-6">
            Your email address has been successfully verified. Please login to your account to get started.
          </p>

        
          <button
            onClick={handleLoginRedirect}
            className="w-[200px] py-2 px-4 bg-green-600 text-white font-bold rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
          >
            Go to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
