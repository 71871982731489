import "./App.css";
import { Route, Routes,Navigate} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import RatingModel from "./components/models/ReviewModel"
import LoginPage from "./pages/LoginPage";
import FAQ from "./pages/FAQ";
import SignupPage from "./pages/SignupPage";
import PaymentFail from "./components/Checkout/PaymentFail";
import AdminLoginPage from "./pages/AdminLoginPage";
import AddToCartPage from "./pages/AddToCartPage";
import VerifyEmail from "./components/EmailVerification/VerifyEmail";
import OpenRoute from "./components/core/security/OpenRoute";
import PrivateAdminRoute from "./components/core/security/PrivateAdminRoute";
import PrivateRoute from "./components/core/security/PrivateRoute";
import TopBar from "./components/Home/TopBar";
import ProductPage from "./pages/ProductPage";
import Orders from "./pages/Orders"
import WhistListEmptyPage from "./pages/WhistListEmptyPage";
import WhistList from "./components/WhistList/WhistList";
import CheckoutPage from "./pages/CheckoutPage";
import Navbar from "./pages/Navbar";
import Footer from "./pages/Footer";
import VerifiyAccount from "./components/auth/Signup/VerifiyAccount";
import SingleProductPage from "./pages/SingleProductPage";
import WhistListPage from "./pages/WhistListPage";
import AllAddressPage from "./pages/AllAddressPage";
import CartPage from "./pages/CartPage";
import Stepper from "./components/Checkout/Stepper";
import ForgotPassword from "./components/auth/ForgotPassword/ForgotPassword";
import UserAccountPage from "./pages/UserAccountPage";
import ForgotPasswordVerify from "./components/auth/ForgotPassword/ForgotPasswordVerify";
import ContactUsPage from "./pages/ContactUsPage";
import OrderDetailsPage from "./pages/OrderDetailsPage";
import AccountDetailsPage from "./pages/AccountDetailsPage";
import OrderRecivedPage from "./pages/OrderRecivedPage";
import OrderTable from "./components/AccountDetails/OrderTable";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditionPage from "./pages/TermsAndConditionPage";
import ReadMorePage from "./components/Home/ReadMorePage";
import ShippingDelivery from "./pages/ShippingDelivery";
import CancelAndRefund from "./pages/CancelAndRefund";
import CustomizedPage from "./pages/CustomizedPage";
import FindStore from "./pages/FindStorePage";
import BrandLidkar from "./pages/BrandLidkar";
import { useLocation } from "react-router-dom";
import AdminHeader from "./components/Admin/AdminHeader";
import AdminDashboardPage from "./pages/AdminDashboardPage";
import OrderTrackingPage from "./pages/OrderTarckingPage";
import PickUp from "./components/Admin/PickUp";
import Label from "./components/Admin/Label";
import Gallery from "./pages/Gallery";
import LoginOtp from "./components/LoginOtp/LoginOtp";

function App() {
  const location = useLocation();
  const adminToken = localStorage.getItem("adminToken");
  const navigate=useNavigate()


  const isAdminRoute = location.pathname.startsWith("/admin");
  return (
    <div className="flex flex-col h-screen">
      {/* <TopBar /> */}
      {!isAdminRoute && <Navbar />}
      {isAdminRoute && <AdminHeader />} 
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<ReadMorePage />} />

        <Route
          path="/login"
          element={
            <OpenRoute>
              <LoginPage />
            </OpenRoute>
          }
        />
        <Route
          path="/login-otp"
          element={
            <OpenRoute>
              <LoginOtp />
            </OpenRoute>
          }
        />
        <Route
          path="/register"
          element={
            <OpenRoute>
              <SignupPage />
            </OpenRoute>
          }
        />
           <Route
          path="/faq"
          element={
         
              <FAQ />
            
          }
        />
           <Route
          path="/gallery"
          element={
         
              <Gallery />
            
          }
        />

        <Route
          path="/cart"
          element={
            <PrivateRoute>
              <Stepper />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment-fail"
          element={
            <PrivateRoute>
              <PaymentFail />
            </PrivateRoute>
          }
        />
        <Route path="shop/products" element={<ProductPage />} />
        <Route
          path="/emptyWhistList"
          element={
            <PrivateRoute>
              <WhistListEmptyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/order-tracking"
          element={
            <PrivateRoute>
              <OrderTrackingPage />
            </PrivateRoute>
          }
        />
         <Route path="admin/dashboard/pickup" element={<PickUp/>} />
         <Route path="admin/dashboard/label" element={<Label/>} />
        <Route
          path="/wishlist"
          element={
            <PrivateRoute>
              <WhistListPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/checkout"
          element={
            <PrivateRoute>
              <CheckoutPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/allAddress"
          element={
            <PrivateRoute>
              <AllAddressPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/verify-email/:verifyToken"
          element={
            <OpenRoute>
              <VerifyEmail />
            </OpenRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <OpenRoute>
              <ForgotPassword />
            </OpenRoute>
          }
        />
        <Route
          path="/admin"
          element={
              <AdminLoginPage/>
          }
        />
        <Route
          path="/shop/products/:productName"
          element={<SingleProductPage />}
        />
        <Route
          path="/my-account"
          element={
            <PrivateRoute>
              <UserAccountPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/forgot-password/:verifyToken"
          element={
            <OpenRoute>
              <ForgotPasswordVerify />
            </OpenRoute>
          }
        />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/findStore" element={<FindStore/>} />
        <Route path="/lidkarBrand" element={<BrandLidkar/>} />
        <Route path="/customized" element={<CustomizedPage />} />
        <Route
          path="/accountDetails/:id"
          element={
            <PrivateRoute>
              <AccountDetailsPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/orderConfirmed/my-order/:orderId"
          element={
            <PrivateRoute>
              <OrderRecivedPage />
            </PrivateRoute>
          }
        />
          <Route
          path="/rate-product"
          element={
            <PrivateRoute>
              <RatingModel />
            </PrivateRoute>
          }
        />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsAndCondition" element={<TermsAndConditionPage />} />
        <Route path="/shippingAndDelivery" element={<ShippingDelivery />} />
        <Route path="/cancelAndRefund" element={<CancelAndRefund />} />
            
         
        <Route
          path="/order-details"
          element={
            <PrivateRoute>
              <OrderDetailsPage />
            </PrivateRoute>
          }
        />
   
   <Route
          path="/admin/dashboard"
          element={
            <PrivateAdminRoute> 
           
              <AdminDashboardPage />
              </PrivateAdminRoute>
          
          }
        />
        <Route
          path="/my-orders"
          element={
            <PrivateRoute>
              <div className="mt-10 flex items-center justify-center mx-auto w-full h-[400px]">
                <OrderTable />
              </div>
            </PrivateRoute>
          }
        />
      </Routes>
      {!isAdminRoute && <Footer />}
    </div>
  );
}

export default App;
