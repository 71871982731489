import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
 
import { IoStar } from "react-icons/io5";
import { CreateRatings, CreateReview } from '../../services/operations/productApi';
import { useSelector } from 'react-redux';


const RateProductDialog = ({product,orderId}) => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const { token, user } = useSelector((state) => state.auth);
  console.log("user",user);
    console.log("product",product);
    console.log("orderId",orderId);
    const [text, setText] = useState(''); 

  const handleTextChange = (e) => {
    setText(e.target.value); 
  };
  const handleNext = async() => {
    if (step < 2) {
      setStep(step + 1);
    } else {
      try{

     
      const ratingObj = {
        rating:rating,
        userId:user.userId,
        productId:product.productId,
        orderId:orderId
      }
      const reviewObject = {
        review:text,
        userId:user.userId,
        productId :product.productId,
        orderId :orderId
      }
      console.log("ratingObj",ratingObj)
      console.log(reviewObject,"reviewObject")
      const ratingResponse = await CreateRatings(ratingObj,token);
      const reviewResponse = await CreateReview(reviewObject,token);
      console.log(ratingResponse);
      console.log(reviewResponse);
    }catch(error){
       console.log(error,"error")
    }
        setRating(0);
        setText("");
      setOpen(false);
    }
  };
  // useEffect(()=>{
  //      const fetchRatingAPi = ()=>{
  //       const ratingREsponse = CreateRatings(token);
  //       console.log(ratingREsponse,"ratingResponse");
  //      }
  //      fetchRatingAPi();
  // },[]);
 const ratingLabels = ["Horrible", "Bad", "Average", "Good", "Excellent"];
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <p className="w-[120px] whitespace-nowarp flex justify-center items-center h-[21px] border rounded-sm border-gray-300 text-base text-blue-600 p-3 cursor-pointer">
          Rate Product
        </p>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[660px] p-6">
        <DialogHeader>
          {step === 1 && (
            <div className="text-center">
              <h1 className="text-base font-helvetica font-semibold">Rate the Product</h1>
              <p className="text-[14px] mb-4">How did you find this product based on your usage?</p>
              <div className="flex gap-6 justify-center">
                {[...Array(5)].map((star, index) => {
                  const ratingValue = index + 1;
                  return (
                    <div key={index} className="flex flex-col items-center">
              <label>
                <input
                  type="radio"
                  name="rating"
                  value={ratingValue}
                  onClick={() => setRating(ratingValue)}
                  className="hidden"
                />
                <IoStar
                  className={`cursor-pointer text-2xl ${
                    ratingValue <= (hover || rating)
                      ? 'text-yellow-500'
                      : 'text-gray-300'
                  }`}
                  onMouseEnter={() => setHover(ratingValue)}
                  onMouseLeave={() => setHover(0)}
                />
              </label>
              <span className="text-sm text-gray-700 mt-1">
                {ratingLabels[index]}
              </span>
            </div>

                  );
                })}
              </div>
            </div>
          )}
          {step === 2 && (
            <div>
              <h1 className="text-base font-helvetica">Additional Feedback</h1>
              
              <textarea
        className="border border-gray-300 p-3 w-full"
        name="example"
        rows="5"
        cols="40"
        placeholder="Enter your text here..."
        value={text} 
        onChange={handleTextChange} 
      ></textarea>
              </div>
          )}
        </DialogHeader>
       
          <button
            onClick={handleNext}
            disabled={!rating}
            className={`flex ${!rating ? "text-gray-300":"text-blue-600"} justify-end items-center py-2 px-4  text-blue-600 rounded-md  focus:outline-none`}
          >
            
            {step === 2 ? 'Submit' : 'Next'}
          </button>
        
      </DialogContent>
    </Dialog>
  );
};

export default RateProductDialog;
