import { apiConnector } from "../apiConnector";
import { productsEndPoints } from "../api";
import { featuredProductEndPoints } from "../api";
import { specialPriceEndPoints } from "../api";

export const getAllProducts = async () => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_ALL_PRODUCTS
    );
    result = response?.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};
export const GET_ALL_FEATUREDPRODUCT = async () => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      featuredProductEndPoints.GET_FEATURED_PRODUCT
    );
    result = response?.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};
export const GET_ALL_CLUTCHES_AND_WALLETS=async()=>{
  let result=[]
  try{
  const response =await apiConnector(
    "GET",
    productsEndPoints.GET_ALL_CLUTCHES_WALLETS
  
  )
  result=response?.data
  return result
  }catch(error){
    console.log(error)
  }
}
export const GETALLCHAPPALS=async()=>{
  let result=[]
  try{
  const response =await apiConnector(
    "GET",
    productsEndPoints.GET_ALL_CHAPPALS
  
  )
  result=response?.data
  return result
  }catch(error){
    console.log(error)
  }
}
export const GetAllSpecialProducts = async () => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      specialPriceEndPoints.GET_SPECIAL_PRICE
    );
    result = response?.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};
export const getMenAllProducts = async () => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GETMENSPRODUCTS
    );
    result = response?.data;
   
  } catch (error) {
    console.log(error);
  }
  return result;
};
export const getWomenAllProducts = async () => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GETWOMENSPRODUCTS
    );
    result = response?.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};
export const getUniSexAllProducts = async () => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GETUNISEXPRODUCTS
    );
    result = response?.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};

export const getProductByProductName = async (productName) => {
  let result = null;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_PRODUCT_BY_PRODUCTNAME,
      {},
      {},
      {
        productName: productName,
      }
    );
    let data = response.data;
    result = data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getProductByProductId = async (productId) => {
  let result;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_PRODUCT_BY_PRODUCTID,
      {},
      {},
      {
        productId: productId,
      }
    );
    
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getProductByCategoryId = async (categoryId) => {
  let result = null;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_PRODUCT_BY_CATEGORYID,
      {},
      {},
      {
        categoryId: categoryId,
      }
    );
    let data = response?.data;
    
    result = data;
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getALLProductByCategoryId = async (categoryId) => {
  let result = null;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_ALLPRODUCTS_BY_CATEGORY,
      {},
      {},
      {
        categoryId,
      }
    );
    let data = response?.data;
   
    result = data;
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getALLProductBySubCategoryId = async (subCategoryId) => {
  let result = null;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_ALLPRODUCTBY_SUBCATEGORIES,
      {},
      {},
      {
        subCategoryId,
      }
    );
    let data = response?.data;

    result = data;
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getALLProductBySubSubCategoryId = async (subSubCategoryId) => {
  let result = null;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_PRODUCT_BY_SUB_SUB_CATEGORYID,
      {},
      {},
      {
        subSubCategoryId,
      }
    );
    let data = response?.data;
   
    result = data;
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};


export const getProductByMultipleCategoryId = async (categoryIds,token) => {
  let result = null;

  if (!Array.isArray(categoryIds)) {
    console.log('categoryIds must be an array');
  }
  const categoryIdsParam = Array.isArray(categoryIds) ? categoryIds.join(',') : '';

  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_PRODUCT_BY_MULTIPLE_CATEGORYID,
      {},
      {  Authorization: `Bearer ${token}`,},
      {
        categoryIds:categoryIdsParam
      }
    );
    let data = response?.data;
   
    result = data;
    return result;
  } catch (error) {
    console.log(error,'vasu');
    throw error;
  }
};

export const searchByProductName = async (productName) => {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.SEARCH_BY_PRODUCT_NAME,
      {},
      {},
      {
        name: productName,
      }
    );

    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getProductBySubCategoryId = async (subCategoryId) => {
  let result = null;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_PRODUCT_BY_SUB_CATEGORYID,
      {},
      {},
      {
        subCategoryId,
      }
    );
    let data = response?.data;
   
    result = data;
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
export const getRatingByProductId = async (productId,token) => {
  let result;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_RATING_BY_PRODUCT_ID,
      {},
      {Authorization: `Bearer ${token}`},
      {
        productId: productId,
      }
    );
    
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getRatingsAndReviewsByProductId = async (productId,token) => {
  let result;
  try {
    const response = await apiConnector(
      "GET",
      productsEndPoints.GET_RATINGS_AND_REVIEWS_BY_PRODUCT_ID,
      {},
      {Authorization: `Bearer ${token}`},
      {
        productId: productId,
      }
    );
    
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const CreateRatings = async (data,token) => {
  let result;
  try {
    const response = await apiConnector(
      "POST",
      productsEndPoints.RATE_THE_PRODUCT,
      data,
      {Authorization: `Bearer ${token}`},
      {
       
      }
    );
    
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const CreateReview = async (data,token) => {
  let result;
  try {
    const response = await apiConnector(
      "POST",
      productsEndPoints.REVIEW_THE_PRODUCT,
      data,
      {Authorization: `Bearer ${token}`},
      {
       
      }
    );
    
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

