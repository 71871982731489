import React, { useState, useEffect } from "react";
import { Button } from "../ui/button";
import ChangePasswordModel from "../models/ChangePasswordModel";
import { useSelector,useDispatch } from "react-redux";
import { updateProfile } from "../../services/operations/authApi";
import toast from "react-hot-toast";

import { getUserById } from "../../services/operations/authApi";
import { setUser } from "../../redux/slices/authSlice";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "../../components/ui/dialog";
import { FaPencil } from "react-icons/fa6";

const EditProfileModel = ({ setIsEditProfile, onProfileUpdate }) => {
    const [open, setOpen] = useState(false);
    const { token, user } = useSelector((state) => state.auth);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [alternativePhoneNumber, setAlternativePhoneNumber] = useState("");
    const [emailId, setEmailID] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [gender, setGender] = useState("");
    const [typingField, setTypingField] = useState(null);
    const [firstNamePlaceHolder, setFirstNamePlaceHolder] = useState("FirstName");
    const [lastNamePlaceHolder, setLastNamePlaceHolder] = useState("LastName");
    const [emailPlaceHolder, setEmailPlaceHolder] = useState("Email");
    const [phoneNumberPlaceHolder, setPhoneNumberPlaceHolder] =
      useState("PhoneNumber");
  
    const [alterNativePlaceHolder, setAlternativePlaceHolder] = useState(
      "Alternate Phone Number"
    );
  
    const [isEdit, setIsEdit] = useState(true);
    const [userId, setUserId] = useState();
    const [errors, setErrors] = useState({});
    const [userDetails, setUserDetails] = useState([]);
    const dispatch=useDispatch()
  
    useEffect(() => {
      fetchUser();
    }, [token, user]);
    const fetchUser = async () => {
      const response = await getUserById(user.userId);
  
      setUserDetails(response);
      
  
      setFirstName(response?.firstName);
      setLastName(response?.lastName);
      setPhoneNumber(response?.mobileNumber);
      setEmailID(response?.emailId);
      setUserId(response?.userId);
      setDateOfBirth(
        response?.dateOfBirth ? formatDate(response?.dateOfBirth) : ""
      );
      setGender(response?.gender || "");
      setAlternativePhoneNumber(response?.alternateMobileNumber);
     
    };
    const fetchUserRedux=async()=>{
      const response = await getUserById(user.userId);
      dispatch(setUser(response))
      localStorage.setItem("userData", JSON.stringify(response));
    }
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
  
    const handleEditProfile = async () => {
      const validationErrors = validateForm();
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        const response = await updateProfile(
          token,
          userId,
          firstName,
          lastName,
          emailId,
          phoneNumber,
          alternativePhoneNumber,
          dateOfBirth,
          gender
        );
        fetchUser();
        fetchUserRedux()
       
      
        setOpen(false)
        onProfileUpdate();
        
        setIsEditProfile(false);
       
      }
    };
    const validateForm = () => {
      const errors = {};
      const phoneRegex = /^[6-9]\d{9}$/;
      if (!firstName.trim()) {
        errors.firstName = "First Name Required";
        return errors;
      } else if (firstName.length > 50) {
        errors.firstName = "First Name should not exceed 50 characters";
        return errors;
      }
  
      if (!lastName.trim()) {
        errors.lastName = "Last Name Required";
        return errors;
      } else if (lastName.length > 50) {
        errors.lastName = "Last Name should not exceed 50 characters";
        return errors;
      }
  
      if (!phoneNumber.trim()) {
        errors.phoneNumber = "Phone Number Required";
      } else if (!phoneRegex.test(phoneNumber)) {
        errors.phoneNumber = "Invalid Phone Number";
        return errors;
      }
     
     
  
      return errors;
    };
    const handleChange = (setValue, value, field) => {
      if (field == "phoneNumber" || field == "alternativePhoneNumber") {
        if (/^\d*$/.test(value)) {
          setValue(value);
          setTypingField(field);
          setErrors({ [setValue]: "" });
        }
      } else {
        setValue(value);
        setTypingField(field);
        setErrors({ [setValue]: "" });
      }
    };
    const handleBlur = (placeholderSetter, value, initialValue) => {
      if (!value) {
        placeholderSetter(initialValue);
      }
    };
    const handleFocus = (placeholderSetter) => {
      placeholderSetter("");
    };
    const handleKeyDown = (e, setValue, field) => {
      const keyCode = e.keyCode || e.which;
  
      if (
        !(
          (keyCode >= 48 && keyCode <= 57) ||
          (keyCode >= 96 && keyCode <= 105) ||
          keyCode === 8 ||
          keyCode === 9 ||
          keyCode === 37 ||
          keyCode === 39 ||
          keyCode === 46 ||
          keyCode === 110 ||
          keyCode === 190
        )
      ) {
        e.preventDefault();
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "Phone number must contain only digits",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
      }
    };
  
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <p className="text-black  font-medium text-sm text-right cursor-pointer flex gap-2 items-center justify-center">Edit <FaPencil fontSize={14}/></p>
      </DialogTrigger>
      <DialogContent className="max-w-[350px] sm:max-w-[560px] ">
        <DialogHeader>
        <form>
          <div className="flex flex-col sm:flex-row justify-between gap-3 my-3">
            <div className="flex flex-col flex-1">
              <label className="font-semibold text-base text-gray-700 mb-1">
                First Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={firstName}
                maxLength="50"
                onChange={(e) =>
                  handleChange(setFirstName, e.target.value, "firstName")
                }
                className={`rounded-[7px] ${errors.firstName ? "border-red-500" : "border-gray-200"
                  }  border p-2 placeholder-gray-400 pl-4`}
                onFocus={() => handleFocus(setFirstNamePlaceHolder)}
                onBlur={() =>
                  handleBlur(setFirstNamePlaceHolder, firstName, "First Name")
                }
                placeholder={firstNamePlaceHolder}
              />
              {errors.firstName && (
                <p className="text-red-500 text-xs">{errors.firstName}</p>
              )}
              {typingField === "firstName" && (
                <small className="text-gray-500 self-end">
                  ({firstName.length}/50)
                </small>
              )}
            </div>
            <div className="flex flex-col flex-1">
              <label className="font-semibold text-base text-gray-700 mb-1">
                Last Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={lastName}
                maxLength="50"
                onChange={(e) =>
                  handleChange(setLastName, e.target.value, "lastName")
                }
                className={`rounded-[7px] ${errors.lastName ? "border-red-500" : "border-gray-200"
                  }  border p-2 placeholder-gray-400 pl-4`}
                onFocus={() => handleFocus(setLastNamePlaceHolder)}
                onBlur={() =>
                  handleBlur(setLastNamePlaceHolder, lastName, "Last Name")
                }
                placeholder={lastNamePlaceHolder}
              />
              {errors.lastName && (
                <p className="text-red-500 text-xs">{errors.lastName}</p>
              )}
              {typingField === "lastName" && (
                <small className="text-gray-500 self-end">
                  ({lastName.length}/50)
                </small>
              )}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-between gap-3 my-3">
            <div className="flex flex-col flex-1">
              <label className="font-semibold text-base text-gray-700 mb-1">
                Phone Number <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                maxlength="10"
                value={phoneNumber}
                onChange={(e) =>
                  handleChange(setPhoneNumber, e.target.value, "phoneNumber")
                }
                className={`rounded-[7px] ${errors.phoneNumber ? "border-red-500" : "border-gray-200"
                  }  border p-2 placeholder-gray-400 pl-4`}
                disabled={isEdit}
                placeholder={phoneNumberPlaceHolder}
              />
            </div>
            <div className="flex flex-col flex-1">
              <label className="font-semibold text-base text-gray-700 mb-1">
                Alternate Phone Number
              </label>
              <input
                type="text"
                maxlength="10"
                value={alternativePhoneNumber}
                onChange={(e) =>
                  handleChange(
                    setAlternativePhoneNumber,
                    e.target.value,
                    "alternativePhoneNumber"
                  )
                }
                onKeyDown={(e) =>
                  handleKeyDown(
                    e,
                    setAlternativePhoneNumber,
                    "alternativePhoneNumber"
                  )
                }
                className={`rounded-[7px] ${errors.alternativePhoneNumber
                    ? "border-red-500"
                    : "border-gray-200"
                  }  border p-2 placeholder-gray-400 pl-4`}
                onFocus={() => handleFocus(setAlternativePlaceHolder)}
                onBlur={() =>
                  handleBlur(
                    setAlternativePlaceHolder,
                    alternativePhoneNumber,
                    "Alternate Mobile Number"
                  )
                }
                placeholder={alterNativePlaceHolder}
              />
           
            </div>
          </div>
        
          <div className="flex flex-col my-3">
            <label className="font-semibold text-base text-gray-700 mb-1">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              value={emailId}
              onChange={(e) => setEmailID(e.target.value)}
              className={`rounded-[7px] border-gray-200  border p-2 placeholder-gray-400 pl-4`}
              placeholder="Email"
              disabled={isEdit}
            />
          </div>
          {/* <span className="flex justify-end ">
            <ChangePasswordModel />
          </span> */}
          <div>
            <Button
              type="button"
              onClick={handleEditProfile}
              className="bg-[#552038] hover:bg-[#411b2d] w-full px-2 py-2 rounded-[15px] mt-4 text-white"
            >
              Save Changes
            </Button>
          </div>
        </form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default EditProfileModel