import React from "react";
import { footerMenuItems } from "../static/footerItems";
import { footerFashionItems } from "../static/footerItems";
import { FooterCustomerInfo } from "../static/footerItems";
import { footerQuickLinks } from "../static/footerItems";
import { UsefulLinks } from "../static/footerItems";
import { MdPhone } from "react-icons/md";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { FaYoutube } from "react-icons/fa";

import { IoMail } from "react-icons/io5";
import { MdLocationOn } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF, FaInstagram, } from "react-icons/fa6";
import { PiCopyright } from "react-icons/pi";
import { Link, useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';
import Footer_Logo from "../../src/images/Footer_Logo.png"
import { FaRegCopyright } from "react-icons/fa6";
import FooterBg from "../images/Footer-BG.png";
import mobileFooter from "../images/mobileFooter.jpg";
import LikarLogo from "../images/logowhitelidkar.png"
const Footer = () => {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const handleNavigation = (path,state) => {
    window.scrollTo(0, 0);
    navigate(path, { state });
  };
  const handleUseFullLinks = (item) => {

    if (item === "Privacy Policy") {
      handleNavigation('/privacyPolicy');
    }
    if (item === "Terms and Conditions") {
      handleNavigation('/termsAndCondition');
    }
    if (item === "Shipping and Delivery") {
      handleNavigation('/shippingAndDelivery');
    }
    if (item === "Cancellation or Exchange") {
      handleNavigation('/cancelAndRefund');
    }
  }
  const handleItems = (item) => {

    if (item == "DR+") {

      handleNavigation(`/shop/products?id=${3}`);
    }
    if (item == "Shoes") {
      handleNavigation(`/shop/products?id=${1}`);
    }
    if (item == "Wallets") {
      handleNavigation(`/shop/products?id=${4}`);
    }
    if (item == "Bags") {
      handleNavigation(`/shop/products?id=${5}`);
    }
  }
  const handleQuickLinks = (items) => {
    if (items == "Home") {
      handleNavigation("/")
    }
    if (items === "About Us") {
      handleNavigation("/about-us")
    }
    if (items == "Brand Lidkar") {
      handleNavigation("/lidkarBrand");
    }
    if (items == "Contact Us") {
      handleNavigation("/contact-us");

    }
  }

  const handleUseFulLinks = (items) => {
    if (items === "Find Our Nearest Store") {
      handleNavigation("/findStore");
    } else if (items.includes('@')) {
      window.location.href = `mailto:${items.split(',')[1].trim()}`;
    } else {
     
    }
  }
  const getLinkHref = (item) => {
    console.log("ite")
    if (item === "Find the Nearest Store") {
      return "/findStore";
    } else if (item === "FAQs") {
      return "/faq";
    }
    else if (item === "Track Your Order"){
    
      return token ? "/accountDetails/3" : "/login";
    } else if (item.includes('@')) {
      return `mailto:${item.split(',')[1].trim()}`;
    } else {
      return "#";
    };
  }
  const getLinkHrefs = (item) => {
    if (item === "Privacy Policy") {
      return '/privacyPolicy';
    } else if (item === "Terms and Conditions") {
      return '/termsAndCondition';
    } else if (item === "Shipping and Delivery") {
      return '/shippingAndDelivery';
    } else if (item === "Cancellation or Exchange") {
      return '/cancelAndRefund';
    } else {
      return '#';
    }
  };

  const handleFooterQuickLinks = (index) => {
    const quickLink = footerQuickLinks[index];
    if (quickLink) {
      handleNavigation('/shop/products', { id: quickLink.id });
    

    }
  };
  const handleGallary = (e)=>{
    e.preventDefault()
    handleNavigation("/gallery")

  }

  return (
    <div className="relative max-w-[1920px] 1921px:mx-auto px-3 flex flex-col items-center justify-center mt-5 mb-0 bottom-0 h-[870px] md:h-[810px] lg:h-[350px] md:px-[5rem] ">
      <div className="absolute hidden md:flex inset-0 bg-cover bg-center md:py-8 md:p-2" style={{ backgroundImage: `url(${FooterBg})` }}></div>
      <div className="absolute flex md:hidden bg-cover   inset-0   bg-center md:py-8 md:p-2" style={{ backgroundImage: `url(${mobileFooter})` }}></div>

      <div className="relative flex w-full xl:px-[2.1rem] gap-6 sm:gap-0 lg:gap-6  flex-col  lg:flex-wrap h-[830px] md:h-[760px]   lg:h-[160px] md:w-full lg:gap-[30px] xl:gap-[45px] z-10 pl-2 lg:pl-0 lg:ml-[39px]">
      <div className=" md:py-5 lg:mb-3 mt-8 sm:mt-0 md:flex md:flex-col gap-2 md:items-start lg:items-start  relative h-full ">
          <img className="w-[110px] h-[70px] md:self-start lg:self-start  mb-2 md:mb-0" src={LikarLogo} alt="Lidkar Logo" />
          <p className="font-helvetica text-sm md:pr-2 mb-1 sm:mb-3 md:mb-0 md:text-center text-white whitespace-nowrap">FOLLOW US</p>
          <div className="flex items-center gap-6 sm:gap-4  md:pr-2 md:justify-start py-2 md:py-0">
            <Link to="https://www.facebook.com/Lidkarkarnataka/">
              <FaFacebookF className="cursor-pointer hover:text-[#FBD3A4] text-white" />
            </Link>
            <Link to="https://x.com/lidkar01?lang=en">
              <FaXTwitter className="cursor-pointer hover:text-[#FBD3A4] text-white" />
            </Link>
            <Link to="https://www.instagram.com/lidkarkarnataka/">
              <FaInstagram className="cursor-pointer hover:text-[#FBD3A4] text-white" />
            </Link>
            <Link to="https://www.youtube.com/@lidkarbangalore1513">
              <FaYoutube className="cursor-pointer text-[21px]  hover:text-[#FBD3A4] text-white" />
            </Link>
          </div>
          {/*<div className="py-7"></div>*/}
        </div>
        <div className="lg:py-4 flex gap-[2px] flex-col lg:pr-0  sm:mt-0">
          <h1 className="font-helvetica text-base leading-6 my-2 text-white font-medium">ABOUT</h1>
          {footerMenuItems.map((item, index) => (



            <a
              href={
                item.footerName === "Home"
                  ? "/"
                  : item.footerName === "About Us"
                    ? "/about-us"
                    : item.footerName === "Brand Lidkar"
                      ? "/lidkarBrand"
                      : item.footerName === "Contact Us"
                        ? "/contact-us"
                        : "#"
              }
              key={index}
              onClick={(e) => {
                e.preventDefault();
                handleQuickLinks(item.footerName)
              }
              }
              className="font-Montserrat hover:text-[#FBD3A4] text-white hover:font-medium cursor-pointer text-[14px]"
            >
              {item.footerName}
            </a>



          ))}
        </div>

        <div className="md:py-4 gap-[2px] flex flex-col">
          <h1 className="font-helvetica text-base leading-6 font-medium text-white my-2 ">QUICK LINKS</h1>
          {footerQuickLinks.map((item, index) => (


            <a
              href={`/shop/products?id=${item.id}`}
              key={index}
              className="font-Montserrat hover:text-[#FBD3A4] text-white hover:font-medium cursor-pointer text-[14px]"
              onClick={(e) => {
                e.preventDefault();
                handleFooterQuickLinks(index);
              }}
            >
              {item.name}
            </a>


          ))}

        </div>

        <div className="md:py-4 gap-[2px] flex flex-col md:pr-0 md:ml-0">
          <h1 className="font-helvetica text-base font-medium leading-6 my-2 text-white">USEFUL LINKS</h1>
          {UsefulLinks.map((items, index) => (

            <a
              href={getLinkHref(items)}
              key={index}
              className="font-Montserrat hover:text-[#FBD3A4] text-white hover:font-medium cursor-pointer text-[14px]"

            >

              {items}

            </a>


          ))}
        </div>

        <div className="md:py-4 gap-[2px] flex flex-col mb-7 sm:mb-0">
          <h1 className="font-helvetica font-medium text-base leading-6 text-white my-2 ]">CUSTOMER POLICIES</h1>
          {FooterCustomerInfo.map((item, index) => (


            <a
              href={getLinkHrefs(item)}
              key={index}

              className="font-Montserrat hover:text-[#FBD3A4] text-white hover:font-medium cursor-pointer text-[14px]"
              onClick={(e) => {
                e.preventDefault();
                handleUseFullLinks(item);
              }}
            >
              {item}
            </a>


          ))}

        </div>

       
      </div>
      <div className="relative w-full mt-5 md:text-center md:py-1 mb-2 z-20 mt-auto">
    <hr className="w-full border-t-0.3 border-white mb-1" />
    <div className="flex font justify-center text-[14px] font-Montserrat gap-3 w-full">
    <p
    className="text-white cursor-pointer"
    onClick={() => window.open('https://lidkar.com', '_blank', 'noopener,noreferrer')}
>
    Website <span className="ml-3">|</span>
</p>

        <p className="text-white cursor-pointer" onClick={handleGallary}>Gallery <span className="ml-3">|</span> </p>
        <p className="text-white cursor-pointer">Review <span className="ml-3">|</span> </p>
        <p className="text-white cursor-pointer">Feedback <span className="ml-3">|</span> </p>
        <p className="text-white cursor-pointer" onClick={() => window.location.href = "mailto:customercare@lidkar.com"}>Help</p>
    </div>
    {/* <span className="text-white mt-2 mb-2 font-Montserrat text-[12px] flex items-center justify-center md:text-[14px]">
        <FaRegCopyright className="ml-1 mr-1" />Lidkar, Government of Karnataka.
    </span> */}
    <span className="text-white mt-2 mb-2 font-Montserrat text-[12px] flex items-center justify-center md:text-[14px]">
  Copyright <FaRegCopyright className="ml-1 mr-1" /> 2024 by 
  <span className="ml-2">
    <Link to='https://sunplussoftware.com'>SUNPLUS SOFTWARE TECHNOLOGIES PVT LTD</Link>
  </span> 
  <span className="ml-2">All Rights Reserved.</span>
</span>
</div>

    </div>


  );
};

export default Footer;
