import { Navigate } from "react-router-dom";

const PrivateAdminRoute = ({ children }) => {
  const adminToken = localStorage.getItem("adminToken");

  if (adminToken) {
    return children;
  } else {
    return <Navigate to="/admin" />;
  }
};

export default PrivateAdminRoute;
