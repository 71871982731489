import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import Orders from "../../pages/Orders"; // Ensure this import path is correct
import logo from "../../images/logo.png";
import { FaBoxes, FaCashRegister } from "react-icons/fa";
import { LuLayoutDashboard } from "react-icons/lu";
import { FaAngleDown } from "react-icons/fa";
import { CgEditBlackPoint } from "react-icons/cg";
import Booking from "./Booking"; // Import your Booking component
import PickUp from "./PickUp";
import Tarif from "./Tarif";
import Label from "./Label"
export const sidebarItems = [
  {
    title: "Logistics",
    path: "/logistics",
    icon: <LuLayoutDashboard size={24} />,
    submenu: true,
    subMenuItems: [
      { title: "Orders", path: "/orders", icon: <CgEditBlackPoint size={20} title="Orders" /> },
      { title: "Booking", path: "/booking", icon: <FaCashRegister size={20} /> },

      // { title: "PickUp", path: "/pickUp", icon: <FaCashRegister size={20} title="booking" /> },

      { title: "Tarrif", path: "/tarrif", icon: <FaCashRegister size={20} /> },
      // { title: "Label", path: "/label", icon: <FaCashRegister size={20} /> },
    ],
  },
  {
    title: "Logout",
    icon: <MdLogout size={24} />,
  },
];

const AdminDashboard = ({ isCollapsed, onToggle }) => {
  const [activeSubItem, setActiveSubItem] = useState("Orders"); // Default to "Orders"

  return (
    <div className="flex h-screen w-full">
      {/* Sidebar */}
      <div className="w-[21%] bg-[#FBBA71] p-4 h-full overflow-y-auto text-black">
        <div className="flex flex-col space-y-6 w-full">
          {/* Sidebar Menu */}
          <div className="flex flex-col space-y-2">
            {sidebarItems.map((item, idx) => (
              <MenuItem
                key={idx}
                item={item}
                isCollapsed={isCollapsed}
                onSubItemClick={(title) => setActiveSubItem(title)} // Update active item on click
              />
            ))}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-4">
        {activeSubItem === "Orders" && <Orders />}
        {activeSubItem === "Booking" && <Booking />}
        {/* {activeSubItem === "PickUp" && <PickUp />} */}
        {activeSubItem === "Tarrif" && <Tarif />}
        {activeSubItem === "Label" && <Label />}
      </div>
    </div>
  );
};

const MenuItem = ({ item, isCollapsed, onSubItemClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    localStorage.removeItem("adminData");
    navigate("/admin");
  };

  const handleItemsClick = (e, title) => {
    e.preventDefault();
    if (title === "Logout") {
      handleLogout();
    } else {
      onSubItemClick(title); // Notify parent about submenu click
    }
    console.log("title", title);
  };

  const toggleSubmenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Link
        to={item.path || "#"}
        className="flex flex-row space-x-4 items-center px-2 py-2 rounded-lg text-black transition-opacity duration-300 ease-in-out"
        onClick={(e) => {
          if (item.submenu) {
            e.preventDefault();
            toggleSubmenu();
          } else {
            handleItemsClick(e, item.title);
          }
        }}
      >
        <span className="w-[2.1rem] h-[2.1rem] flex justify-center items-center">
          {item.icon}
        </span>
        <span className={`flex justify-between items-center w-full`}>
          {item.title}
          {item.submenu && (
            <span className={`ml-auto ${isOpen ? "rotate-180" : ""}`}>
              <FaAngleDown />
            </span>
          )}
        </span>
      </Link>

      {item.submenu && isOpen && (
        <div className="ml-8 space-y-2">
          {item.subMenuItems.map((subItem, index) => (
            <Link
              to={subItem.path || "#"}
              key={index}
              className="flex flex-row space-x-2 items-center px-2 py-2 rounded-lg text-gray-700 transition-opacity duration-300 ease-in-out"
              onClick={(e) => handleItemsClick(e, subItem.title)}
            >
              <span className="w-[2.1rem] h-[2.1rem] flex text-black justify-center items-center">
                {subItem.icon}
              </span>
              <span className="text-black">{subItem.title}</span>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
