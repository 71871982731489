import React, { useEffect, useState } from "react";
import { featuredProduct } from "../../../data/categories";
import { IoBag } from "react-icons/io5";
import { Card } from "../../ui/card";

import { GET_ALL_FEATUREDPRODUCT, getAllProducts } from "../../../services/operations/productApi";
import { Link, useNavigate } from "react-router-dom";
import { SkeletonCard } from "../../Skeletoncard";
import heartIcon from "../../../images/heart.png";
import  colorHeart from "../../../images/colorHeart.png"
import  Image2 from "../../../images/HIHE-01.png"
import  Image4 from "../../../images/HIHE-02.png"
import  Image1 from "../../../images/HIHE-03.png"
import  Image3 from "../../../images/HIHE-04.png"
import { MdCurrencyRupee } from "react-icons/md";

import {
  addProductToWishlist,
  getAllWishlistsByUser,
  removeSingleWishList,
} from "../../../services/operations/wishlistApi";
import {
  addItemToCart,
  decrementCartItem,
  getCartItemsByUserAndStatus,
  getCartItemsByUserAndStatusAndProducts,
  incrementCartItem,
} from "../../../services/operations/cartItemApi";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../ui/alert-dialog";

import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../ui/button";
import toast from "react-hot-toast";
import { setCartCount, setCartData } from "../../../redux/slices/cartSlice";
import { mdCurrencyRupee } from "react-icons/md";
import {
  setWishlisData,
  setWishlistCount,
} from "../../../redux/slices/wishlistSlice";
const FeaturedProduct = () => {
  const dispatch = useDispatch();
  const { wishlistData } = useSelector((state) => state.wishlist);
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [products, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [wishlistData1, setWishlistData1] = useState([]);
  const [sizeId, setSizeId] = useState(null);
  const [colorId, setColorId] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const img=[{url:Image1},{url:Image2},{url:Image3},{url:Image4}]


  const fetAllProducts = async () => {
    try {
      setLoading(true);
      const response = await GET_ALL_FEATUREDPRODUCT();
   
    
      setAllProducts(response);
    
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(()=>{
    getAllWishlists()
  },[])
  const getAllWishlists = async () => {
    try {
      const response = await getAllWishlistsByUser(token);
      
      setWishlistData1(response);
      dispatch(setWishlisData(response));
      dispatch(setWishlistCount(response?.length));
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        setCartLoading(true);
        const response = await getCartItemsByUserAndStatus(token);
       
        setCartItems(response);
        dispatch(setCartData(response));
        dispatch(setCartCount(response?.length));
        setCartLoading(false);
      } catch (error) {
        console.log(error);
      }
      setCartLoading(false);
    };
    if (token) {
      fetchData();
    }
  }, [token]);


  const addToCart = async (productId, sizeId, colorId) => {
    if (!sizeId || !colorId) {
      toast.error("Please Select Size and color");
      return;
    }
    if (!sizeId) {
      toast.error("Please Select Size");
      return;
    }
    if (!colorId) {
      toast.error("Please Select Color");
      return;
    }
    try {
      setCartLoading(true);
      const response = await addItemToCart(productId, sizeId, colorId, token);
      const response1 = await getCartItemsByUserAndStatusAndProducts(token);
    
      dispatch(setCartData(response1));
      dispatch(setCartCount(response1?.length));
      setCartItems((prevItems) => {
        if (!Array.isArray(prevItems)) {
          return [response];
        }
        const existingItem = prevItems.find(
          (item) => item?.productId === productId
        );
        if (existingItem) {
          return prevItems.map((item) =>
            item?.productId === productId ? response : item
          );
        } else {
          return [...prevItems, response];
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  const increaseQuantity = async (cartItemId) => {
    try {
      setCartLoading(true);
      const response = await incrementCartItem(cartItemId, token);
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.cartItemId === cartItemId ? response : item
        )
      );
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  const decreaseQuantity = async (cartItemId) => {
    try {
      setCartLoading(true);
      const response = await decrementCartItem(cartItemId, token);
      const response1 = await getCartItemsByUserAndStatusAndProducts(token);
   
      dispatch(setCartData(response1));
      dispatch(setCartCount(response1?.length));
      if (response.quantity === 1) {
        window.location.reload();
      } else {
       
        setCartItems((prevItems) =>
          prevItems.map((item) =>
            item.cartItemId === cartItemId ? response : item
          )
        );
      }
    
      dispatch(setCartData(response1));
      dispatch(setCartCount(response1?.length));
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  const fetchCartItemsData = async () => {
    try {
      setCartLoading(true);
      const response = await getCartItemsByUserAndStatus(token);
      setCartItems(response);
      dispatch(setCartData(response));
      dispatch(setCartCount(response.length));
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  useEffect(() => {
    fetchCartItemsData();
  }, [token, dispatch]);

  useEffect(() => {
    fetAllProducts();
  }, []);

  const navigateToAllProducts = () => {
    navigate("/shop/products");
    window.scrollTo(0, 0);
  };
  const isProductInWishlist = (productId) => {
    return wishlistData?.some((item) =>
      item.products?.some((product) => product?.productId === productId)
    );
  };
 

  const navigateToSingleProduct = (productId) => {
    navigate(`/shop/products/${productId}`);
    window.scrollTo(0,0);
  };
  const addToWislist = async (productId) => {
    try {
      if (!token) {
        toast.error("Please Login First");
        navigate("/login");
        window.scrollTo(0, 0);
        return;
      }

   
      const response = await addProductToWishlist(token, productId);

      const response1 = await getAllWishlistsByUser(token);
      dispatch(setWishlisData(response1));
      dispatch(setWishlistCount(response1?.length));
    } catch (error) {
      console.log(error);
    }

  };
  
  const handleRemoveSigleWishList = async (wishListId) => {
    try {
     
      await removeSingleWishList(token, wishListId);
      const response = await getAllWishlistsByUser(token);
      setWishlistData1(response);
      const response1 = await getAllWishlistsByUser(token);
      dispatch(setWishlisData(response1));
      dispatch(setWishlistCount(response1?.length));
    } catch (error) {
      console.log(error);
    }
  };
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
 useEffect(()=>{

 
  // Add event listener for resizing the window
  window.addEventListener("resize", handleResize);

  // Cleanup on unmount
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

   // Determine the number of items to show based on screen size
   let sliceAmount = 4; // Default for 'xl' and larger
   if (screenWidth > 873 && screenWidth < 1280) {
     sliceAmount = 3; // For 'lg' screens (less than 1024px)
   }else if(screenWidth < 600){
     sliceAmount = 4
   }

  return (
    <div className=" max-w-[1920px] mx-auto px-[0px] sm:px-[5rem] py-3 sm:py-0  ">
      <div className="relative  sm:gap-98 sm:gap-0">
        <div className="">
    
              <h1 className="font-orator mt-7 text-[#6C3516] mb-3 items-center text-4xl 1441px:mt-3 leading-60 flex justify-center">
    <p className="font-orator text-[40px] mb-1 ">F</p>
    <p className="font-orator space-x-0 tracking-tighter text-[27px] sm:text-[33px] mr-2 ">EATURED PRODUCTS</p>
  </h1>
          <div className=" sm:px-0 flex gap-[30px] flex-wrap justify-center sm:p-2 lg:gap-[50px] ">
            
             
           
            {loading || products?.length < 1
              ? Array.from({ length: 4 }).map((_, index) => (
                  <div
                    key={index}
                    className="w-[240px] relative     h-[420px] mb-12 flex flex-col items-center  sm:flex-1 p-0   sm:mt-10"
                  >
                    <SkeletonCard
                      isAllProducts={true}
                      circle={false}
                      height="h-[370px] 1441px:h-[220px]"
                      width="w-[260px] 1441px:w-[320px]"
                      key={index}
                      className="w-[260px] h-[370px] rounded-sm bg-[#ebc4a3]"
                    />
                  </div>
                ))
              : products?.slice(0,sliceAmount)?.map((ele, index) => {
                  return (
                    <div
                      key={ele?.productId}
                      className="w-[320px]  sm:w-[240px]    h-[420px] flex flex-col items-center  sm:flex-1 p-0"
                    >
                       
                      <Card  style={{
    background: 'linear-gradient(to bottom, rgba(206, 203, 202, 0.5) 0%, #F7F7F6 100%)',}}
                      className="border-none relative shadow-none  cursor-pointer sm:w-[285px] w-[320px] h-[390px] rounded-none    flex items-center justify-center align-center ">
                     
                            <img
                            onClick={() =>
                              navigateToSingleProduct(ele?.productId)
                            }
                            src={img[index]?.url}
                            key={index}
                            alt="categoryImage"
                            className="w-[70%] h-[60%] object-fit mx-auto "
                          />

<div className="flex  mt-2 justify-center items-center  rounded-full p-1 sm:p-2 mr-0">
                {isProductInWishlist(ele?.productId) ? (
                  <>
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        {/* <FaHeart
                          fontSize={19}
                          title="Remove From Wishlist"
                          color="#E61F7F"
                        /> */}
                        <div className="absolute top-[10px] right-[19px] " >

                        <img src={colorHeart} className="w-[1rem] h-[1rem]" />
                        </div>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            Are you absolutely sure To Remove from Wislist?
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            This action cannot be undone. This will permanently
                            delete your account and remove your data from our
                            servers.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction
                            className="bg-red-500 hover:bg-red-800 text-white"
                            onClick={() =>
                              handleRemoveSigleWishList(
                                wishlistData.find((item) =>
                                  item.products.some(
                                    (product) =>
                                      product.productId === ele.productId
                                  )
                                ).wishListId
                              )
                            }
                          >
                            Remove
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </>
                ) : (
                  <div  className="absolute top-[10px] right-[19px]"  onClick={() => addToWislist(ele?.productId)}>

                  <img src={heartIcon} className="w-[1rem] h-[1rem]"/>

                  </div>

                 
                )}
              </div>
                      
                      </Card>
                      

                      <p className="text-[#303030] h-[50px] sm:h-[30px] mt-2 text-[18px] font-orator font-[500]  self-center  ">
                        {ele.productName.length > 28
                          ? ele.productName.substring(0, 28) + "..."
                          : ele.productName}
                      </p>
                      <p className="sm:py-3 sm:mt-5 font-helvetica flex font-bold text-[14px] self-center text-[#303030]">
                      <MdCurrencyRupee className="mt-[3px]"/>
                        {ele?.sellingPrice}
                      </p>
                      
                     
                    </div>
                  );
                })}

            
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProduct;
