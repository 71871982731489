import { toast } from "react-hot-toast";
import { setLoading, setToken, setUser } from "../../redux/slices/authSlice";
import { apiConnector } from "../apiConnector";
import { endpoints } from "../api";
import { useNavigate } from "react-router-dom";

export const signUp = (
  firstName,
  lastName,
  mobileNumber,
  emailId,
  password
) => {
  
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", endpoints.SIGNUP_API, {

        firstName,
        lastName,
        emailId,
        mobileNumber,
        password,
      });
     
      dispatch(
        setUser({
          emailId: response.data.emailId,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          mobileNumber: response.data.mobileNumber,
        })
      );
     
        toast.success("Email Sent. Please Verify Your Email");
     
    
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      
        toast.error(error?.response?.data?.message);
     
    
      dispatch(setLoading(false));
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
};

export const getUserDetailsByVerifyToken = async (verifyToken) => {
  let result;
  try {
    const response = await apiConnector(
      "GET",
      endpoints.GET_USERDETAILS_BY_VERIFY_TOKEN,
      {},
      {},
      { verifyToken: verifyToken }
    );
   
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const verifyUser = async (emailId, password, token, navigate) => {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", endpoints.VERIFY_USER, {
        emailId: emailId,
        password: password,
        token: token,
      });
    
      dispatch(setToken(response.data.accessToken));
      dispatch(setUser(response.data));
      localStorage.setItem("token", JSON.stringify(response.data.accessToken));
      localStorage.setItem("userData", JSON.stringify(response.data));
      dispatch(setLoading(false));
      toast.success("Login Successful");
      navigate("/");
      toast.success("Email Verified!. Login Successful");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
};

export const login = (emailId, password, navigate) => {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        endpoints.LOGIN_API,
        {},
        {},
        { emailId, password }
      );

      dispatch(setToken(response.data.accessToken));
      dispatch(setUser(response.data));
      localStorage.setItem("token", JSON.stringify(response.data.accessToken));
      localStorage.setItem("userData", JSON.stringify(response.data));
      dispatch(setLoading(false));
      toast.success("Login Successful");
      navigate("/");
    } catch (error) {
      
      setTimeout(() => {
        toast.error(error.response.data.message);
      }, 3000);
      
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
};

export const forgotPassword = async (emailId) => {
  // const toastId = toast.loading("Sending Email...");
  try {
    const response = await apiConnector(
      "GET",
      endpoints.FORGOT_PASSWORD,
      {},
      {},
      { emailId: emailId }
    );
    const result = response.data;
    // toast.success(`Email Sent to ${emailId}`);
    return result;
  } catch (error) {
    console.error("Error sending email:", error);
    throw error
    // toast.error("Failed to send email. Please try again.");
  } finally {

  }
};

export const forgotPasswordVerify = async (password, verifyToken, navigate) => {
  let result = null;
  try {
    const response = await apiConnector(
      "POST",
      `${endpoints.FORGOT_PASSWORD_VERIFY}`,
      {
        token: verifyToken,
        password: password,
      }
    );
   
    if (response) {
      toast.success("Password Updated");
    }
    result = response.data;
    // navigate("/login");
    return result;
  } catch (error) {
    console.error(error);
    toast.error(
      error?.response?.data?.message ||
        "An error occurred while updating password"
    );
  }
};

export const resetPassword = async (oldPassword, newPassword, token) => {
  let result = null;
 
  try {
    const response = await apiConnector(
      "POST",
      endpoints.CHANGE_PASSWORD,
      {},
      {
        Authorization: `Bearer ${token}`,
      },
      { oldPassword: oldPassword, newPassword: newPassword }
    );
   
    if (response) {
      toast.success("Password Changed");
    }
    result = response.data;
    return result;
  } catch (error) {
    console.error(error);
    toast.error(
      error?.response?.data?.message ||
        "An error occurred while updating password"
    );
  }
};

export const updateProfile = async (
  user_token,
  userId,
  firstName,
  lastName,
  emailId,
  mobileNumber,
  alternateMobileNumber,
  dateOfBirth,
  gender
) => {
  let result = [];
 
  try {
    const response = await apiConnector(
      "PUT",
      endpoints.EDIT_PROFILE_DETAILS,
      {
        firstName,
        lastName,
        emailId,
        mobileNumber,
        alternateMobileNumber,
        dateOfBirth,
        gender,
      },
      {
        Authorization: `Bearer ${user_token}`,
      },
      { userId: userId }
    );
    result = response?.data;
    toast.success("Profile Updated");
    return result;
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong. Please Try Again");
  }
};
export const getUserById = async (userId) => {
  if(!userId) return;
  let result;
  try {
    const response = await apiConnector(
      "GET",
      endpoints.GET_USER_BY_ID,
      {},
      {},
      { userId: userId }
    );
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};
