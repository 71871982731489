import React, { useEffect, useState } from "react";
import { CiHeart } from "react-icons/ci";

import { IoMdClose } from "react-icons/io";

import { FaAngleRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../ui/button";

import {
  getCartItemsByUserAndStatusAndProducts,
  removeItemFromCart,
  decrementCartItem,
  incrementCartItem,
} from "../../services/operations/cartItemApi";
import LoadingPage from "../../pages/LoadingPage";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import PaymentSummary from "./PaymentSummary";
import { useNavigate } from "react-router-dom";
import { getCartItemsByUserAndStatus } from "../../services/operations/cartItemApi";
import { Link, Navigate } from "react-router-dom";
import SelectSizeModel from "../models/SelectSizeModel";
import { setCartCount, setCartData } from "../../redux/slices/cartSlice";
import SelectColorModel from "../models/SelectColorModel";
import { Card } from "../ui/card";
const BagStep = ({
  setCurrentStep,
  token,
  cartDetails,
  setCartDetails,
  cartLoading,
  setCartLoading,
  calculateTotalPrice,
  fetchData,
  gstPrice,
  sellingPrice,
}) => {
 console.log(cartDetails,'cart details')

  const colorMap = {
    "Dark Brown": "#5C4033",
    "Dark Blue": "#00008B",
    "Dark Green": "#006400",
    "White": "#FFFFFF",
    "Tan Brown": "#A52A2A",
    "Maroon": "#800000"
  };
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const [cartDisable,setCartDisable]=useState(false)

  const handleRemoveFromCart = async (cartItemId) => {
    try {
      setCartLoading(true);
      const response = await removeItemFromCart(token, cartItemId);
     
      const response1 = await getCartItemsByUserAndStatusAndProducts(token);
      setCartDetails(response1);
      dispatch(setCartData(response1));
      dispatch(setCartCount(response1?.length));
      setCartLoading(false);
    } catch (error) {
      console.log(error);
      setCartLoading(false);
    }
  };
  const decreaseQuantity = async (cartItemId) => {
    try {
      setCartDisable(true)
      const response = await decrementCartItem(cartItemId, token);
      if (response.quantity === 0) {
        window.location.reload();
      } else {
        // Update the cart item normally
        const response1 = await getCartItemsByUserAndStatusAndProducts(token);
        setCartDetails(response1)
      }
      setTimeout(()=>{
        setCartDisable(false)
      },3000)
    } catch (error) {
      console.log(error);
    }
  };
  const increaseQuantity = async (cartItemId) => {
    try {
      setCartDisable(true)
      const response = await incrementCartItem(cartItemId, token);
    
      const response1 = await getCartItemsByUserAndStatusAndProducts(token);
      setCartDetails(response1)
      console.log(response1,'vasu')
      setTimeout(()=>{
        setCartDisable(false)
      },3000)
    } catch (error) {
      console.error(error);
    }
  };
  const handleContinue = ()=>{
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentStep(2)
  }
  

  return cartLoading ? (

    <div className="flex items-center justify-center mt-44 pt-24">
      <LoadingPage />
    </div>
  ) : (
    
    <div className="grid grid-cols-1  xl:grid-cols-2 lg:grid-cols-1 lg:grid-cols-1 gap-0  sm:gap-16  max-w-[1920px] mx-auto lg:px-[0rem] lg:py-3">
      <div className="flex lg:w-[550px]">
        <div className="flex flex-col w-full">
          <div className="flex gap-9 items-center">
            <p className="font-semi-bold text-[#303030] text-[16px] sm:text-[19px] mt-3 sm:mb-3 sm:mt-0 px-6 sm:px-3 lg:px-0 font-helvetica ">
              {cartDetails?.length} ITEMS SELECTED
            </p>
          </div>
          <div className="flex flex-col space-y-3 max-h-[500px] lg:p-0  overflow-y-scroll  scrollbar w-full p-3">
            {cartDetails?.map((item, index) => (
              <Card
  key={index}
  className="flex flex-col lg:w-[530px] lg:flex-row justify-between relative  h-auto lg:h-[300px] p-3"
>
  <div className="flex lg:flex-1 flex-col lg:flex-row">
    <div onClick={()=>navigate(`/shop/products/${item.productDTO?.productId}`)} className="flex items-center justify-center lg:m-2 w-full lg:w-[30%] cursor-pointer">
      <img
        src={item?.productDTO?.productImage[0]?.imagePath}
        alt="Product"
        className="object-contain w-full w-[10rem] h-[10rem] lg:w-32 lg:h-32"
      />
    </div>
    <div className="flex lg:flex-1 flex-col lg:mx-2 lg:mt-4 lg:mt-0">
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <div className="self-end cursor-pointer">
            <IoMdClose fill="black" size={20}  className="absolute top-[10px] right-[10px]" />
          </div>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              Item from the Cart
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              className="bg-red-600 hover:bg-red-700 text-white"
              onClick={() => handleRemoveFromCart(item.cartItemId)}
            >
              Remove
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <p className="font-orator text-[#303030] text-[16px]">
        {item.productDTO?.productName.length > 20
          ? item.productDTO?.productName.substring(0, 20) + "..."
          : item.productDTO?.productName}
      </p>
      <p className="text-gray-600 text-lg font-basicommercial">
        {item?.productDTO?.description.length > 60
          ? `${item?.productDTO?.description?.substring(0, 60)}...`
          : item?.productDTO?.description}
      </p>
      <div className="flex gap-3 mt-3">
      { item?.productDTO?.categoryDTO?.categoryName=='Bags' || item?.productDTO?.productId==104 || item?.productDTO?.productId==107 || item?.productDTO?.productId==114 || item?.productDTO?.subSubCategoryName=='Wallet' || item?.productDTO?.subSubCategoryName=='Waist Belts' || item?.productDTO?.subSubCategoryName=='Card Holder'  ?(
    <></>
    ):
    (<>
                 <p className="text-[#bdaeae] text-[19px] font-prompt">
            <SelectSizeModel
              cartDetails={cartDetails}
              cartItemId={item.cartItemId}
              sizeDTO={item.sizeDTO}
              colorDTO={item.colorDTO}
              fetchAgain={fetchData}
            />
          </p>
         
          </>
       
)}
        <div className="w-[25px] h-[21px] px-16 py-5 border border-[#D9D9D9] rounded flex items-center justify-center gap-10">
          <p className="text-[#514F4F] text-[19px] font-prompt ">
            <SelectColorModel
              cartDetails={cartDetails}
              cartItemId={item?.cartItemId}
              colorDTO={item?.colorDTO}
              fetchAgain={fetchData}
            />
          </p>
          <div
           style={{ background: colorMap[item?.colorDTO?.colorName] || item?.colorDTO?.colorName }}
            
            className="w-[18px] p-3 h-[18px] rounded-full border border-[#DDDDDD] flex justify-center items-center"
          ></div>
        </div>
      </div>
      <div className="flex gap-3 mt-2">
        <p className=" font-Barlow">Quantity : </p>
        <p>{item?.quantity}</p>
      </div>

      <div
        key={index}
        className="flex items-center justify-between cursor-pointer w-[150px] text-black gap-3 bg-[#F9C88F]  px-2 py-[2px] mt-2 rounded"
      >
        <Button
          className="bg-[#F9C88F] shadow-none h-7 sm:h-9 text-black hover:bg-[#F9C88F]"
          onClick={() => decreaseQuantity(item.cartItemId)}
          disabled={cartDisable}
        >
          -
        </Button>
        <span>{item.quantity}</span>
        <Button
          className="bg-[#F9C88F] shadow-none h-7 sm:h-9 text-black hover:bg-[#F9C88F]"
          onClick={() => increaseQuantity(item.cartItemId)}
          disabled={cartDisable}
        >
          +
        </Button>
      </div>
      <div className="flex justify-start gap-5 items-center pt-3 mb-3">
        <p className="font-prompt font-bold text-[#000000] text-[19px]">
          ₹ {item?.productDTO?.sellingPrice}
        </p>
        <p className="font-prompt text-red-500">
          {item?.productDTO?.discount !== 0 && (
            <span className="flex gap-3 text-[#514F4F]">
              MRP{" "}
              <p className="line-through text-red-400">
                {item?.productDTO?.mrpPrice}
              </p>
            </span>
          )}
        </p>
      </div>
    </div>
  </div>
</Card>

            ))}
          </div>
          <div className="flex justify-end p-3 lg:p-0">
          <Link
            to={"/wishlist"}
            className="w-full h-[50px] border border-[#D9D9D9] flex mt-0 lg:mt-3 bg-[#F4F4F4] flex justify-between items-center px-2"
          >
            <div className="flex justify-start items-center gap-3 ">
              <p className="font-prompt text-[#000000] text-[15px] ">
                Add More From Wishlist
              </p>
              <CiHeart color="#E61F7F" fontSize={21} />
            </div>
            <FaAngleRight fontSize={21} color="#636363" />
          </Link>
          </div>
        </div>
      </div>
      <div className="flex mt-[41px] rounded-xl flex-1 flex-col py-4 h-fit bg-white mt-4">
        <p className="text-[#03A685] text-[21px]  font-orator whitespace-nowrap px-5 sm:px-9">
          Payment Summary
        </p>
        <PaymentSummary gstPrice={gstPrice} sellingPrice={sellingPrice} calculateTotalPrice={calculateTotalPrice} />
        <div className="mt-3 px-5 sm:px-9">
          <button
            onClick={handleContinue}
            className="font-prompt bg-[#F9C88F] w-[150px] px-[14px] py-[6px] sm:py-[9px] text-base sm:text-[18px] text-black rounded "
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default BagStep;
