import React from "react";
import { HiCheckBadge } from "react-icons/hi2";
import shoe from "../../images/shoes.png";
import { MdCurrencyRupee } from "react-icons/md";
import LoadingPage from "../../pages/LoadingPage";

const OrderRecived = ({ orderLoading, orderDetails }) => {


  const colorMap = {
    "Dark Brown": "#5C4033",
    "Dark Blue": "#00008B",
    "Dark Green": "#006400",
    "White": "#FFFFFF",
    "Tan Brown": "#A52A2A",
    "Maroon": "#800000"
  };
  if (orderLoading) {
    return (
      <div className="w-full h-full mx-auto my-auto justify-center items-center">
        <LoadingPage />
      </div>
    );
  }

 

  return orderLoading ? (
    <div className="w-full h-full mx-auto my-auto justify-center items-center">
      <LoadingPage />
    </div>
  ) : (
    <div className="max-w-[1920px] 1921px:mx-auto px-[0.5rem] sm:px-[1rem] xl:px-[5rem] py-3 mt-9 ">
      <div className="flex gap-3 justify-center lg:justify-start">
        <div className="flex items-center mb-1">
          <HiCheckBadge fontSize={41} color="green" />
        </div>
        <div className="flex flex-col">
          <h1 className="text-[#000000] ttext-[21px] sm:text-[30px] font-orator font-bold ">
            Thanks for your order!
          </h1>
          <p className="text-[#000000] text-base font-helvetica font-semi-bold">
            Order No : #{orderDetails?.orderDTO.orderNumber}
          </p>
        </div>
      </div>
      <div className="flex w-full flex-col lg:flex-row items-center lg:gap-[120px] lg:items-start">
        <div className="flex flex-col max-h-[600px] lg:max-h-[500px] overflow-y-scroll border border-slate-200 shadow-sm w-[330px] sm:w-[350px] lg:w-[600px] p-3 sm:p-6 rounded-md gap-4 mt-5">
          {orderDetails?.productDTO.map((product, index) => (
            <div className="border border-[#D9D9D9] w-[300px] lg:w-[550px] h-[300px] lg:h-[250px] rounded-lg">
              <div className="flex justify-between items-center w-full ">
                <div className="flex w-[45%] lg:w-[35%] ">
                  <div className="w-[12rem]h-[12rem] bg-[#EFEFEF] m-3">
                    <img
                      src={product?.productImage[0].imagePath}
                      alt="shoes"
                      className="w-[9rem] h-[7rem]"
                    />
                  </div>
                </div>
                <div className="flex w-[65%] lg:w-[75%] flex-col">
                  <p className="text-[#636363] text-[12px] font-helvetica ">{product?.categoryDTO.categoryName}</p>
                  <p className="text-[#636363] text-[12px] font-helvetica "><span className="font-extrabold">Quantity:</span> {product?.quantity}</p>
                  <p className="text-[#303030] font-orator font-semi-bold whitespace-nowrap">
                    {product?.productName.length > 30
                      ? product?.productName.substring(0, 30) + "..."
                      : product?.productName}
                  </p>
                  <div className="flex gap-3 lg:gap-5 mt-5 ">
                    <div className="border border-[#D9D9D9] w-[4.9rem] lg:w-[6.4rem] h-[2.5rem]  rounded-sm flex gap-5 justify-center items-center p-3 lg:p-2">
                      <p className="text-[#514F4F] font-helvetica">Size</p>
                      <span className="w-[1rem] h-[1rem] p-2 text-[15px] lg:text-base lg:p-0 lg:w-[2rem] lg:h-[2rem] border border-[#D9D9D9] font-basicommercial rounded-full flex justify-center items-center">
                        <p>{product?.selectedSize}</p>
                      </span>
                    </div>
                    <div className="border border-[#D9D9D9] w-[4.9rem] lg:w-[6.4rem] h-[2.5rem] rounded-sm  flex gap-5 justify-center items-center p-3 lg:p-2">
                      <p className="text-[#514F4F] font-helvetica">Color</p>
                      <span
                        style={{ background: colorMap[product?.colors[0].colorName] || product?.colors[0].colorName }}

                        className="w-[1rem] h-[1rem] p-2 text-sm lg:text-base  lg:w-[2rem] lg:h-[1.6rem] border rounded-full border-[#D9D9D9] rounded-full flex justify-center items-center"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <hr color="grey" />
              <div className="flex justify-between flex-col gap-6 lg:flex-row items-center p-3">
                <div className="flex items-center justify-center gap-3  flex-row lg:flex-col">
                  <p className="text-[#303030] text-base   font-bold">
                    Estimated delivery date
                  </p>
                  <p className="font-Nunito text-[14px] lg:mt-3">
                    {new Date(
                      Date.now() + 5 * 24 * 60 * 60 * 1000
                    ).toLocaleDateString()}
                  </p>
                </div>
                <div className="flex">
                  <button className="px-4 py-2 bg-[#E8396C] text-[white] rounded-full">
                    Track your order
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-[340px] lg:w-[360px] h-[180px] mt-10 mx-auto bg-[#F6F6F6] rounded-lg">
          <p className="text-[#000000] font-prompt text-base font-bold px-4 py-2">
            Payment Summary
          </p>
          {orderDetails?.productDTO.length > 0 && (
            <>
              <div className="flex justify-between items-center px-4 py-1">
                <p className="text-base text-[#090909] font-Nunito">
                  Total Price
                </p>
                <p className="text-base font-bold text-[#000000] font-prompt flex items-center">
                  <MdCurrencyRupee />{" "}
                  {orderDetails.productDTO.reduce(
                    (acc, curr) => acc + curr.sellingPrice,
                    0
                  ).toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between items-center px-4 py-1">
                <p className="text-base text-[#090909] font-Nunito">
                  Total GST Amount
                </p>
                <p className="text-base font-bold text-[#000000] font-prompt flex items-center">
                  <MdCurrencyRupee />{" "}
                  {orderDetails.productDTO.reduce(
                    (acc, curr) => acc + (curr.gstAmount),
                    0
                  ).toFixed(2)}
                </p>
              </div>

              <hr className="mt-3" />
              <div className="flex mt-3 justify-between items-center px-4 py-1">
                <p className="text-base text-[#090909] font-Nunito">
                  Total Amount
                </p>
                <p className="text-base font-bold text-[#000000] font-prompt flex items-center">
                  <MdCurrencyRupee />{" "}
                  {Number(orderDetails?.orderDTO?.totalPrice).toFixed(2)}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderRecived;
