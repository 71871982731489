import React, { useState, useEffect } from "react";
import { FaAddressCard, FaUser, FaUserMinus } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";
import { PiShoppingCartSimpleLight } from "react-icons/pi";
import { useSelector } from "react-redux";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs.jsx";
import UserAccountPage from "../../pages/UserAccountPage";
import AddressDetails from "./AddressDetails";
import OrderDetails from "./OrderDetails";
import { getUserById } from "../../services/operations/authApi.js";
import AddressModel from "../models/AddressModel.jsx";
import { getAllAddress } from "../../services/operations/addressApi.js";
import LoadingPage from "../../pages/LoadingPage.jsx";
import { FaAddressBook, FaShoppingBag } from "react-icons/fa";
import EditProfileModel from "../models/EditProfileModel.jsx";
const AccountDetails = ({tabId}) => {
  const { token, user } = useSelector((state) => state.auth);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [allAddressData, setAllAddressData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabIds,setTabId]=useState(tabId)
  const [activeTab,setActiveTab]=useState("profile")
  const [hideRemoveBtn, setHideRemoveBtn] = useState(false);

  const handleEditProfile = () => {
    setIsEditProfile(true);
  };
  const handleProfileUpdate = () => {
    fetchUser();
    setIsEditProfile(false);
  };
  useEffect(() => {
    switch (tabId) {
      case "1":
        setActiveTab("profile");
        break;
      case "2":
        setActiveTab("Address");
        break;
      case "3":
        setActiveTab("Orders");
        break;
      default:
        setActiveTab("profile");
        break;
    }
  }, [tabId]);
  useEffect(() => {
    fetchUser();
  }, [token, user]);

  const fetchUser = async () => {
    const response = await getUserById(user.userId);
    setUserData(response);
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getAllAddress(user?.userId, token);
     
      setAllAddressData(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    loading ? (
      <div className="w-full h-[450px] items-center justify-center mx-auto my-auto">
        <LoadingPage />
      </div>
    ) : (
<div className="w-full max-w-[1920px] mx-auto px-3 xl:px-[5rem] py-3 mt-5">

  <Tabs
    defaultValue={activeTab} value={activeTab} onValueChange={setActiveTab}
    className="flex flex-col xl:flex-row lg:mx-auto items-start border border-[#D9D9D9] h-auto xl:h-fit w-full lg:w-[1100px]"
  >
   <TabsList
  className={`w-full xl:w-[200px] h-auto ${
    activeTab === "profile"
      ? "xl:h-[200px]"
      : activeTab === "Address"
      ? "xl:h-[300px]"
      : "xl:h-[600px]"
  } border-b xl:border-b-0 xl:border-r border-[#D9D9D9]`}
>

      <TabsTrigger
        value="profile"
        onClick={() => {
          setIsEditProfile(false);
          setActiveTab('profile');
        }}
        className={` whitespace-nowrap w-full font-medium text-sm xl:text-[16px] px-[1rem] py-[1rem] text-[#000000] gap-3 flex justify-start items-center border-b border-grey  ${activeTab === "profile" ? "bg-gray-300" : "bg-white"}`}
      >
        <FaUser /> My Profile
      </TabsTrigger>
      <TabsTrigger
        value="Address"
        onClick={()=>setActiveTab('Address')}
        className={` whitespace-nowrap font-medium text-sm xl:text-[16px] px-[1rem] py-[1rem] text-[#000000] gap-3 flex justify-start items-center border-b border-grey w-full  ${activeTab === "Address" ? "bg-gray-300" : "bg-white"}`}
      >
        <FaAddressCard /> Manage Address
      </TabsTrigger>
      <TabsTrigger
        value="Orders"
        onClick={()=>setActiveTab('Orders')}
        className={` whitespace-nowrap font-medium text-sm xl:text-[16px] px-[1rem] py-[1rem] text-[#000000] gap-3 flex justify-start items-center border-b border-grey w-full ${
          activeTab === "Orders" ? "bg-gray-300" : "bg-white"}
`}
      >
        <FaShoppingBag /> My Orders
      </TabsTrigger>
    </TabsList>
    <TabsContent
      value='profile'
      className="w-full h-fit p-3 mt-0"
    >
      
        <div className="bg-[rgb(246,246,246)]  p-3  xl:p-3 h-auto  rounded-xl w-[300px] xl:w-full px-5 xl:px-10 mx-4 my-2 xl:mx-0 mt-6 xl:mt-0 flex flex-col xl:flex-col justify-between gap-5 ">
          <div className="flex justify-between items-center">
            {/* <FaUser className="w-[5rem] h-[5rem] xl:w-[5rem] xl:h-[5rem]" /> */}
            <p className="text-black text-base font-medium">Profile Details</p>
            <button
                className="flex border w-fit border-black p-2 rounded-md py-1 text-center bg-white text-xs xl:text-sm"
                onClick={handleEditProfile}
              >
               <EditProfileModel
           setIsEditProfile={setIsEditProfile}
           onProfileUpdate={handleProfileUpdate}
        
        />
              </button>
          </div>
          <div className="flex gap-3 xl:gap-2
            flex-col font-helvetica">
            <div className="flex items-center gap-[50px] ">
              <h1 className="text-[#000000]  text-sm xl:text-base">
                {userData?.firstName} {userData?.lastName}
              </h1>

            </div>
            <p className="text-[#000000]  text-sm xl:text-base">
              {userData?.emailId}
            </p>
            <p className="text-[#000000]  text-sm xl:text-base">
              {userData?.mobileNumber}
            </p>
          </div>
        </div>
      
    </TabsContent>
    <TabsContent value="Address" className="w-full h-full p-3 mt-0">
    <div className={`flex mt-0  flex-col xl:flex-col ${allAddressData?.length < 5 && allAddressData?.length > 0 ? "justify-center":"justify-center"}`}>

      { allAddressData?.length > 0 && (
        <div className="flex justify-between gap-4 xl:gap-9 items-start px-2 py-2 rounded">
          <p className="text-black  text-base font-medium">Address Details</p>
          <p className=" text-xs xl:text-[14px] p-2 rounded-md shadow-sm bg-[#FBD3A4] text-[#000000]">
          <AddressModel
              editTag={"Add New Address"}
              allAddressData={allAddressData}
              setAllAddressData={setAllAddressData}
              fetchData={fetchData}
              hideRemoveBtn={hideRemoveBtn} 
              setHideRemoveBtn={setHideRemoveBtn}
            />
          </p>
        </div>
       )}
    <AddressDetails userData={userData} allAddressData={allAddressData || []} setAllAddressData={setAllAddressData} hideRemoveBtn={hideRemoveBtn} setHideRemoveBtn={setHideRemoveBtn} />

      </div>
    </TabsContent>
    <TabsContent value="Orders" className="w-full h-full">
      <OrderDetails />
    </TabsContent>
  </Tabs>
</div>

    )
  );
};

export default AccountDetails;
