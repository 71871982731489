import { apiConnector } from "../apiConnector";
import { orderEndPoints } from "../api";
import {LogisticsEndPoints} from "../api";

export const getAllPinCode = async (Input_Pincode,token) => {
    let result = [];
    // console.log("userId", Input_Pincode);
    try {
      const response = await apiConnector(
        "GET",
        LogisticsEndPoints.GET_PIN_CODE,
        {},
        {
          Authorization: `Bearer ${token}`,
        },
        { Input_Pincode: Input_Pincode }
      );
      result = response.data;
  
      return result;
    } catch (error) {
      console.log("error", error);
    }
  };

  export const CreatePickUp =async(data, token)=>{
    let result=[]
       console.log("tohdjdjhdjhen", token)
    
        try {
            const response = await apiConnector(
              "POST",
              LogisticsEndPoints.GET_PICKUP,
              
                data,
              
              {
                Authorization: `Bearer ${token}`,
              },
              
              
            );
           
            result = response?.data;
            return result;
          } catch (error) {
            console.log(error);
          }
         
        };
  export const trackingAPi =async(data, token)=>{
    let result=[]
    
        try {
            const response = await apiConnector(
              "POST",
              LogisticsEndPoints.EVENT_TRACKING,
              
                data,
              
              {
                Authorization: `Bearer ${token}`,
              },
              {}
              
              
            );
           
            result = response?.data;
            return result;
          } catch (error) {
            console.log(error);
          }
         
        };
  export const CreateLabel =async(data, token)=>{
    let result=[]
    
        try {
            const response = await apiConnector(
              "POST",
              LogisticsEndPoints.GET_LABEL,
              
data,             
              {
                
                Authorization: `Bearer ${token}`,
              },
              // {
              //   articleNumber :data.articalId,
              //   service:data.service,
              //   senderName:data.senderName,
              //   senderAddressLine1:data.senderAddressLine1,
              //   senderAddressLine2:data.senderAddressLine2,
              //   senderAddressLine3:data.senderAddressLine3,
              //   senderCity:data.senderCity,
              //   senderPincode:data.senderPincode,
              //   senderMobile:data.senderMobile,
              //   nameOfReceipient:data.nameOfReceipient,
              //   receipientAddressLine2:data.receipientAddressLine2,
              //   receipientAddressLine3:data.receipientAddressLine3,
              //   receipientMobile:data.receipientMobile,
              //   receipientCountry:data.receipientCountry,
              //   receipientState:data.receipientState,
              //   weight:data.weight,
              //   length:data.length,
              //   breadth:data.breadth,
              //   height:data.height,
              //   insuredValue:data.insuredValue,
              //   codValue:data.codValue,
              //   vppValue:data.vppValue,

              // }
              
              
            );
           
            result = response?.data;
            return result;
          } catch (error) {
            console.log(error);
          }
         
        };
        export const generateArticleId = async (orderId,token) => {
          let result = [];
          // console.log("userId", Input_Pincode);
          try {
            const response = await apiConnector(
              "GET",
              LogisticsEndPoints.GENERATE_ARTICLE_ID,
              {},
              {
                Authorization: `Bearer ${token}`,
              },
              { orderId: orderId }
            );
            result = response.data;
        
            return result;
          } catch (error) {
            console.log("error", error);
          }
        };
        export const getOrderDetailsById = async (orderId,token) => {
          let result = [];
          // console.log("userId", Input_Pincode);
          try {
            const response = await apiConnector(
              "GET",
              orderEndPoints.GET_ORDER_DETAILS_BY_AdminID,
              {},
              {
                Authorization: `Bearer ${token}`,
              },
              { orderId: orderId }
            );
            result = response.data;
        
            return result;
          } catch (error) {
            console.log("error", error);
          }
        };