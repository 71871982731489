import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { trackingAPi } from "../services/operations/LogisticsApi";
import { XMLParser } from "fast-xml-parser";

const OrderTrackingPage = () => {
    const location = useLocation();
    const articleId = location?.state?.articleId;

    const [trackingDetails, setTrackingDetails] = useState([]);
    const { token } = useSelector((state) => state.auth);
    const [currentStep, setCurrentStep] = useState(0);

    const steps = ["Order Placed", "Order Bagged",  "Out For Delivery", "Delivered"];
    const stepMessages = [
        "Your order has been placed successfully.",
        "Your package has been bagged successfully.",
       
        "Your package is out for delivery.",
        "Your package has been delivered!"
    ];

    const eventCodeMapping = {
        "ITEM_BOOK": 0,
        "ITEM_BAGGING": 1,
        
        "BEAT_DISPATCH": 2,
        "ITEM_DELIVERY": 3
    };

    useEffect(() => {
        const formattedDate = "05052022"; 
        const data = {
            Event_Date: formattedDate
        };

        const orderTracking = async () => {
            const response = await trackingAPi(data, token);
            const parser = new XMLParser();
            const json = parser.parse(response);

            const individualTracking = json.LatestEventDetails.ArticleDetails
            .filter((item) => item.articleNumber === articleId)
         
            

            setTrackingDetails(individualTracking);

            const latestEvent = individualTracking[0]?.EventCode || "ITEM_BOOK";
            setCurrentStep(eventCodeMapping[latestEvent] || 0);
        };

        orderTracking();
    }, [articleId, token]);

    return (
        <div className="w-full px-4 sm:px-16 py-8 bg-gray-100">
            <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
                <h1 className="text-2xl font-bold text-center mb-4 text-gray-800">
                    {steps[currentStep]}
                </h1>
                <p className="text-sm text-center text-gray-600 mb-6">
                    {stepMessages[currentStep]}
                </p>

                {/* Progress Bar */}
                <div className="flex items-center justify-between w-full relative">
                    {steps.map((step, index) => (
                        <div className="flex flex-col items-center relative flex-1" key={index}>
                            <div
                                className={`w-10 h-10 rounded-full flex items-center justify-center font-semibold transition-all duration-300 z-10 
                                ${
                                    index < currentStep
                                        ? "bg-yellow-600 text-white"
                                        : index === currentStep
                                        ? "bg-yellow-500 text-white"
                                        : "bg-gray-300 text-gray-500"
                                }`}
                            >
                                {index < currentStep ? "✓" : index + 1}
                            </div>
                            <div
                                className={`mt-2 text-sm text-center ${
                                    index <= currentStep
                                        ? "text-yellow-600 font-semibold"
                                        : "text-gray-500"
                                }`}
                            >
                                {step}
                            </div>

                            {/* Line between steps */}
                            {index < steps.length - 1 && (
                                <div
                                    className={`absolute top-5 left-[calc(50%+20px)] h-1 w-[calc(100%-40px)] 
                                    ${
                                        index < currentStep
                                            ? "bg-yellow-600"
                                            : "bg-gray-300"
                                    }`}
                                ></div>
                            )}
                        </div>
                    ))}
                </div>

                {/* Tracking Details Section */}
                <div className="mt-8">
                    {trackingDetails.map((detail, index) => (
                        <div
                            key={index}
                            className={`p-4 rounded-md mb-4 shadow border 
                            ${
                                index <= currentStep
                                    ? "border-yellow-500"
                                    : "border-gray-300"
                            }`}
                        >
                            <h2 className="text-base font-bold text-gray-700">
                                Step: {steps[eventCodeMapping[detail.EventCode] || 0]}
                            </h2>
                            <p className="text-sm text-gray-600 mt-1">
                                Location: {detail.EventOfficeName || "Not Available"}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OrderTrackingPage;
