import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { FaMobileAlt } from "react-icons/fa";

const LoginOtp = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleNumberChange = (e) => {
    setMobileNumber(e.target.value.slice(0, 10)); // Ensures max 10 digits
  };

  const handleLogin = () => {
    setLoading(true);
    // Simulate API call or logic here
    setTimeout(() => {
      setLoading(false);
      console.log("Verification code sent to:", mobileNumber);
    }, 2000);
  };

  return (
    <div className="mx-auto max-w-lg mb-4 mt-1 p-5">
      <h1 className="text-2xl font-helvetica text-center mb-1 md:text-3xl text-black-700">
        Login/Sign up
      </h1>
      <div className="bg-white border border-gray-300 rounded-lg pb-6 pt-6 pl-9 pr-9 mt-5 mx-auto inline-block w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-700">
            Login/Sign up
          </h2>
        
        </div>
        <p className="text-sm text-gray-600 mb-6 text-center">
          We will send you an OTP on this Mobile Number
        </p>

        <div className="flex items-center border border-gray-300 rounded-lg px-3 py-2 mb-4">
          <FaMobileAlt className="text-blue-500 text-xl" />
          <div className="h-6 w-px bg-gray-300 mx-3"></div>
          <input
            type="number"
            className="flex-1 outline-none text-gray-700 text-base placeholder-gray-400"
            placeholder="Enter Mobile Number"
            maxLength={10}
            value={mobileNumber}
            onChange={handleNumberChange}
          />
        </div>

        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-gray-600"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
          <label className="ml-2 text-gray-600 text-sm">
            I agree to accept the{" "}
            <span className="text-blue-600 underline cursor-pointer">
              terms and conditions
            </span>
          </label>
        </div>

        <button
          className={`w-full px-2 py-2 text-black rounded-[15px] mt-2 ${
            isChecked
              ? "bg-[#F9C88F] hover:bg-[#F6B97B]"
              : "bg-gray-400 cursor-not-allowed"
          }`}
          disabled={loading || !isChecked}
          onClick={handleLogin}
        >
          {loading ? "Processing..." : "Get Verification Code"}
        </button>
      </div>
    </div>
  );
};

export default LoginOtp;
