import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { DialogDescription } from "@radix-ui/react-dialog";
import { Link } from "react-router-dom";
import { getOrderDetailsAdminById, getOrderDetailsById } from "../../services/operations/orderApi.js";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { useSelector } from "react-redux";
import { FaRegEye } from "react-icons/fa";
const SingleOrderAdminModel = ({ orderId, visibility, setVisibility , isAdminOrder}) => {
 
  const { token } = useSelector((state) => state.auth);
  const [isSingleOrderLoading, setIsSingleOrderLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [productDto, setProductDto] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setIsSingleOrderLoading(true);
      try {
        const response = await getOrderDetailsAdminById(orderId, token);
       
       
        setOrderData(response?.orderDTO || []);
        setProductDto(response?.productDTO);
      
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setIsSingleOrderLoading(false);
      }
    };
    fetchData();
  }, [token]);

  const handleView = () => {
    setVisibility(false);
  };
  const handleViewDialog = ()=>{
    setVisibility(true);

  }
   const [open, setOpen] = useState(false);

  return (
    <div>
   
     
         <Dialog open={open} onOpenChange={setOpen}> 
        <DialogTrigger asChild >
          <p className={ `flex mt-4 hover:text-blue-600 items-center text-sm text-right cursor-pointer ${isAdminOrder == "isAdminOrder" ? "text-[#03A685]":"text-black"}`}>
             View
          </p>
        </DialogTrigger>
       
        <DialogTrigger onClick={() => setVisibility(false) } >
        <DialogContent className="sm:max-w-[830px] "   >
          <DialogHeader>
            <DialogDescription className="text-[black] text-[20px] font-outFit ">
              <div className="flex gap-12 flex-wrap h-[190px] rounded overflow-y-auto">
                {productDto?.map((product, index) => {
                  return (
                    <div className="flex sm:justify-start w-full justify-between flex-col sm:flex-row items-center gap-3">
                      <div className="flex   w-[100px] h-[100px] bg-[#E8ECF7]">
                        <img
                          src={product?.productImage[0]?.imagePath}
                          alt="Product Image"
                          className="rounded"
                        />
                      </div>
                      <div className="flex flex-col">
                      <div className="flex flex-1 flex-col ">
                        <p className="px-3 text-base text-[#636363] font-helvetica">
                          {product?.categoryDTO?.categoryName}
                        </p>

                        <p className="px-3  font-bold text-base font-orator">
                          {product?.productName}
                        </p>
                        <p className="px-3 flex items-center font-helvetica">
                          <MdOutlineCurrencyRupee /> {Math.floor(product?.totalPrice)}
                        </p>
                       
                      </div>
                      <div className="flex gap-[30px] px-3 py-2 font-bold">
                  <p className="text-[15px] font-bold text-[#000000] font-helvetica">
                    Quantity
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {product?.quantity}
                  </p>
                </div>
                </div>
                    </div>
                  );
                })}
                <hr className="mt-3" />
              </div>
            </DialogDescription>
            

            <DialogDescription className="text-[black] text-[20px] font-outFit rounded">
              <p className="underline text-[15px] px-3 py-1 mb-1 font-orator font-bold">
                {" "}
                Order Details:
              </p>
              <div className="flex flex-row sm:flex-col  flex-wrap h-fit sm:h-[160px] ">
                <div className="flex gap-24 px-3 py-2">
                  <p className="text-[15px] text-[#000000] font-bold font-helvetica">
                    Name
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {orderData?.userName}
                  </p>
                </div>
                <div className="flex gap-[30px] px-3 py-2">
                  <p className="text-[15px] text-[#000000] font-helvetica font-bold">
                    Phone Number
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {orderData?.mobileNumber}
                  </p>
                </div>
                <div className="flex gap-[45px] px-3 py-2 ">
                  <p className="text-[15px] font-bold text-[#000000] font-helvetica">
                    Order Status
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {orderData?.orderStatus}
                  </p>
                </div>

                <div className="flex gap-[30px] px-3 py-2 font-bold">
                  <p className="text-[15px] font-bold text-[#000000] font-helvetica">
                    Email
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {orderData?.emailId}
                  </p>
                </div>
              
                <div className="flex gap-[46px] px-3 py-2">
                  <p className="text-[15px] text-[#000000] font-bold font-helvetica">
                    Total Price
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {Math.floor(orderData?.totalPrice)}
                  </p>
                </div>
                <div className="flex gap-[39px] px-3 py-2">
                  <p className="text-[15px] text-[#000000] font-bold font-helvetica">
                    Payment Status
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {orderData?.paymentStatus}
                  </p>
                </div>
                <div className="flex gap-[30px] px-3 py-2">
                  <p className="text-[15px] text-[#000000] font-helvetica font-bold">
                    Payment Type
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {orderData?.paymentType}
                  </p>
                </div>
              </div>
            </DialogDescription>
            <DialogDescription className="text-[black] text-[20px] font-outFit rounded">
              <div className="flex flex-col flex-wrap h-[140px] ">
                <p className="underline text-[15px] text-[#000000] px-3 py-1 mb-1 font-bold font-orator">
                  {" "}
                  Delivery Location:
                </p>
                <p className="px-3  text-[black] text-[14px] mt-3 font-basicommercial">
                  Richmond Cirlce, Bangalore, Bangalore, Karnataka 560027
                </p>
                <p className="px-3 text-[black] text-[14px] font-basicommercial">
                  Landmark: Oppossite to udupi upahar
                </p>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
        </DialogTrigger>
        </Dialog>
   

   
    </div>
  );
};

export default SingleOrderAdminModel;
