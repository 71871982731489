import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { DialogDescription } from "@radix-ui/react-dialog";
import { getOrderDetailsById } from "../../services/operations/orderApi.js";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { useSelector } from "react-redux";
import { FaRegEye } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import ReviewModel from "../models/ReviewModel";

const SingleOrderModel = ({ orderId, visibility, setVisibility, isAdminOrder }) => {
  const { token } = useSelector((state) => state.auth);
  const [isSingleOrderLoading, setIsSingleOrderLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [productDto, setProductDto] = useState([]);
  const [reviewOpen, setReviewOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const navigate=useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      setIsSingleOrderLoading(true);
      try {
        const response = await getOrderDetailsById(orderId, token);
        setOrderData(response?.orderDTO || []);
        setProductDto(response?.productDTO);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setIsSingleOrderLoading(false);
      }
    };
    fetchData();
  }, [token]);

  const [open, setOpen] = useState(false);

  // const handleOpenReviewModel = (product) => {
  // navigate("/rate-product",{state:{product}})
  // };

  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <p
            className={`flex mb-1 font-medium  text-base items-center  text-right cursor-pointer ${
              isAdminOrder == "isAdminOrder" ? "text-[#03A685]" : "text-black"
            }`}
          >
           <FaRegEye/>
          </p>
        </DialogTrigger>

        <DialogContent
          className="sm:max-w-[830px]"
          onClick={(e) => e.stopPropagation()}
        >
          <DialogHeader>
            <DialogDescription className="text-[black] text-[20px] font-outFit">
              <div className="flex gap-12 flex-wrap h-[190px] rounded overflow-y-auto">
                {productDto?.map((product, index) => (
                  <div
                    className="flex sm:justify-start w-full justify-between flex-col sm:flex-row items-start gap-3"
                    key={index}
                  >
                    <div className="flex w-[100px] h-[100px] bg-[#E8ECF7]">
                      <img
                        src={product?.productImage[0]?.imagePath}
                        alt="Product Image"
                        className="rounded"
                      />
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-1 flex-col">
                        <p className="px-3 text-base text-[#636363] font-helvetica">
                          {product?.categoryDTO?.categoryName}
                        </p>

                        <p className="px-3 font-bold text-base font-orator">
                          {product?.productName}
                        </p>
                        <p className="px-3 flex items-center font-helvetica">
                          <MdOutlineCurrencyRupee />{" "}
                          {product?.totalPrice.toFixed(2)}
                        </p>
                      </div>
                      <div className="flex gap-[30px] px-3 py-2 font-bold">
                        <p className="text-[15px] font-bold text-[#000000] font-helvetica">
                          Quantity
                        </p>
                        <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                          {product?.quantity}
                        </p>
                      </div>
                     
                     
                    </div>
                    <ReviewModel
          product={product}
          orderId={orderId}
        
        />
                   
                  </div>
                ))}
                <hr className="mt-3" />
              </div>
            </DialogDescription>

            <DialogDescription className="text-[black] text-[20px] font-outFit rounded">
              <p className="underline text-[15px] px-3 py-1 mb-1 font-orator font-bold">
                Order Details:
              </p>
              <div className="flex flex-row sm:flex-col flex-wrap h-fit sm:h-[160px]">
                <div className="flex gap-24 px-3 py-2">
                  <p className="text-[15px] text-[#000000] font-bold font-helvetica">
                    Name
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {orderData?.userName}
                  </p>
                </div>
                <div className="flex gap-[30px] px-3 py-2">
                  <p className="text-[15px] text-[#000000] font-helvetica font-bold">
                    Phone Number
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {orderData?.mobileNumber}
                  </p>
                </div>
                <div className="flex gap-[45px] px-3 py-2">
                  <p className="text-[15px] font-bold text-[#000000] font-helvetica">
                    Order Status
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {orderData?.orderStatus}
                  </p>
                </div>

                <div className="flex gap-[30px] px-3 py-2 font-bold">
                  <p className="text-[15px] font-bold text-[#000000] font-helvetica">
                    Email
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {orderData?.emailId}
                  </p>
                </div>

                <div className="flex gap-[46px] px-3 py-2">
                  <p className="text-[15px] text-[#000000] font-bold font-helvetica">
                    Total Price
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {Number(orderData?.totalPrice).toFixed(2)}
                  </p>
                </div>
                <div className="flex gap-[39px] px-3 py-2">
                  <p className="text-[15px] text-[#000000] font-bold font-helvetica">
                    Payment Status
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {orderData?.paymentStatus}
                  </p>
                </div>
                <div className="flex gap-[30px] px-3 py-2">
                  <p className="text-[15px] text-[#000000] font-helvetica font-bold">
                    Payment Type
                  </p>
                  <p className="text-[#5E5D5D] text-[15px] font-basicommercial">
                    {orderData?.paymentType}
                  </p>
                </div>
              </div>
            </DialogDescription>
            <DialogDescription className="text-[black] text-[20px] font-outFit rounded">
              <div className="flex flex-col flex-wrap h-[140px]">
                <p className="underline text-[15px] text-[#000000] px-3 py-1 mb-1 font-bold font-orator">
                  Delivery Location:
                </p>
                <p className="px-3 text-[black] text-[14px] mt-3 font-basicommercial">
                  Richmond Circle, Bangalore, Karnataka 560027
                </p>
                <p className="px-3 text-[black] text-[14px] font-basicommercial">
                  Landmark: Opposite to Udupi Upahar
                </p>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>

  
      
      
      
    </div>
  );
};

export default SingleOrderModel;
