import React, { useState } from 'react';
import { Button } from '../ui/button';
import Select from 'react-select'
import { IoLocationOutline } from "react-icons/io5";
import { TbPhonePause } from "react-icons/tb";
import { CiMail } from "react-icons/ci";
import { FaClockRotateLeft } from "react-icons/fa6";
import { createContactUs } from '../../services/operations/contactusApi';
import toast from 'react-hot-toast';
import { number } from 'zod';
import Flag from "react-world-flags";
import location from "../../images/location1.png"
import { FaWhatsapp } from "react-icons/fa6";

const ContactUs = () => {
  const [name, setName] = useState('');
  const [emailId, setEmailId] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [errors, setErrors] = useState({});
  const [typingField, setTypingField] = useState(null)
  const [mobileNumberPlaceHolder, setMobileNumberPlaceHolder] = useState('Phone Number')
  const [namePlaceHolder, setNamePlaceHolder] = useState('Name')
  const [emailPlaceHolder, setEmailPlaceHolder] = useState('Email')
  const [subjectPlaceHolder, setSubjectPlaceHolder] = useState('Subject')
  const [messagePlaceHolder, setMessagePlaceHolder] = useState('Message')
  const [value, setValue] = useState()
  const handleChange = (setValue, value, field) => {

    if (field == 'mobileNumber') {
      if (/^\d*$/.test(value)) {
        setValue(value);
        setTypingField(field)
        setErrors(({ [setValue]: '' }));
      }
    } else {
      setValue(value);
      setTypingField(field)
      setErrors(({ [setValue]: '' }));
    }
  };
  const handleKeyDown = (e, setValue, field) => {
    const keyCode = e.keyCode || e.which;

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 37 ||
        keyCode === 39 ||
        keyCode === 46 ||
        keyCode === 110 ||
        keyCode === 190
      )
    ) {
      e.preventDefault();
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: 'Phone number must contain only digits',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: '',
      }));
    }
  };


  const validateForm = () => {
    const errors = {};

    // Regex for email with no additional text after .com
    const emailRegex = /^[^\s@]+@[^\s@]+\.com$/;
    const phoneRegex = /^[6-9]\d{9}$/;

    if (!name.trim()) {
      errors.name = 'Name is required';
      return errors;
    }
    if (!emailId.trim()) {
      errors.emailId = 'Email is required';
      return errors;
    } else if (!emailRegex.test(emailId)) {
      errors.emailId = 'Email is invalid';
      return errors;
    }
    if (!mobileNumber.trim()) {
      errors.mobileNumber = 'Phone number is required';
      return errors;
    } else if (!phoneRegex.test(mobileNumber)) {
      errors.mobileNumber = 'Phone number is invalid';
      return errors;
    }
    if (!subject.trim()) {
      errors.subject = 'Subject is required';
      return errors;
    }
    if (!message.trim()) {
      errors.message = 'Message is required';
      return errors;
    } else if (message.length > 100) {
      errors.message = 'Message must be less than 100 characters';
      return errors;
    } else if (message.length < 10) {
      errors.message = 'Message must be more than 10 characters';
      return errors;
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        await createContactUs(name, emailId, mobileNumber, subject, message);
        toast.success('Thank you for contacting us! Your message has been successfully sent. We will get back to you as soon as possible.');
        setName('');
        setEmailId('');
        setMobileNumber('');
        setSubject('');
        setMessage('');
        setErrors({});
      } catch (error) {
        toast.error('Something went wrong. Please try again later.');
      }
    }


  };
  const countries = [
    { value: "+1", label: "USA", code: "US" },
    { value: "+44", label: "UK", code: "GB" },
    { value: "+91", label: "India", code: "IN" },
    { value: "+61", label: "Australia", code: "AU" },
    { value: "+33", label: "France", code: "FR" },
    { value: "+49", label: "Germany", code: "DE" },
    { value: "+81", label: "Japan", code: "JP" },
    { value: "+34", label: "Spain", code: "ES" },
    { value: "+55", label: "Brazil", code: "BR" },
    { value: "+86", label: "China", code: "CN" },
    { value: "+7", label: "Russia", code: "RU" },
    { value: "+39", label: "Italy", code: "IT" },
    { value: "+31", label: "Netherlands", code: "NL" },
    { value: "+27", label: "South Africa", code: "ZA" },
    { value: "+64", label: "New Zealand", code: "NZ" },
    { value: "+351", label: "Portugal", code: "PT" },
    { value: "+41", label: "Switzerland", code: "CH" },
    { value: "+45", label: "Denmark", code: "DK" },
    { value: "+46", label: "Sweden", code: "SE" },
    { value: "+47", label: "Norway", code: "NO" },
    { value: "+48", label: "Poland", code: "PL" },
    { value: "+53", label: "Cuba", code: "CU" },
    { value: "+54", label: "Argentina", code: "AR" },
    { value: "+57", label: "Colombia", code: "CO" },
    { value: "+60", label: "Malaysia", code: "MY" },
    { value: "+62", label: "Indonesia", code: "ID" },
    { value: "+63", label: "Philippines", code: "PH" },
    { value: "+65", label: "Singapore", code: "SG" },
    { value: "+66", label: "Thailand", code: "TH" },
    { value: "+94", label: "Sri Lanka", code: "LK" },
    { value: "+98", label: "Iran", code: "IR" },
    { value: "+995", label: "Georgia", code: "GE" },
    { value: "+998", label: "Uzbekistan", code: "UZ" },
    { value: "+230", label: "Mauritius", code: "MU" },
    { value: "+264", label: "Namibia", code: "NA" },
    { value: "+355", label: "Albania", code: "AL" },
    { value: "+380", label: "Ukraine", code: "UA" },
    { value: "+356", label: "Malta", code: "MT" },
    { value: "+972", label: "Israel", code: "IL" },
    { value: "+353", label: "Ireland", code: "IE" },
    { value: "+254", label: "Kenya", code: "KE" },
    { value: "+502", label: "Guatemala", code: "GT" },
    { value: "+503", label: "El Salvador", code: "SV" },
    { value: "+504", label: "Honduras", code: "HN" },
    { value: "+505", label: "Nicaragua", code: "NI" },
    { value: "+506", label: "Costa Rica", code: "CR" },
    { value: "+507", label: "Panama", code: "PA" },
    { value: "+509", label: "Haiti", code: "HT" },
    { value: "+90", label: "Turkey", code: "TR" },
    { value: "+880", label: "Bangladesh", code: "BD" },
    { value: "+977", label: "Nepal", code: "NP" },
    { value: "+84", label: "Vietnam", code: "VN" },
    { value: "+93", label: "Afghanistan", code: "AF" },
    { value: "+964", label: "Iraq", code: "IQ" },
    { value: "+967", label: "Yemen", code: "YE" },
  ];
  

  
  const [selectedCountry, setSelectedCountry] = useState( countries.find((country) => country.code === "IN"));
   
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: 14,
      color: 'blue',
      backgroundColor: state.isSelected ? 'lightblue' : 'white',
    }),
  };
  const handleBlur = (placeholderSetter, value, initialValue) => {
    if (!value) {
      placeholderSetter(initialValue);
    }
  };
  const handleFocus = (placeholderSetter) => {
    placeholderSetter('');
  };
 

  const handleChangeCountry = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  return (
    <div className="w-1920 mt-6 px-1 sm:px-[5rem] sm:py-3 overflow-hidden">
      {/* <div className='bg-[#F6F6F6]  mt-5 sm:h-[1000px] xl:h-[500px]  p-3'>
        <div className='flex flex-col sm:flex-col xl:flex-row justify-center gap-12'>
          <div className='flex flex-col gap-y-5'>
            <p className='font-semibold text-4xl text-[#011334] font-prompt leading-46 mt-10'>Let's talk with us.</p>
            <p className='font-normal text-base font-prompt leading-7 w-[370px] mt-3'>Questions, comments, or suggestions? Simply fill in the form and we’ll be in touch shortly.</p>
            <div className='flex gap-3 items-center'>
              <IoLocationOutline className='mb-12' />
              <p className='w-[370px] font-semi-bold text-[#011334] font-prompt'>No. 32, Dr. Babu Jagjivan Ram Lidkar Bhavana, Millers bund area, Vasantha Nagara, Bengaluru - 560052</p>
            </div>
            <div className='flex gap-3 items-center'>
              <TbPhonePause />
              <p className='w-[370px] font-semi-bold text-[#011334] font-prompt'>080-22288895</p>
            </div>
            <div className='flex gap-3 items-center'>
              <CiMail />
              <p className='w-[370px] font-semi-bold text-[#011334] font-prompt'>support@lidakar.com</p>
            </div>
            <div className='flex gap-3 items-center'>
              <FaClockRotateLeft />
              <p className='w-[370px] font-semi-bold text-[#011334] font-prompt'>Mon-Fri: 09:00am - 06:00pm</p>
            </div>
          </div>
          <div className='flex bg-[#FFFFFF] w-[500px] h-[401px] px-7 py-3 mt-9'>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="flex gap-x-3 mt-5">
                <div className="flex flex-col w-full">
                  <input
                    type="text"
                    value={name}
                    maxLength={50}
                    onChange={(e) => handleChange(setName, e.target.value, 'name')}
                    onFocus={() => handleFocus(setNamePlaceHolder)}
                    onBlur={() => handleBlur(setNamePlaceHolder, name, 'Name')}

                    className={`rounded-[7px] ${errors.name ? "border-red-500" : "border-gray-200"}  border p-2 placeholder-gray-400 pl-4`}
                    placeholder={namePlaceHolder}
                  />
                  {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
                  {typingField === "name" && (
                    <small className="text-gray-500 self-end">({name.length}/50)</small>
                  )}
                </div>
                <div className="flex flex-col w-full">
                  <input
                    type="text"
                    value={emailId}
                    maxLength='50'
                    onChange={(e) => handleChange(setEmailId, e.target.value, 'emailId')}
                    onFocus={() => handleFocus(setEmailPlaceHolder)}
                    onBlur={() => handleBlur(setEmailPlaceHolder, emailId, 'Email')}

                    className={`rounded-[7px] ${errors.emailId ? "border-red-500" : "border-gray-200"}  border p-2 placeholder-gray-400 pl-4`}
                    placeholder={emailPlaceHolder}
                  />
                  {errors.emailId && <p className="text-red-500 text-xs">{errors.emailId}</p>}
                  {typingField === "emailId" && (
                    <small className="text-gray-500 self-end">({emailId.length}/50)</small>
                  )}
                </div>
              </div>
              <div className='flex flex-col gap-3'>
                <div className="flex flex-col">
                  <input
                    type='text'
                    value={mobileNumber}

                    maxLength='10'
                    onChange={(e) => handleChange(setMobileNumber, e.target.value, 'mobileNumber')}
                    onFocus={() => handleFocus(setMobileNumberPlaceHolder)}
                    onBlur={() => handleBlur(setMobileNumberPlaceHolder, mobileNumber, 'PhoneNumber')}

                    onKeyDown={(e) => handleKeyDown(e, setMobileNumber, 'mobileNumber')}
                    className={`rounded-[7px] ${errors.mobileNumber ? "border-red-500" : "border-gray-200"}  border p-2 placeholder-gray-400 pl-4`}
                    placeholder={mobileNumberPlaceHolder}
                  />
                  {errors.mobileNumber && <p className="text-red-500 text-xs">{errors.mobileNumber}</p>}
                  {typingField === "mobileNumber" && (
                    <small className="text-gray-500 self-end">({mobileNumber.length}/10)</small>
                  )}
                </div>
                <div className="flex flex-col">
                  <input
                    type="text"
                    value={subject}
                    maxLength='50'
                    onChange={(e) => handleChange(setSubject, e.target.value, 'subject')}
                    onFocus={() => handleFocus(setSubjectPlaceHolder)}
                    onBlur={() => handleBlur(setSubjectPlaceHolder, subject, 'Subject')}

                    className={`rounded-[7px] ${errors.subject ? "border-red-500" : "border-gray-200"}  border p-2 placeholder-gray-400 pl-4`}
                    placeholder={subjectPlaceHolder}
                  />
                  {errors.subject && <p className="text-red-500 text-xs">{errors.subject}</p>}
                  {typingField === "subject" && (
                    <small className="text-gray-500 self-end">({subject.length}/50)</small>
                  )}
                </div>
                <div className="flex flex-col">
                  <textarea
                    value={message}
                    onChange={(e) => handleChange(setMessage, e.target.value, 'message')}
                    onFocus={() => handleFocus(setMessagePlaceHolder)}
                    onBlur={() => handleBlur(setMessagePlaceHolder, message, 'Message')}

                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="message"
                    maxLength='150'
                    rows="5"
                    placeholder={messagePlaceHolder}
                  ></textarea>

                  {errors.message && <p className="text-red-500 text-xs">{errors.message}</p>}
                  {typingField === "message" && (
                    <small className="text-gray-500 self-end">({message.length}/150)</small>
                  )}
                </div>
                <Button
                  type="submit"
                  className="bg-[#552038] hover:bg-[#411b2d] w-full px-2 py-2 rounded-[15px] mt-4 text-white"
                >
                  Send Message
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
      <div className='bg-white border border-[#FFC683] p-1 sm:p-6'>
        <div className="flex items-center justify-center w-full mt-3">
          <hr className="flex-grow border-t border-[#FFC683]" />
          <div className="mx-4 text-[#b4975a]">
            <FaWhatsapp size={24} color='#FFC683' />
          </div>
          <hr className="flex-grow border-t border-[#FFC683]" />
        </div>
        <p className='justify-center mt-5 items-center text-[#58595b] bg-transparent inline-block pb-2.5 uppercase text-lg border-b-0 border-[#e8e5d6] w-full text-center  tracking-wide   font-orator '>CONTACT US</p>
        <div className='mt-9'>
          <p className='flex justify-center items-center flex-col sm:flex-row text-[#777]  font-helvetica text-sm '>For customer service, product or online order related inquiries please contact us by completing the form below or via email at:
            <span className='text-black font-helvetica text-sm font-bold ml-1 cursor-pointer'   onClick={() => window.location.href = "mailto:customercare@lidkar.com"}>customercare@lidkar.com</span>
          </p>

        </div>
        <div className='flex justify-center items-center mt-6'>
          <span className='text-[#777]  font-helvetica text-sm'>You can also call us at:</span> <span className='text-black font-helvetica text-sm font-bold cursor-pointer'  onClick={() => window.location.href = "tel:+918447746959"}>+91 84477 46959</span>


        </div>
        <hr className="flex-grow border-t mt-3 border-[#FFC683] " />
        <div className='bg-[#FFFFFF] w-[340px] sm:w-[500px] h-[373px] sm:px-7 py-3 mt-9 w-1920 mx-auto'>
          <form className="space-y-4" onSubmit={handleSubmit}>


            <div>
              <div className="flex sm:gap-3 sm:w-full justify-center items-center">
                <label className='text-[#777]  sm-flex fon-helvetica'>Name*</label>

                <input
                  type="text"
                  style={{ backgroundColor: 'rgba(251, 211, 164, 0.7)' }}
                  value={name}
                  maxLength={50}
                  onChange={(e) => handleChange(setName, e.target.value, 'name')}
                  onFocus={() => handleFocus(setNamePlaceHolder)}
                  onBlur={() => handleBlur(setNamePlaceHolder, name, 'Name')}

                  className={` ml-[72px] bg-[#f2efeb] outline-none   w-full ${errors.name ? "border-red-500" : "border-[#F4B574]"}  border p-1 placeholder-gray-400 pl-4 cursor-text`}
                  placeholder={namePlaceHolder}
                />
              </div>

              <div className='flex justify-between'  >

                {errors.name && <p className="text-red-500 text-xs  ">{errors.name}</p>}
                {typingField === "name" && (
                  <small className="text-gray-500 ">({name.length}/50)</small>
                )}
              </div>

            </div>





            <div>
              <div className="flex sm:gap-3 w-full justify-center items-center">
                <label className='text-[#777] whitespace-nowrap font-helvetica'>Email Id*</label>
                <input
                  type="text"
                  value={emailId}
                  style={{ backgroundColor: 'rgba(251, 211, 164, 0.7)' }}

                  maxLength='50'
                  onChange={(e) => handleChange(setEmailId, e.target.value, 'emailId')}
                  onFocus={() => handleFocus(setEmailPlaceHolder)}
                  onBlur={() => handleBlur(setEmailPlaceHolder, emailId, 'Email')}

                  className={` ml-[57px] outline-none b w-full ${errors.emailId ? "border-red-500" : "border-[#F4B574]"}  border p-1 placeholder-gray-400 pl-4`}
                  placeholder={emailPlaceHolder}
                />
              </div>
              <div className='flex justify-between'  >
                {errors.emailId && <p className="text-red-500 text-xs">{errors.emailId}</p>}
                {typingField === "emailId" && (
                  <small className="text-gray-500 self-end">({emailId.length}/50)</small>
                )}
              </div>

            </div>
            <div>

              <div className="flex gap-2 w-full justify-between items-center">
              <label className='text-[#777] mr-3 whitespace-nowrap font-helvetica'>Phone Number*</label>
              <div className="flex items-center w-full">

              {/* <select
  value={countryCode}
  onChange={(e) => handleCountryCodeChange(e)}
  style={{ backgroundColor: 'rgba(251, 211, 164, 0.7)' }}
  className="border text-gray-400 outline-none h-[33px] w-[55px] text-gray-800 border-[#F4B574] h-[100px]"
  // size="10"
//  styles={customStyles}
>
  <option value="+1">+1 (USA)</option>
  <option value="+44">+44 (UK)</option>
  <option value="+91">+91 (India)</option>
  <option value="+61">+61 (Australia)</option>
  <option value="+33">+33 (France)</option>
  <option value="+49">+49 (Germany)</option>
  <option value="+81">+81 (Japan)</option>
  <option value="+34">+34 (Spain)</option>
  <option value="+55">+55 (Brazil)</option>
  <option value="+86">+86 (China)</option>
  <option value="+39">+39 (Italy)</option>
  <option value="+7">+7 (Russia)</option>
  <option value="+52">+52 (Mexico)</option>
  <option value="+31">+31 (Netherlands)</option>
  <option value="+27">+27 (South Africa)</option>
  <option value="+61">+61 (Australia)</option>
  <option value="+34">+34 (Spain)</option>
  <option value="+64">+64 (New Zealand)</option>
  <option value="+55">+55 (Brazil)</option>
  <option value="+351">+351 (Portugal)</option>
  <option value="+41">+41 (Switzerland)</option>
  <option value="+33">+33 (France)</option>
  <option value="+91">+91 (India)</option>
  <option value="+45">+45 (Denmark)</option>
  <option value="+46">+46 (Sweden)</option>
  <option value="+47">+47 (Norway)</option>
  <option value="+48">+48 (Poland)</option>
  <option value="+49">+49 (Germany)</option>
  <option value="+53">+53 (Cuba)</option>
  <option value="+54">+54 (Argentina)</option>
  <option value="+57">+57 (Colombia)</option>
  <option value="+60">+60 (Malaysia)</option>
  <option value="+62">+62 (Indonesia)</option>
  <option value="+63">+63 (Philippines)</option>
  <option value="+65">+65 (Singapore)</option>
  <option value="+66">+66 (Thailand)</option>
  <option value="+67">+67 (Palau)</option>
  <option value="+94">+94 (Sri Lanka)</option>
  <option value="+98">+98 (Iran)</option>
  <option value="+995">+995 (Georgia)</option>
  <option value="+998">+998 (Uzbekistan)</option>
  <option value="+230">+230 (Mauritius)</option>
  <option value="+264">+264 (Namibia)</option>
  <option value="+355">+355 (Albania)</option>
  <option value="+380">+380 (Ukraine)</option>
  <option value="+356">+356 (Malta)</option>
  <option value="+380">+380 (Ukraine)</option>
  <option value="+972">+972 (Israel)</option>
  <option value="+353">+353 (Ireland)</option>
  <option value="+254">+254 (Kenya)</option>
  <option value="+254">+254 (Kenya)</option>
</select> */}
 <div>
      <Select
        options={countries}
        value={selectedCountry}
        onChange={handleChangeCountry}
        defaultValue={countries.find((country) => country.code === "IN")} 
        styles={{
          control: (base) => ({
            ...base,
            backgroundColor: "rgba(251, 211, 164, 0.7)",
            border: "1px solid #F4B574",
            borderRadius:"0px",
            fontSize: "12px", 
            height: "33px", 
            minHeight: "30px", 
            width: "70px",
          }),
          menu: (base) => ({
            ...base,
            width: "300px", 
             marginTop: "5px",
          }),
          valueContainer: (base) => ({
            ...base,
            height: "30px", 
            padding: "0 8px", 
          }),
          indicatorsContainer: (base) => ({
            ...base,
            height: "30px", 
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: "4px", 
          }),
          option: (base, { isFocused }) => ({
            ...base,
            backgroundColor: isFocused ? "#F4B574" : "#FFF",
            color: "#333",
            display: "flex",
            // width:"200px",
            alignItems: "center",
            fontSize: "12px", 
            padding: "5px 10px", 
          }),
        }}
        getOptionLabel={(e) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Flag
              code={e.code}
              style={{ width: "20px", height: "15px", marginRight: "10px" }}
            />
            {e.label} ({e.value})
          </div>
        )}
        getOptionValue={(e) => e.value}
      />
    </div>
  <input
    type='text'
    value={mobileNumber}
    style={{ backgroundColor: 'rgba(251, 211, 164, 0.7)' }}
    maxLength='10'
    onChange={(e) => handleChange(setMobileNumber, e.target.value, 'mobileNumber')}
    onFocus={() => handleFocus(setMobileNumberPlaceHolder)}
    onBlur={() => handleBlur(setMobileNumberPlaceHolder, mobileNumber, 'PhoneNumber')}
    onKeyDown={(e) => handleKeyDown(e, setMobileNumber, 'mobileNumber')}
    className={`w-full outline-none  ${errors.mobileNumber ? "border-red-500" : "border-[#F4B574]"} border-l-0 border-r border-t  border-b h-[33px] placeholder-gray-400 pl-4`}
    placeholder={mobileNumberPlaceHolder}
  />
</div>

              </div>
              <div className='flex justify-between'  >
                {errors.mobileNumber && <p className="text-red-500 text-xs">{errors.mobileNumber}</p>}
                {typingField === "mobileNumber" && (
                  <small className="text-gray-500 self-end">({mobileNumber.length}/10)</small>
                )}
              </div>

            </div>
            <div>
              <div className="flex sm:gap-3 w-full justify-center items-center">
                <label className='text-[#777] whitespace-nowrap font-helvetica'>Subject*</label>

                <input
                  type="text"
                  value={subject}
                  style={{ backgroundColor: 'rgba(251, 211, 164, 0.7)' }}
                  maxLength='50'
                  onChange={(e) => handleChange(setSubject, e.target.value, 'subject')}
                  onFocus={() => handleFocus(setSubjectPlaceHolder)}
                  onBlur={() => handleBlur(setSubjectPlaceHolder, subject, 'Subject')}

                  className={`w-full outline-none ml-[61px]  ${errors.subject ? "border-red-500" : "border-[#F4B574]"}  border p-1 placeholder-gray-400 pl-4`}
                  placeholder={subjectPlaceHolder}
                />
              </div>
              <div className='flex justify-between'  >
                {errors.subject && <p className="text-red-500 text-xs">{errors.subject}</p>}
                {typingField === "subject" && (
                  <small className="text-gray-500 self-end">({subject.length}/50)</small>
                )}
              </div>

            </div>
            <div>
              <div className="flex sm:gap-3 w-full justify-center items-center">
                <div>
                <label className='text-[#777] whitespace-nowrap font-helvetica'>Message*</label>
                <div className='flex justify-between'  >
                {errors.message && <p className="text-red-500 text-xs">{errors.message}</p>}
                {typingField === "message" && (
                  <small className="text-gray-500 self-end">({message.length}/150)</small>
                )}
              </div>
              </div>
                <textarea
                  type="text"
                  value={message}
                  style={{ backgroundColor: 'rgba(251, 211, 164, 0.7)' }}
                  onChange={(e) => handleChange(setMessage, e.target.value, 'message')}
                  onFocus={() => handleFocus(setMessagePlaceHolder)}
                  onBlur={() => handleBlur(setMessagePlaceHolder, message, 'Message')}
                  className={`w-full outline-none ml-[50px] ${errors.message ? "border-red-500" : "border-[#F4B574]"} border p-1 placeholder-gray-400 pl-4`}
                  id="message"
                  maxLength='150'
                  rows="5"
                  placeholder={messagePlaceHolder}
                />
              </div>
              

            </div>
            <div className='w-full flex justify-center items-center ml-12'>

              <Button
                type="submit"
                className="bg-[#552038] hover:bg-[#411b2d] font-basicommercial text-lg  px-2 py-2 w-[150px]  text-white"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
        <hr className="flex-grow border-t mt-7 border-[#FFC683]" />
        <div className=' mt-7 flex flex-col sm:flex-row justify-center items-center text-[#777]  font-helvetica text-sm ' >
          Become a part of the Lidkar, join the team!
          For job enquiries, applications or internships write to us at
          <span className='ml-2 mr-2 font-helvetica font-bold text-sm text-black cursor-pointer'  onClick={() => window.location.href = "mailto:careers@lidkar.com"}> careers@lidkar.com</span> with your resume or portfolio.


        </div>
        <div className='flex justify-center items-center mt-3 mb-3'>

          <img className='w-[60px] h-[60px]' src={location} />
        </div>

        <p className='text-[#777] flex justify-center items-center mt-3 text-sm font-helvetica'>Our Corporate address:</p>
        <div className='mt-2 flex text-center justify-center items-center text-[#777]  font-helvetica text-sm '>
          No. 32, Dr. Babu Jagjivan Ram Lidkar Bhavana

        </div>
        <span className='flex justify-center  font-helvetica  text-sm text-[#777]  ml-2 '>,Millers bund area, Vasantha Nagara, Bengaluru - 560052 </span>

        <span className='flex justify-center cursor-pointer  font-helvetica font-bold text-sm text-black ml-2'  onClick={() => window.location.href = "mailto:customercare@lidkar.com"}>customercare@lidkar.com</span>
        <hr className="flex-grow border-t mt-7 border-[#FFC683] " />

      </div>
      <div className='mt-4'>

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.723010202521!2d77.55193187356844!3d12.989559914487298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d8cf49351ad%3A0x73e31bc04d4d3596!2sLIDKAR!5e0!3m2!1sen!2sin!4v1717129411221!5m2!1sen!2sin" width='100%' height="300" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
