import React from 'react'
import AdminSignin from '../components/Admin/AdminSignin'

const AdminLoginPage = () => {
  return (
    <div>
      <AdminSignin/>
    </div>
  )
}

export default AdminLoginPage
