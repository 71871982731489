import React from "react";
import AboutUsImg01 from "../../images/aboutusnew.jpg";
import AboutUsImg02 from "../../images/about2-01.png";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ReadMorePage = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
  
    const scrollToSection = (sectionId) => {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
  
        
       
      }
    };
  
    if (hash === '#mission') {
      scrollToSection('mission');
    } else if (hash === '#history') {
      scrollToSection('history');
    }else if (hash === '#brandLidkar') {
      scrollToSection('brandLidkar');
    }else  {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [location]);
  
  

  return (
    <div>
    <div id="intro"></div>
    
    <div className="w-1920  py-2 sm:px-[5rem] sm:py-2  mt-5 "  >
      <div className="flex  px-[2rem] sm:px-[0rem]  ">
        <div className="  pr-7 pt-7 pb-7 hidden sm:flex h-[480px] w-[420px]  ">
          <img src={AboutUsImg01} className="h-full w-full" />
        </div>
        <div className="flex flex-1 flex-col mt-10">
          <h1 className="sm:text-[41px] text-black font-Merriweather  mb-3 text-[26px] whitespace-nowrap sm:whitespace-wrap">
          The Corporation
          </h1>
          <p className="text-[14px] text-black   text-justify">
          Dr Babu Jagjivan Ram Leather Industries Development Corporation Ltd., was established by the Government of Karnataka in the year 1976 with the paramount objective of comprehensive development of leather industry in the State of Karnataka. 
          </p>
          <p className="text-[14px]  mt-6 text-justify ">
          The righteous objective is to uplift the downtrodden, enhance the socio-economic conditions of scheduled caste Leather Artisans, and aid all classes of people associated with leatherworks from tanners to artisans producing leather goods through an organization that could also be an instrument to showcase and channel the handcrafted products to the markets and connoisseurs of leather goods

          </p>
          <p className="text-[14px]  mt-6 text-justify ">
          The Corporation’s conscientious motive is to duly confer the credit for the creativity and deliver the deserved monetary value and accreditation for the toil that the leatherworkers undertake in the shadows, in poverty and in silence. And today with its incessant efforts paying dividends, the Corporation is world-renowned by its iconic flagship brand LIDKAR.

          </p>
        </div>
      </div>
    
      <div className="h-5" id="history" ></div>
     
      <section className="flex  pr-8 pb-8 pt-4 pl-0  rounded-sm"  >
     
      <div className=""></div>
        <div className="flex flex-1 flex-col">
          <div className="mt-3 flex md:flex-row flex-col ">
            <div className="flex flex-1 flex-col h-fit">
          <h1 className="text-[26px] flex font-Merriweather">
            History of Leather Artisans/ Leather craft
          </h1>
          <p className="text-[14px]  text-black  text-justify">
          Leather Craft in the state of Karnataka takes pride in its riveting history. 
Genesis of one of the most soul-churning, watershed moment in history dates back to 12th century CE. when a poor ardent devotee couple of Lord Shiva, Sri. Haralayya and his wife Smt. Kalyanamma, hailing from the dalit community (today classified as a Scheduled Caste under modern India's system of affirmative action), and an untouchable class of the society called “Chamara or Chamar”, engaged in the trade of shoemaking and leatherworks for a living, resided in the “untouchables” settlement of Kalyana town, Karnataka.

          </p>
          <p className="text-[14px] mt-6 text-black  text-justify">
          In the spiritus mundi of this eon, chamars were a echelons of the society that was bogged down under millennia of cultural and religious boycott, social stigma, neglect, repression and oppression, and time and again, outright exploitation.



          </p>
          
          <p className="text-[14px] mt-6 text-black  text-justify">
         The couple were fervent disciples of Sri. Basavanna, a Hindu religious reformer, teacher, theologian, minister and administrator of the royal treasury of the Kalachuri-dynasty King Bijjala I, who then was vigorously trying to transform oppressive caste-driven society and effectuate indispensable radical social reforms in the populace.





          </p>

          
         
          
            </div>
            <div className="flex flex-1 pr-6 pb-6 pl-6  hidden sm:flex">
          <img src={AboutUsImg02} className="w-full h-[400px]" />
        </div>
          </div>
          <div className="mt-3">
          <p className="text-[14px] text-black  text-justify">
          Sri. Basavanna, in order to eradicate untouchability and break the taboo around Chamaras, heartily visited “untouchables” settlement of Kalyana town and Sri. Haralayya’s house, performed pooja exuberantly and accepted the prasadam (oblation or offering). 
          </p>
            
          <p className="text-[14px] mt-6  text-black  text-justify">
         
         While on his way out, Sri Haralayya greeted Sri. Basavanna simply saying “Sharanu”. To the shock of everyone around, the pious Sri. Basavanna reciprocated the single salutation with ”Sharanu Sharanarthi”, signalling the acceptance of Sri. Haralayya as one of his own kith and kin. On hearing this, the gathered congregation including the entourage of Sri. Basavanna was pleasantly awestruck and Sri. Haralayya, the untouchable shoemaker was dumbstruck, felt profound gratitude on receiving such a courtesy bestowed upon by Sri. Basavanna, the treasurer of the King towards a poor, untouchable dalit Chamara. 
         
                   </p>
                   
                   <p className="text-[14px] mt-6 text-black  text-justify">
                  
                   Howbeit, Sri Haralayya conveyed to his wife that he felt excruciating agony as he had committed a terrible sin, since Sri. Basavanna had reciprocated his single greeting with a double salutation and he had failed to express his profound gratitude for this great act of acceptance and humility by Sri. Basavanna. 
         
                   </p>
            <p className="mt-6 text-justify  text-[14px]">
            Sri. Haralayya and his wife decided thereupon to contrive an extraordinaire offering for Sri. Basavanna. Out of love and to convey their infinite gratitude, they each cut a piece of skin from their thighs, tanned it and handcrafted a beautiful pair of chappals from it. So was the depth of their love, the purity of their devotion and heartfelt thankfulness.





            </p>
            <p className="mt-6 text-justify  text-[14px]">
            Sri. Haralayya then called upon Sri. Basavanna to present him the offering, the chappals made by the couple’s own skin; but Sri. Basavanna stupefied everyone around when he placed the chappals on his head in reverence and announced to the congregation gathered that “the shoes made from a Sharana’s skin, GOD only can wear it” and returned it back with humility
            </p>
            <p className="mt-6 text-justify  text-[14px]">
            One single monumental act by Sri. Basavanna, an awe-inspiring humane, devotional incident involving a poor, dalit chamara and the saga of the divine intervention that followed the insult of the chamara couple’s sacred chappals was pivotal in drastic decimation of ancient taboos, razed numerous societal barriers to the ground, transformed social norms, customs and practices that surrounded untouchability, community of dalits and chamaras across the kingdoms and hence, marked the dawn of a new era of social equality, high morality and justice. This saga of/in the fight for equality and social justice, humility and acceptance, love and devotion, sacrifice and gratitude, principles and values involving Sri. Basavanna and a poor dalit chamara couple has had their names etched in the annals of history for eternity. 
            </p>
            <p className="mt-6 text-justify  text-[14px]">
            These nearly 800-year-old chappals were kept almost intact in a specially-constructed small temple in Bijanalli. About two decades ago, experts from the Karnataka University, Dharwad and the University of Mysore visited Bijanalli, took a small sampling of the footwear for comprehensive investigation and scientific testing and later confirmed that they were indeed made of human skin. 
            </p>
            <p className="mt-6 text-justify  text-[14px]">
            Thus a fortiori, the fabled legend of Smt. & Sri Haralayya in the folklore has left its true mark in the annals of history of Leather artisans, social justice and equality, culture and the collective human psyche in the state of Karnataka.
            </p>
            <p className="mt-6 text-justify  text-[14px] "> <span className="text-[26px] flex font-Merriweather">Sub-Castes :</span>
             In northern Karnataka, the scheduled caste of chamaras are classified into various sub-castes based on the varied activities that they undertake within the leather industry such as flaying, tanning and footwear making, and are respectively called Madigas, Dhor and Samagara, also referred as Mochis, Machagara, Madigas in southern Karnataka. Overall, all the artisans belonging to these sub communities and sub-castes are called leather artisans.</p>
            <p className="mt-6 text-justify  text-[14px]"> <span className="text-[26px] flex font-Merriweather">The Industry :</span> The leather industry today, has burgeoned into a prodigious sector playing a pivotal role in the Indian economy, raking in billions worth of export revenue. This self-sustaining industry has been substantial and interminably successful in generating employment opportunities to millions among the backward communities of the society specifically, to the rural women folk</p>
          </div>
        </div>
      
       
      </section>
      <div className="" id="mission"></div>
      
      <section className="flex rounded-sm pr-8 pt-4 pb-3 pl-0" >
      
        <div className="flex flex-1 flex-col">
          <h1 className="text-[26px] sm:text-[26px] flex font-Merriweather">
            MISSION : Combating the Socio-Economic Adversity & Inequality
          </h1>
          <div className="mt-3">
            <p className="text-[14px] text-black  text-justify">
            The pivotal aim of the corporation is towards the upliftment of the downtrodden and betterment of the socio-economic conditions of scheduled caste leather artisans in the State of Karnataka, a large chunk of the populace of which is still enduring harrowing adversity and misery within the confines of poverty, illiteracy and the, lack of bare minimum standard of living. 


            </p>
            <p className="mt-6 text-justify  text-[14px]">
            The leather artisans in the state are economically most backward and still engaged in making and selling traditional footwear. Lamentably, they are still being exploited by private dealers and hence are not receiving the right price for their merchandise or the acknowledgment for the sweat of their brow. Even today, many of the artisans still linger by the road, without proper shelter and are actively engaged in footwear repair for their livelihood and daily sustenance. The income what they earn is barely enough to survive and can be termed, hand to mouth.



            </p>
            <p className="mt-6 text-justify  text-[14px]">
            The Government of Karnataka, during the tenure of the then Hon’ble Chief Minister Sri. Devaraja Urs, bearing in mind the welfare of the SC leather artisans had earnestly established the Karnataka Leather Industries Development Corporation Ltd., in the year 1976 and started implementing various developmental schemes.


            </p>
            <p className="mt-6 text-justify  text-[14px]">
            The nomenclature of the corporation was changed as Dr. Babu Jagjivan Ram Leather Industries Development Corporation Limited, with effect from 13th of May, 2010. The Corporation, since its inception was under the Administrative control Commerce and Industries department and was transferred under the purview of Social Welfare Department from the 15th of December, 2011. Since then, the corporation has been a beneficiary of ample allocation of funds from the Social Welfare Department and the Corporation is proactively implementing numerous schemes, some solely concentrating on the welfare of the artisan community.

            </p>
            <p className="mt-6 text-justify  text-[14px]">
            Apart from the developmental schemes, the Corporation is relentlessly engaged in a myriad of commercial activities to promote leather industry and support leather craftsmen. The genuine leather products made by leather artisans and small scale industries are being marketed and sold under the Brand name Lidkar, through a network of fifteen Lidkar Leather Emporia spread across the breadth of the state and has developed a parallel revenue channel through attaining bulk orders from various Governmental Departments/ Corporations and private companies. The Corporation actively undertakes exhibition cum sales events that are routinely organized in numerous places. Corporation leaves no stone unturned in its mission of extending marketing assistance for the products made by local artisans and the SSI units. 

            </p>
            {/* <p className=" text-[14px]">
              Apart from the Developmental Schemes, the Corporation is
              relentlessly engaged in a myriad of commercial activities to
              promote leather industry and support leather workers. The genuine
              leather products made by leather artisans and small scale
              industries are being marketed and sold under Lidkar’s Brand name,
              through a network of 15 Lidkar Leather Emporia spread across the
              breadth of the state and has developed a parallel revenue channel
              through attaining bulk orders from various Governmental
              Departments/ Corporations and Private Companies. The Corporation
              actively undertakes Exhibition cum sales events that are routinely
              organized in different places. Corporation leaves no stone
              unturned is its mission of extending marketing assistance for the
              products made by local artisans and SSI units.
            </p> */}
          </div>
        </div>
      </section>

      <div className="h-5" id="brandLidkar"></div>
       <section>
         <h1 className='text-xl font-helvetica mb-4 mt-3 font-semibold'>BRAND LIDKAR</h1>
             <p className='mb-4   text-[14px] text-justify'>
             <span className="font-bold">Lidkar... is a </span>confluence of ancient, traditional, rich artistry, authentic leather and sui generis designs.
</p>
<p className='mb-4   text-[14px] text-justify'>Lidkar is an iconic, world-renowned, premium Indian leather brand, founded in the year 1976.</p>
             <p className='mb-4   text-[14px] text-justify'>
             Lidkar’s legendary lustre is primarily due to its folklore-inspired signature weave patterns inherent to few communities of local kannadiga artisans possessing the know-how and skill-set that has been passed down the generations. 

</p>
             <p className='mb-4   text-[14px] text-justify'>
             Lidkar is the archetypal embodiment of exquisite leather quality that defies the sands of time, finesse and master craftsmanship coupled with innovative, stylish and timeless designs, that has enamoured fashionistas and bewitched leather aficionados worldwide

</p>
             <p className='mb-4   text-[14px] text-justify'>
             Every single merchandise is a work of nuanced contemporary art, meticulously handcrafted by skilled artisans that breathe life into it and bequeath a story to fit your individuality for an everlasting companionship. Every product is in vogue with the fashion trends raging across the globe and a step ahead of the rest with a little more à la mode.

</p>
             <p className='mb-4   text-[14px] text-justify'>
             Lidkar is where India’s finest quality leather blends with eons of knowledge and mastery craftsmanship. Lidkar has a long history of bringing forth exquisite, high-quality and wide-array of leather products, including footwear, handbags, wallets, and belts. 
</p>
             <p className='mb-4   text-[14px] text-justify'>
             Lidkar today celebrates the patronage of leather connoisseurs spanning erstwhile royalty, celebrities and divas of Indian cinema, A-listers from sports, politics, art & culture, fashion world and other prominent personas from all walks of life.
</p>
             <p className='mb-4   text-[14px] text-justify'>
     
</p>
             <p className='mb-4   text-[14px] text-justify'>
             Lidkar is unequivocally a tableau vivant, where luxury meets comfort.
</p>
             <p className='mb-4   text-xl text-justify font-semibold'>
             FASHION MISSION : A perfect amalgamation of the past and the future!


</p>
             <p className='mb-4   text-[14px] text-justify'>
             Lidkar is an brand extraordinaire where the ancient traditional art forms  well-nigh consigned to oblivion are resurrected and infused with a new lease of life to the closely guarded, secretive primaeval leather eruditions that are met with futuristic designs and exigencies.
</p>
             <p className='mb-4   text-[14px] text-justify'>
             Crafting artisanal leather goods, the brand unveils and flaunts to the world, beaucoup distinctive leather weaving designs intrinsic to traditional artisans and leathersmiths, that instantly confers Lidkar it’s iconic mien and a distinctive panache. 
</p>
             <p className='mb-4   text-[14px] text-justify'>
            <span className='font-semibold text-xl'> QUALITY, TIMELESS AND ETERNAL</span><br/>
  A cornucopia of artisans still harness the closely-guarded secrets of traditional art of leather processing that’s been around for eons and passed down the generations as a family heirloom. The corollary is the exquisite natural texture, fine quality, par excellence tensile strength and a durability that defies the sands of time.

</p>
<div>


            
             <p className='mb-2   text-xl text-justify font-semibold'>
             EVOVLING INTO “THE BRAND”
</p>
<p className='mb-1   text-[14px] text-justify '>
              The Salient
</p>
             <p className='mb-1  text-[14px] text-justify'>
             Leather is Legendary
</p>
             <p className='mb-1  text-[14px] text-justify'>
             Leather is Luxury
</p>
             <p className='mb-1  text-[14px] text-justify'>
             Leather is LIDKAR
</p>
</div>
<p className='mb-4   text-[14px] text-justify'>

became the brand's fabled 3L slogan because the handcrafted Lidkar's merchandise are expeditiously distinguishable  and theretofore, the logo was displayed discreetly on the inside of its products.
     </p> 
     <div>


            
             <p className='mb-2   text-xl text-justify font-semibold'>
             LEGAL DESCLAIMER
</p>
<p className='mb-1   text-[14px] text-justify '>
As per the agreement reached between Dr. Babu Jagjivan Ram Leather Industries Development Corporation Ltd and SunPlus Software Technologies, it comes perview of Sun Plus Software Technologies to Design, Develop, Run and maintain the E-Commerce portal and E-Commerce website of the brand lidkar owned by Dr. babu, a govermental org located at LLE No.32 Dr. Babu Jagjivan Ram Lidkar Bhavan, Vasanthnagar, Bangalore - 560052. All further queries maybe directed brand Lidkar or Dr. Babu Jagjivan Ram Leather Industries Development Corporation Ltd 

</p>
 
</div>      
       </section>

    </div>
    </div>
  );
};

export default ReadMorePage;
