import { apiConnector } from "../apiConnector";
import { orderEndPoints } from "../api";

export const getAllOrders = async (token) => {
  let result = [];
 
  try {
    const response = await apiConnector(
      "GET",
      orderEndPoints.GET_ALL_ORDERS,
      {},
      {
        Authorization: `Bearer ${token}`,
      },
      {}
    );
  
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getOrderDetailsById = async (orderId, token) => {
    let result = null;
  
    try {
      const response = await apiConnector(
        "GET",
        orderEndPoints.GET_ORDER_DETAILS_BY_ID,
        {},
        {
          Authorization: `Bearer ${token}`,
        },
        {orderId}
      );
      result = response.data;
      
      return result;
    } catch (error) {
      console.log(error);
    }
  };
export const getOrderDetailsAdminById = async (orderId, token) => {
    let result = null;
   
    try {
      const response = await apiConnector(
        "GET",
        orderEndPoints.GET_ORDER_DETAILS_BY_AdminID,
        {},
        {
          Authorization: `Bearer ${token}`,
        },
        {orderId}
      );
      result = response.data;
      
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  export const GET_Order_Placed = async (token) => {
    console.log(token,'vada')
    let result = [];
  
    try {
      const response = await apiConnector(
        "GET",
        orderEndPoints.GET_ALL_ORDERS_Placed,
        {},
        {
          Authorization: "Bearer " + token,
        }
      );
     
      result = response.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  export const CANCEL_ORDER = async (token,orderId) => {
  
   
    try {
      const response = await apiConnector(
        "POST",
        orderEndPoints.CANCEL_ORDER,
        {orderId},
        {
          Authorization: `Bearer ${token}`,
        },
        {}
      );
    
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  

