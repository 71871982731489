import { getOrderDetailsById } from '../../services/operations/LogisticsApi';
import { CreatePickUp, generateArticleId } from '../../services/operations/LogisticsApi';
import React, { useState, useEffect } from 'react';
import { useParams,useLocation, useNavigate } from "react-router-dom";
const PickUp = () => {
  const { id } = useParams();
  console.log(id,"id")
 const location =  useLocation()
 console.log("location", location.state.orderId);
 const navigate = useNavigate();
 const [recipientAddress,setRecipientAddress] = useState({
  receipientaddressline1: '',
  receipientcity: '',
  receipientpincode: '',

 })
  const [values, setValues] = useState({
    identifier: '99999999',
    articleid: '',
    articletype: 'SP',
    articlelength: 0,
    articlewidth: 0,
    articleheight: 0,
    articleweight: 340.0,
    codvalue: 0.0,
    insurancevalue: 0.0,
    proofofdeliveryflag: '',
    customerid: '',
    contractnumber: '',
    sendername: '',
    senderaddressline1: '',
    senderaddressline2: '',
    senderaddressline3: '',
    sendercity: '',
    senderpincode: '',
    sendercountry: '',
    senderemail: '',
    sendermobile: '',
    nameofreceipient: '',
    // receipientaddressline1: '',
    receipientaddressline2: '',
    receipientaddressline3: '',
    // receipientcity: '',
    // receipientpincode: '',
    receipientcountry: '',
    receipientemail: '',
    receipientmobile: '',
    pickupname:'',
    pickupaddressline1:"",
    pickupaddressline2:"",
    pickupaddressline3:"",
    pickupcity:"",
    pickupaddresspincode:"",
    pickupcountry:"",
    pickupaddressemail:"",
    pickupaddressmobile:"",

    refid: ''
  });
  const [articalId,setArticalId] = useState("");

    const token = JSON.parse(localStorage.getItem("adminToken"));
  console.log("token", token)
  const [errors, setErrors] = useState({});
   useEffect(()=>{
      const fetchArticleId = async()=>{
        try{

          const response = await generateArticleId(location.state.orderId, token);
          console.log(response,"fetchArticleId")
          setArticalId(response)
        }catch (error){
            console.log(error)
        }
      }
      const getOrderDetailsBy = async()=>{
        try{

          const response = await getOrderDetailsById(location.state.orderId, token);
          console.log(response,"getOrderDetailsBy")
    
          setRecipientAddress({
            receipientaddressline1: response?.addressDto?.landmark,
            receipientcity:response?.addressDto?.city,
            receipientpincode:response?.addressDto?.pincode
          })
        }catch (error){
            console.log(error)
        }
      }
      getOrderDetailsBy()
      fetchArticleId()
   },[location.state.orderId])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };
  const handleRecipientChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };
  const validate = () => {
    const validationErrors = {};
  
    if (!values.identifier) validationErrors.identifier = "Identifier is required.";
    // if (!values.articleid) validationErrors.articleid = "Article ID is required.";
    if (!values.articletype) validationErrors.articletype = "Article Type is required.";
    if (!values.articlelength) validationErrors.articlelength = "Article Length is required.";
    if (!values.articlewidth) validationErrors.articlewidth = "Article Width is required.";
    if (!values.articleheight) validationErrors.articleheight = "Article Height is required.";
    if (!values.articleweight) validationErrors.articleweight = "Article Weight is required.";
    if (!values.codvalue) validationErrors.codvalue = "COD Value is required.";
    if (!values.insurancevalue) validationErrors.insurancevalue = "Insurance Value is required.";
    if (!values.proofofdeliveryflag) validationErrors.proofofdeliveryflag = "Proof of Delivery Flag is required.";
    if (!values.customerid) validationErrors.customerid = "Customer ID is required.";
    if (!values.contractnumber) validationErrors.contractnumber = "Contract Number is required.";
    if (!values.sendername) validationErrors.sendername = "Sender Name is required.";
    if (!values.senderaddressline1) validationErrors.senderaddressline1 = "Sender Address Line 1 is required.";
    if (!values.senderaddressline2) validationErrors.senderaddressline2 = "Sender Address Line 2 is required.";
    if (!values.senderaddressline3) validationErrors.senderaddressline3 = "Sender Address Line 3 is required.";
    if (!values.sendercity) validationErrors.sendercity = "Sender City is required.";
    if (!values.senderpincode) validationErrors.senderpincode = "Sender Pincode is required.";
    if (!values.sendercountry) validationErrors.sendercountry = "Sender Country is required.";
    if (!values.senderemail) validationErrors.senderemail = "Sender Email is required.";
    if (!values.sendermobile) validationErrors.sendermobile = "Sender Mobile is required.";
    if (!values.nameofreceipient) validationErrors.nameofreceipient = "Recipient Name is required.";
    // if (!values.receipientaddressline1) validationErrors.receipientaddressline1 = "Recipient Address Line 1 is required.";
    if (!values.receipientaddressline2) validationErrors.receipientaddressline2 = "Recipient Address Line 2 is required.";
    if (!values.receipientaddressline3) validationErrors.receipientaddressline3 = "Recipient Address Line 3 is required.";
    // if (!values.receipientcity) validationErrors.receipientcity = "Recipient City is required.";
    // if (!values.receipientpincode) validationErrors.receipientpincode = "Recipient Pincode is required.";
    if (!values.receipientcountry) validationErrors.receipientcountry = "Recipient Country is required.";
    if (!values.receipientemail) validationErrors.receipientemail = "Recipient Email is required.";
    if (!values.receipientmobile) validationErrors.receipientmobile = "Recipient Mobile is required.";
    if (!values.pickupname) validationErrors.pickupname = "Pickup Name is required.";
    if (!values.pickupaddressline1) validationErrors.pickupaddressline1 = "Pickup addressline1 is required.";
    if (!values.pickupaddressline2) validationErrors.pickupaddressline2 = "Pickup addressline2 is required.";
    if (!values.pickupaddressline3) validationErrors.pickupaddressline3 = "Pickup addressline3 is required.";
    if (!values.pickupcity) validationErrors.pickupcity = "Pickupcity is required.";
    if (!values.pickupaddresspincode) validationErrors.pickupaddresspincode = "Pickup Address Pincode is required.";
    if (!values.pickupcountry) validationErrors.pickupcountry = "Pickup Country is required.";
    if (!values.pickupaddressemail) validationErrors.pickupaddressemail = "Pickup Address Email is required.";
    if (!values.pickupaddressmobile) validationErrors.pickupaddressmobile = "Pickup Address Mobile is required.";
    if (!values.refid) validationErrors.refid = "Reference ID is required.";
  
    return validationErrors;
  };
  
 
console.log("recipientAddress",recipientAddress)
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("values",values);
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const pickUpObject = {
        identifier: "99999999",
        articleid: articalId,
        orderId:location?.state?.orderId,
        articletype: 'SP',
        articlelength: Number(values.articlelength),
        articlewidth: Number(values.articlewidth),
        articleheight: Number(values.articleheight),
        articleweight: Number(values.articleweight),
        codvalue: Number(values.codvalue),
        insurancevalue: Number(values.insurancevalue),
        proofofdeliveryflag: "true",
        customerid: values.customerid,
        contractnumber: values.contractnumber,
        sendername:values.sendername,
        senderaddressline1:values.senderaddressline1,
        senderaddressline2:values.senderaddressline2,
        senderaddressline3: values.senderaddressline3,
        sendercity: values.sendercity,
        senderpincode: values.senderpincode,
        sendercountry: values.sendercountry,
        senderemail: values.senderemail,
        sendermobile: values.sendermobile,
        nameofreceipient: values.nameofreceipient,
        receipientaddressline1: recipientAddress.receipientaddressline1,
        receipientaddressline2: values.receipientaddressline2,
        receipientaddressline3: values.receipientaddressline3,
        receipientcity: recipientAddress.receipientcity,
        receipientpincode: recipientAddress.receipientpincode,
        receipientcountry: values.receipientcountry,
        receipientemail: values.receipientemail,
        receipientmobile: values.receipientmobile,
        pickupname: values.pickupname,
    pickupaddressline1: values.pickupaddressline1,
    pickupaddressline2: values.pickupaddressline2,
    pickupaddressline3: values.pickupaddressline3,
    pickupcity: values.pickupcity,
    pickupaddresspincode: values.pickupaddresspincode,
    pickupcountry: values.pickupcountry,
    pickupaddressemail: values.pickupaddressemail,
    pickupaddressmobile: values.pickupaddressmobile,
        refid: values.refid,
      }
  // const  pickUpObject =   {
  //       identifier: "99999999",
  //       articleid: "EK990583165IN",
  //       articletype: "SP",
  //       articlelength: 0,
  //       articlewidth: 0,
  //       articleheight: 0,
  //       articleweight: 500,
  //       codvalue: 0.0,
  //       insurancevalue: 0.0,
  //       proofofdeliveryflag: false,
  //       customerid: "0000000000",
  //       contractnumber: "0",
  //       sendername: "Test Sender 1",
  //       senderaddressline1: "No 23",
  //       senderaddressline2: "AGS Colony",
  //       senderaddressline3: null,
  //       sendercity: "Mysore",
  //       senderpincode: 570010,
  //       sendercountry: "IN",
  //       senderemail: "praveen@gmail.com",
  //       sendermobile: "9710282493",
  //       nameofreceipient: "NAGENDRA",
  //       receipientaddressline1: "No.22",
  //       receipientaddressline2: "F22",
  //       receipientaddressline3: "",
  //       receipientcity: "Chennai",
  //       receipientpincode: 600125,
  //       receipientcountry: "IN",
  //       receipientemail: "kumar@gmail.com",
  //       receipientmobile: "9841360610",
  //       pickupname: "Test Pickup 1",
  //       pickupaddressline1: "No.23",
  //       pickupaddressline2: "Raghavendra Nagar",
  //       pickupaddressline3: "",
  //       pickupcity: "Chennai",
  //       pickupaddresspincode: 570010,
  //       pickupcountry: "IN",
  //       pickupaddressemail: "",
  //       pickupaddressmobile: "9710282493",
  //       refid: "CSI Testing"
  //   }
      try{
        const response = CreatePickUp(pickUpObject,token);
        console.log(response,"response")
        navigate("/admin/dashboard")
        // window.location.reload();

      }catch(error){
          console.log("errror", error)
      }
      console.log(pickUpObject,"pickUpObject");
    }
      
  };

  const handleClear = (e) => {
    e.preventDefault();
    setValues({
      identifier: 'GEM',
      articleid: '',
      articletype: 'SP',
      articlelength: 0,
      articlewidth: 0,
      articleheight: 0,
      articleweight: 340.0,
      codvalue: 0.0,
      insurancevalue: 0.0,
      proofofdeliveryflag: '',
      customerid: '',
      contractnumber: '',
      sendername: '',
      senderaddressline1: '',
      senderaddressline2: '',
      senderaddressline3: '',
      sendercity: '',
      senderpincode: '',
      sendercountry: '',
      senderemail: '',
      sendermobile: '',
      nameofreceipient: '',
      receipientaddressline1: '',
      receipientaddressline2: '',
      receipientaddressline3: '',
      receipientcity: '',
      receipientpincode: '',
      receipientcountry: '',
      receipientemail: '',
      receipientmobile: '',
      refid: ''
    });
    setErrors({});
  };

  return (
    <div className='p-3 h-[600px] overflow-y-auto'>
      <form onSubmit={handleSubmit}>
        <h1 className='text-black font-bold text-[21px]'>PickUp Form</h1>
        <div className='grid grid-cols-2 gap-5 mt-5'>
          {/* Identifier */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Identifier <span className="text-red-600">*</span></label>
            <input
              type="text"
              name="identifier"
              value="99999999"
              onChange={handleChange}
              disabled
              className="border px-4 py-2 rounded-md"
            />
            {errors.identifier && <div className="text-red-600 text-xs">{errors.identifier}</div>}
          </div>

          {/* Article ID */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Article ID <span className="text-red-600">*</span></label>
            <input
              type="text"
              name="articleid"
              value={articalId}
              // onChange={handleChange}
              placeholder="Enter Article ID"
              className="border px-4 py-2 rounded-md"
            />
            {/* {errors.articleid && <div className="text-red-600 text-xs">{errors.articleid}</div>} */}
          </div>

          {/* Article Type */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Article Type <span className="text-red-600">*</span></label>
            <input
              type="text"
              name="articletype"
              value="SP"
              onChange={handleChange}
              placeholder="Enter Article Type"
              className="border px-4 py-2 rounded-md"
            />
            {errors.articletype && <div className="text-red-600 text-xs">{errors.articletype}</div>}
          </div>

          {/* Article Length */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Article Length</label>
            <input
              type="number"
              name="articlelength"
              value={values.articlelength}
              onChange={handleChange}
              placeholder="Enter Article Length"
              className="border px-4 py-2 rounded-md"
            />
            {errors.articlelength && <div className="text-red-600 text-xs">{errors.articlelength}</div>}
          </div>

          {/* Article Width */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Article Width</label>
            <input
              type="number"
              name="articlewidth"
              value={values.articlewidth}
              onChange={handleChange}
              placeholder="Enter Article Width"
              className="border px-4 py-2 rounded-md"
            />
            {errors.articlewidth && <div className="text-red-600 text-xs">{errors.articlewidth}</div>}
          </div>

          {/* Article Height */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Article Height</label>
            <input
              type="number"
              name="articleheight"
              value={values.articleheight}
              onChange={handleChange}
              placeholder="Enter Article Height"
              className="border px-4 py-2 rounded-md"
            />
            {errors.articleheight && <div className="text-red-600 text-xs">{errors.articleheight}</div>}
          </div>

          {/* Article Weight */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Article Weight</label>
            <input
              type="number"
              name="articleweight"
              value={values.articleweight}
              onChange={handleChange}
              placeholder="Enter Article Weight"
              className="border px-4 py-2 rounded-md"
            />
            {errors.articleweight && <div className="text-red-600 text-xs">{errors.articleweight}</div>}
          </div>

          {/* COD Value */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">COD Value</label>
            <input
              type="number"
              name="codvalue"
              value={values.codvalue}
              onChange={handleChange}
              placeholder="Enter COD Value"
              className="border px-4 py-2 rounded-md"
            />
            {errors.codvalue && <div className="text-red-600 text-xs">{errors.codvalue}</div>}
          </div>

          {/* Insurance Value */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Insurance Value</label>
            <input
              type="number"
              name="insurancevalue"
              value={values.insurancevalue}
              onChange={handleChange}
              placeholder="Enter Insurance Value"
              className="border px-4 py-2 rounded-md"
            />
            {errors.insurancevalue && <div className="text-red-600 text-xs">{errors.insurancevalue}</div>}
          </div>

          {/* Proof of Delivery Flag */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Proof of Delivery Flag</label>
            <input
              type="text"
              name="proofofdeliveryflag"
              value={values.proofofdeliveryflag}
              onChange={handleChange}
              placeholder="Enter Proof of Delivery Flag"
              className="border px-4 py-2 rounded-md"
            />
            {errors.proofofdeliveryflag && <div className="text-red-600 text-xs">{errors.proofofdeliveryflag}</div>}
          </div>

          {/* Customer ID */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Customer ID</label>
            <input
              type="text"
              name="customerid"
              value={values.customerid}
              onChange={handleChange}
              placeholder="Enter Customer ID"
              className="border px-4 py-2 rounded-md"
            />
            {errors.customerid && <div className="text-red-600 text-xs">{errors.customerid}</div>}
          </div>

          {/* Contract Number */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Contract Number</label>
            <input
              type="Number"
              name="contractnumber"
              value={values.contractnumber}
              onChange={handleChange}
              placeholder="Enter Contract Number"
              className="border px-4 py-2 rounded-md"
            />
            {errors.contractnumber && <div className="text-red-600 text-xs">{errors.contractnumber}</div>}
          </div>

          {/* Sender Name */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender Name</label>
            <input
              type="text"
              name="sendername"
              value={values.sendername}
              onChange={handleChange}
              placeholder="Enter Sender Name"
              className="border px-4 py-2 rounded-md"
            />
            {errors.sendername && <div className="text-red-600 text-xs">{errors.sendername}</div>}
          </div>

          {/* Sender Address Line 1 */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender Address Line 1</label>
            <input
              type="text"
              name="senderaddressline1"
              value={values.senderaddressline1}
              onChange={handleChange}
              placeholder="Enter Sender Address Line 1"
              className="border px-4 py-2 rounded-md"
            />
            {errors.senderaddressline1 && <div className="text-red-600 text-xs">{errors.senderaddressline1}</div>}
          </div>

          {/* Sender Address Line 2 */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender Address Line 2</label>
            <input
              type="text"
              name="senderaddressline2"
              value={values.senderaddressline2}
              onChange={handleChange}
              placeholder="Enter Sender Address Line 2"
              className="border px-4 py-2 rounded-md"
            />
            {errors.senderaddressline2 && <div className="text-red-600 text-xs">{errors.senderaddressline2}</div>}
          </div>

          {/* Sender Address Line 3 */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender Address Line 3</label>
            <input
              type="text"
              name="senderaddressline3"
              value={values.senderaddressline3}
              onChange={handleChange}
              placeholder="Enter Sender Address Line 3"
              className="border px-4 py-2 rounded-md"
            />
            {errors.senderaddressline3 && <div className="text-red-600 text-xs">{errors.senderaddressline3}</div>}
          </div>

          {/* Sender City */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender City</label>
            <input
              type="text"
              name="sendercity"
              value={values.sendercity}
              onChange={handleChange}
              placeholder="Enter Sender City"
              className="border px-4 py-2 rounded-md"
            />
            {errors.sendercity && <div className="text-red-600 text-xs">{errors.sendercity}</div>}
          </div>

          {/* Sender Pincode */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender Pincode</label>
            <input
              type="Number"
              name="senderpincode"
              value={values.senderpincode}
              onChange={handleChange}
              placeholder="Enter Sender Pincode"
              className="border px-4 py-2 rounded-md"
            />
            {errors.senderpincode && <div className="text-red-600 text-xs">{errors.senderpincode}</div>}
          </div>

          {/* Sender Country */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender Country</label>
            <input
              type="text"
              name="sendercountry"
              value={values.sendercountry}
              onChange={handleChange}
              placeholder="Enter Sender Country"
              className="border px-4 py-2 rounded-md"
            />
            {errors.sendercountry && <div className="text-red-600 text-xs">{errors.sendercountry}</div>}
          </div>

          {/* Sender Email */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender Email</label>
            <input
              type="email"
              name="senderemail"
              value={values.senderemail}
              onChange={handleChange}
              placeholder="Enter Sender Email"
              className="border px-4 py-2 rounded-md"
            />
            {errors.senderemail && <div className="text-red-600 text-xs">{errors.senderemail}</div>}
          </div>

          {/* Sender Mobile */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender Mobile</label>
            <input
              type="Number"
              name="sendermobile"
              value={values.sendermobile}
              onChange={handleChange}
              placeholder="Enter Sender Mobile"
              className="border px-4 py-2 rounded-md"
            />
            {errors.sendermobile && <div className="text-red-600 text-xs">{errors.sendermobile}</div>}
          </div>

          {/* Recipient Name */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Name</label>
            <input
              type="text"
              name="nameofreceipient"
              value={values.nameofreceipient}
              onChange={handleChange}
              placeholder="Enter Recipient Name"
              className="border px-4 py-2 rounded-md"
            />
            {errors.nameofreceipient && <div className="text-red-600 text-xs">{errors.nameofreceipient}</div>}
          </div>

          {/* Recipient Address Line 1 */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Address Line 1</label>
            <input
              type="text"
              name="receipientaddressline1"
              value={recipientAddress.receipientaddressline1}
              // onChange={handleRecipientChange}
              disabled
              placeholder="Enter Recipient Address Line 1"
              className="border px-4 py-2 rounded-md"
            />
            {/* {errors.receipientaddressline1 && <div className="text-red-600 text-xs">{errors.receipientaddressline1}</div>} */}
          </div>

          {/* Recipient Address Line 2 */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Address Line 2</label>
            <input
              type="text"
              name="receipientaddressline2"
              value={values.receipientaddressline2}
              onChange={handleChange}
              placeholder="Enter Recipient Address Line 2"
              className="border px-4 py-2 rounded-md"
            />
            {errors.receipientaddressline2 && <div className="text-red-600 text-xs">{errors.receipientaddressline2}</div>}
          </div>

          {/* Recipient Address Line 3 */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Address Line 3</label>
            <input
              type="text"
              name="receipientaddressline3"
              value={values.receipientaddressline3}
              onChange={handleChange}
              placeholder="Enter Recipient Address Line 3"
              className="border px-4 py-2 rounded-md"
            />
            {errors.receipientaddressline3 && <div className="text-red-600 text-xs">{errors.receipientaddressline3}</div>}
          </div>

          {/* Recipient City */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient City</label>
            <input
              type="text"
              name="receipientcity"
              value={recipientAddress.receipientcity}
              onChange={handleRecipientChange}
              disabled
              placeholder="Enter Recipient City"
              className="border px-4 py-2 rounded-md"
            />
            {/* {errors.receipientcity && <div className="text-red-600 text-xs">{errors.receipientcity}</div>} */}
          </div>

          {/* Recipient Pincode */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Pincode</label>
            <input
              type="text"
              name="receipientpincode"
              value={recipientAddress.receipientpincode}
              onChange={handleRecipientChange}
              disabled
              placeholder="Enter Recipient Pincode"
              className="border px-4 py-2 rounded-md"
            />
            {/* {errors.receipientpincode && <div className="text-red-600 text-xs">{errors.receipientpincode}</div>} */}
          </div>

          {/* Recipient Country */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Country</label>
            <input
              type="text"
              name="receipientcountry"
              value={values.receipientcountry}
              onChange={handleChange}
              placeholder="Enter Recipient Country"
              className="border px-4 py-2 rounded-md"
            />
            {errors.receipientcountry && <div className="text-red-600 text-xs">{errors.receipientcountry}</div>}
          </div>

          {/* Recipient Email */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Email</label>
            <input
              type="email"
              name="receipientemail"
              value={values.receipientemail}
              onChange={handleChange}
              placeholder="Enter Recipient Email"
              className="border px-4 py-2 rounded-md"
            />
            {errors.receipientemail && <div className="text-red-600 text-xs">{errors.receipientemail}</div>}
          </div>

          {/* Recipient Mobile */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Mobile</label>
            <input
              type="Number"
              name="receipientmobile"
              value={values.receipientmobile}
              onChange={handleChange}
              placeholder="Enter Recipient Mobile"
              className="border px-4 py-2 rounded-md"
            />
            {errors.receipientmobile && <div className="text-red-600 text-xs">{errors.receipientmobile}</div>}
          </div>

          {/* Reference ID */}
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Reference ID</label>
            <input
              type="text"
              name="refid"
              value={values.refid}
              onChange={handleChange}
              placeholder="Enter Reference ID"
              className="border px-4 py-2 rounded-md"
            />
            {errors.refid && <div className="text-red-600 text-xs">{errors.refid}</div>}
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Pickup Name</label>
            <input
              type="text"
              name="pickupname"
              value={values.pickupname}
              onChange={handleChange}
              placeholder="Enter Pickup Name"
              className="border px-4 py-2 rounded-md"
            />
            {errors.pickupname && <div className="text-red-600 text-xs">{errors.pickupname}</div>}
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Pickup Address Line1</label>
            <input
              type="text"
              name="pickupaddressline1"
              value={values.pickupaddressline1}
              onChange={handleChange}
              placeholder="Enter Pickup Address Line1"
              className="border px-4 py-2 rounded-md"
            />
            {errors.pickupaddressline1 && <div className="text-red-600 text-xs">{errors.pickupaddressline1}</div>}
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Pickup Address Line2</label>
            <input
              type="text"
              name="pickupaddressline2"
              value={values.pickupaddressline2}
              onChange={handleChange}
              placeholder="Enter Pickup Address Line2"
              className="border px-4 py-2 rounded-md"
            />
            {errors.pickupaddressline2 && <div className="text-red-600 text-xs">{errors.pickupaddressline2}</div>}
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Pickup Address Line3</label>
            <input
              type="text"
              name="pickupaddressline3"
              value={values.pickupaddressline3}
              onChange={handleChange}
              placeholder="Enter Pickup Address Line3"
              className="border px-4 py-2 rounded-md"
            />
            {errors.pickupaddressline3 && <div className="text-red-600 text-xs">{errors.pickupaddressline3}</div>}
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Pickup City</label>
            <input
              type="text"
              name="pickupcity"
              value={values.pickupcity}
              onChange={handleChange}
              placeholder="Enter Pickup City"
              className="border px-4 py-2 rounded-md"
            />
            {errors.pickupcity && <div className="text-red-600 text-xs">{errors.pickupcity}</div>}
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Pickup Address Pincode</label>
            <input
              type="Number"
              name="pickupaddresspincode"
              value={values.pickupaddresspincode}
              onChange={handleChange}
              placeholder="Enter Pickup Address Pincode"
              className="border px-4 py-2 rounded-md"
            />
            {errors.pickupaddresspincode && <div className="text-red-600 text-xs">{errors.pickupaddresspincode}</div>}
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Pickup Country</label>
            <input
              type="text"
              name="pickupcountry"
              value={values.pickupcountry}
              onChange={handleChange}
              placeholder="Enter Pickup Country"
              className="border px-4 py-2 rounded-md"
            />
            {errors.pickupcountry && <div className="text-red-600 text-xs">{errors.pickupcountry}</div>}
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Pickup Address Email</label>
            <input
              type="text"
              name="pickupaddressemail"
              value={values.pickupaddressemail}
              onChange={handleChange}
              placeholder="Enter Pickup Address Email"
              className="border px-4 py-2 rounded-md"
            />
            {errors.pickupaddressemail && <div className="text-red-600 text-xs">{errors.pickupaddressemail}</div>}
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Pickup Address Mobile</label>
            <input
              type="text"
              name="pickupaddressmobile"
              value={values.pickupaddressmobile}
              onChange={handleChange}
              placeholder="Enter Pickup Address Mobile"
              className="border px-4 py-2 rounded-md"
            />
            {errors.pickupaddressmobile && <div className="text-red-600 text-xs">{errors.pickupaddressmobile}</div>}
          </div>
        </div>

        {/* Submit and Clear Buttons */}
        <div className="mt-6 flex justify-start gap-5">
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md">Submit</button>
          <button onClick={handleClear} className="bg-gray-500 text-white py-2 px-4 rounded-md">Clear</button>
        </div>
      </form>
    </div>
  );
};

export default PickUp;
