import React, { useState } from 'react';

const Tarif = () => {
  const [values, setValues] = useState({
    service: "",
    sourcepin: "",
    destinationpin: "",
    weight: "",
    length: "",
    breadth: "",
    height: "",
    POD_ACK_Flag: "",
    VPP_Value: "",
    INS_Value: "",
    COD_Value: ""
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  const handleClear = () => {
    setValues({
      service: "",
      sourcepin: "",
      destinationpin: "",
      weight: "",
      length: "",
      breadth: "",
      height: "",
      POD_ACK_Flag: "",
      VPP_Value: "",
      INS_Value: "",
      COD_Value: ""
    });
    setErrors({});
  };

  return (
    <div className="p-3 h-[600px] overflow-y-auto">
      <form onSubmit={handleSubmit}>
        <h1 className="text-black font-bold text-[21px]">Tarif Form</h1>
        <div className="grid grid-cols-2 gap-5 mt-5">
          {Object.keys(values).map((field) => (
            <div key={field} className="flex flex-col mb-4">
              <label className="text-sm font-medium capitalize">
                {field.replace(/_/g, " ")} {field === "service" || field === "sourcepin" || field === "destinationpin" ? <span className="text-red-600">*</span> : ""}
              </label>
              <input
                type={field.includes("Value") || field === "weight" || field === "length" || field === "breadth" || field === "height" ? "number" : "text"}
                name={field}
                value={values[field]}
                onChange={handleChange}
                placeholder={`Enter ${field.replace(/_/g, " ")}`}
                className="border px-4 py-2 rounded-md"
              />
              {errors[field] && <div className="text-red-600 text-xs">{errors[field]}</div>}
            </div>
          ))}
        </div>
        <div className="flex justify-start mt-6">
          <button
            type="button"
            onClick={handleClear}
            className="bg-gray-500 text-white py-2 px-4 rounded-md mr-4"
          >
            Clear
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded-md"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Tarif;
