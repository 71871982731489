import React, { useEffect, useRef, useState } from "react";
import logo01 from "../../images/logo.png";
import { RiAccountCircleLine } from "react-icons/ri";

const AdminHeader = () => {
  
  return (
<div className="max-w-[1920px] w-full mx-auto shadow-lg bg-[#FFF5EC]">
    <div className="flex justify-center items-center h-20 ">
      <img src={logo01} alt="logo" className="h-16" />
      </div>
    </div>
  );
};

export default AdminHeader;
