import { apiConnector } from "../apiConnector";
import { endpoints } from "../api";


export const createContactUs=async(name,emailId,mobileNumber,subject,message)=>{
let result=[]


    try {
        const response = await apiConnector(
          "POST",
          endpoints.CREATE_CONTACT_US,
          {
             emailId,
             name,
             mobileNumber,
             subject,
            message
          },
          
        );
       
        result = response?.data;
        return result;
      } catch (error) {
        console.log(error);
      }
     
    };


