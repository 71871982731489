import React, { useState } from 'react';

const Booking = () => {
  const [values, setValues] = useState({
    identifier: '',
    articleid: '',
    articletype: '',
    articlelength: 0,
    articlewidth: 0,
    articleheight: 0,
    articleweight: 340.0,
    codvalue: 0.0,
    insurancevalue: 0.0,
    proofofdeliveryflag: '',
    customerid: '',
    contractnumber: '',
    sendername: '',
    senderaddressline1: '',
    senderaddressline2: '',
    senderaddressline3: '',
    sendercity: '',
    senderpincode: '',
    sendercountry: '',
    senderemail: '',
    sendermobile: '',
    nameofreceipient: '',
    receipientaddressline1: '',
    receipientaddressline2: '',
    receipientaddressline3: '',
    receipientcity: '',
    receipientpincode: '',
    receipientcountry: '',
    receipientemail: '',
    receipientmobile: '',
    refid: ''
  });

  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // handle form submission
  };

  const handleClear = (e) => {
    e.preventDefault();
    setValues({
      identifier: '9999999',
      articleid: '',
      articletype: '',
      articlelength: 0,
      articlewidth: 0,
      articleheight: 0,
      articleweight: 340.0,
      codvalue: 0.0,
      insurancevalue: 0.0,
      proofofdeliveryflag: '',
      customerid: '',
      contractnumber: '',
      sendername: '',
      senderaddressline1: '',
      senderaddressline2: '',
      senderaddressline3: '',
      sendercity: '',
      senderpincode: '',
      sendercountry: '',
      senderemail: '',
      sendermobile: '',
      nameofreceipient: '',
      receipientaddressline1: '',
      receipientaddressline2: '',
      receipientaddressline3: '',
      receipientcity: '',
      receipientpincode: '',
      receipientcountry: '',
      receipientemail: '',
      receipientmobile: '',
      refid: ''
    });
    setErrors({});
  };

  return (
    <div className='p-3 h-[600px] overflow-y-auto'>

    <form onSubmit={handleSubmit}>
      {/* Identifier */}
      <h1 className='text-black font-bold text-[21px]'>Booking Form </h1>
      <div className='grid grid-cols-2 gap-5 mt-5'>
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Identifier <span className="text-red-600">*</span></label>
        <input
          type="text"
          name="identifier"
          value="9999999"
          onChange={handleChange}
          placeholder="Enter Identifier"
          disabled
          className="border px-4 py-2 rounded-md"
        />
        {errors.identifier && <div className="text-red-600 text-xs">{errors.identifier}</div>}
      </div>

      {/* Article ID */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Article ID <span className="text-red-600">*</span></label>
        <input
          type="text"
          name="articleid"
          value={values.articleid}
          onChange={handleChange}
          placeholder="Enter Article ID"
          className="border px-4 py-2 rounded-md"
        />
        {errors.articleid && <div className="text-red-600 text-xs">{errors.articleid}</div>}
      </div>

      {/* Article Type */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Article Type <span className="text-red-600">*</span></label>
        <input
          type="text"
          name="articletype"
          value={values.articletype}
          onChange={handleChange}
          placeholder="Enter Article Type"
          className="border px-4 py-2 rounded-md"
        />
        {errors.articletype && <div className="text-red-600 text-xs">{errors.articletype}</div>}
      </div>

      {/* Article Length */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Article Length</label>
        <input
          type="number"
          name="articlelength"
          value={values.articlelength}
          onChange={handleChange}
          placeholder="Enter Article Length"
          className="border px-4 py-2 rounded-md"
        />
        {errors.articlelength && <div className="text-red-600 text-xs">{errors.articlelength}</div>}
      </div>

      {/* Article Width */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Article Width</label>
        <input
          type="number"
          name="articlewidth"
          value={values.articlewidth}
          onChange={handleChange}
          placeholder="Enter Article Width"
          className="border px-4 py-2 rounded-md"
        />
        {errors.articlewidth && <div className="text-red-600 text-xs">{errors.articlewidth}</div>}
      </div>

      {/* Article Height */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Article Height</label>
        <input
          type="number"
          name="articleheight"
          value={values.articleheight}
          onChange={handleChange}
          placeholder="Enter Article Height"
          className="border px-4 py-2 rounded-md"
        />
        {errors.articleheight && <div className="text-red-600 text-xs">{errors.articleheight}</div>}
      </div>

      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Article Weight</label>
        <input
          type="number"
          name="articleweight"
          value={values.articleweight}
          onChange={handleChange}
          placeholder="Enter Article Weight"
          className="border px-4 py-2 rounded-md"
        />
        {errors.articleweight && <div className="text-red-600 text-xs">{errors.articleweight}</div>}
      </div>

      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">COD Value</label>
        <input
          type="number"
          name="codvalue"
          value={values.codvalue}
          onChange={handleChange}
          placeholder="Enter COD Value"
          className="border px-4 py-2 rounded-md"
        />
        {errors.codvalue && <div className="text-red-600 text-xs">{errors.codvalue}</div>}
      </div>

      {/* Insurance Value */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Insurance Value</label>
        <input
          type="number"
          name="insurancevalue"
          value={values.insurancevalue}
          onChange={handleChange}
          placeholder="Enter Insurance Value"
          className="border px-4 py-2 rounded-md"
        />
        {errors.insurancevalue && <div className="text-red-600 text-xs">{errors.insurancevalue}</div>}
      </div>

      {/* Proof of Delivery Flag */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Proof of Delivery Flag</label>
        <input
          type="text"
          name="proofofdeliveryflag"
          value={values.proofofdeliveryflag}
          onChange={handleChange}
          placeholder="Enter Proof of Delivery Flag"
          className="border px-4 py-2 rounded-md"
        />
        {errors.proofofdeliveryflag && <div className="text-red-600 text-xs">{errors.proofofdeliveryflag}</div>}
      </div>

      {/* Customer ID */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Customer ID</label>
        <input
          type="text"
          name="customerid"
          value={values.customerid}
          onChange={handleChange}
          placeholder="Enter Customer ID"
          className="border px-4 py-2 rounded-md"
        />
        {errors.customerid && <div className="text-red-600 text-xs">{errors.customerid}</div>}
      </div>

      {/* Contract Number */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Contract Number</label>
        <input
          type="text"
          name="contractnumber"
          value={values.contractnumber}
          onChange={handleChange}
          placeholder="Enter Contract Number"
          className="border px-4 py-2 rounded-md"
        />
        {errors.contractnumber && <div className="text-red-600 text-xs">{errors.contractnumber}</div>}
      </div>

      {/* Sender Name */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Sender Name</label>
        <input
          type="text"
          name="sendername"
          value={values.sendername}
          onChange={handleChange}
          placeholder="Enter Sender Name"
          className="border px-4 py-2 rounded-md"
        />
        {errors.sendername && <div className="text-red-600 text-xs">{errors.sendername}</div>}
      </div>

      {/* Sender Address Line 1 */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Sender Address Line 1</label>
        <input
          type="text"
          name="senderaddressline1"
          value={values.senderaddressline1}
          onChange={handleChange}
          placeholder="Enter Sender Address Line 1"
          className="border px-4 py-2 rounded-md"
        />
        {errors.senderaddressline1 && <div className="text-red-600 text-xs">{errors.senderaddressline1}</div>}
      </div>

      {/* Sender Address Line 2 */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Sender Address Line 2</label>
        <input
          type="text"
          name="senderaddressline2"
          value={values.senderaddressline2}
          onChange={handleChange}
          placeholder="Enter Sender Address Line 2"
          className="border px-4 py-2 rounded-md"
        />
        {errors.senderaddressline2 && <div className="text-red-600 text-xs">{errors.senderaddressline2}</div>}
      </div>

      {/* Sender Address Line 3 */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Sender Address Line 3</label>
        <input
          type="text"
          name="senderaddressline3"
          value={values.senderaddressline3}
          onChange={handleChange}
          placeholder="Enter Sender Address Line 3"
          className="border px-4 py-2 rounded-md"
        />
        {errors.senderaddressline3 && <div className="text-red-600 text-xs">{errors.senderaddressline3}</div>}
      </div>

      {/* Sender City */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Sender City</label>
        <input
          type="text"
          name="sendercity"
          value={values.sendercity}
          onChange={handleChange}
          placeholder="Enter Sender City"
          className="border px-4 py-2 rounded-md"
        />
        {errors.sendercity && <div className="text-red-600 text-xs">{errors.sendercity}</div>}
      </div>

      {/* Sender Pincode */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Sender Pincode</label>
        <input
          type="text"
          name="senderpincode"
          value={values.senderpincode}
          onChange={handleChange}
          placeholder="Enter Sender Pincode"
          className="border px-4 py-2 rounded-md"
        />
        {errors.senderpincode && <div className="text-red-600 text-xs">{errors.senderpincode}</div>}
      </div>

      {/* Sender Country */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Sender Country</label>
        <input
          type="text"
          name="sendercountry"
          value={values.sendercountry}
          onChange={handleChange}
          placeholder="Enter Sender Country"
          className="border px-4 py-2 rounded-md"
        />
        {errors.sendercountry && <div className="text-red-600 text-xs">{errors.sendercountry}</div>}
      </div>

      {/* Sender Email */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Sender Email</label>
        <input
          type="email"
          name="senderemail"
          value={values.senderemail}
          onChange={handleChange}
          placeholder="Enter Sender Email"
          className="border px-4 py-2 rounded-md"
        />
        {errors.senderemail && <div className="text-red-600 text-xs">{errors.senderemail}</div>}
      </div>

      {/* Sender Mobile */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Sender Mobile</label>
        <input
          type="text"
          name="sendermobile"
          value={values.sendermobile}
          onChange={handleChange}
          placeholder="Enter Sender Mobile"
          className="border px-4 py-2 rounded-md"
        />
        {errors.sendermobile && <div className="text-red-600 text-xs">{errors.sendermobile}</div>}
      </div>

      {/* Recipient Name */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Recipient Name</label>
        <input
          type="text"
          name="nameofreceipient"
          value={values.nameofreceipient}
          onChange={handleChange}
          placeholder="Enter Recipient Name"
          className="border px-4 py-2 rounded-md"
        />
        {errors.nameofreceipient && <div className="text-red-600 text-xs">{errors.nameofreceipient}</div>}
      </div>

      {/* Recipient Address Line 1 */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Recipient Address Line 1</label>
        <input
          type="text"
          name="receipientaddressline1"
          value={values.receipientaddressline1}
          onChange={handleChange}
          placeholder="Enter Recipient Address Line 1"
          className="border px-4 py-2 rounded-md"
        />
        {errors.receipientaddressline1 && <div className="text-red-600 text-xs">{errors.receipientaddressline1}</div>}
      </div>

      {/* Recipient Address Line 2 */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Recipient Address Line 2</label>
        <input
          type="text"
          name="receipientaddressline2"
          value={values.receipientaddressline2}
          onChange={handleChange}
          placeholder="Enter Recipient Address Line 2"
          className="border px-4 py-2 rounded-md"
        />
        {errors.receipientaddressline2 && <div className="text-red-600 text-xs">{errors.receipientaddressline2}</div>}
      </div>

      {/* Recipient Address Line 3 */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Recipient Address Line 3</label>
        <input
          type="text"
          name="receipientaddressline3"
          value={values.receipientaddressline3}
          onChange={handleChange}
          placeholder="Enter Recipient Address Line 3"
          className="border px-4 py-2 rounded-md"
        />
        {errors.receipientaddressline3 && <div className="text-red-600 text-xs">{errors.receipientaddressline3}</div>}
      </div>

      {/* Recipient City */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Recipient City</label>
        <input
          type="text"
          name="receipientcity"
          value={values.receipientcity}
          onChange={handleChange}
          placeholder="Enter Recipient City"
          className="border px-4 py-2 rounded-md"
        />
        {errors.receipientcity && <div className="text-red-600 text-xs">{errors.receipientcity}</div>}
      </div>

      {/* Recipient Pincode */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Recipient Pincode</label>
        <input
          type="number"
          name="receipientpincode"
          value={values.receipientpincode}
          onChange={handleChange}
          placeholder="Enter Recipient Pincode"
          className="border px-4 py-2 rounded-md"
        />
        {errors.receipientpincode && <div className="text-red-600 text-xs">{errors.receipientpincode}</div>}
      </div>

      {/* Recipient Country */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Recipient Country</label>
        <input
          type="text"
          name="receipientcountry"
          value={values.receipientcountry}
          onChange={handleChange}
          placeholder="Enter Recipient Country"
          className="border px-4 py-2 rounded-md"
        />
        {errors.receipientcountry && <div className="text-red-600 text-xs">{errors.receipientcountry}</div>}
      </div>

      {/* Recipient Email */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Recipient Email</label>
        <input
          type="email"
          name="receipientemail"
          value={values.receipientemail}
          onChange={handleChange}
          placeholder="Enter Recipient Email"
          className="border px-4 py-2 rounded-md"
        />
        {errors.receipientemail && <div className="text-red-600 text-xs">{errors.receipientemail}</div>}
      </div>

      {/* Recipient Mobile */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Recipient Mobile</label>
        <input
          type="text"
          name="receipientmobile"
          value={values.receipientmobile}
          onChange={handleChange}
          placeholder="Enter Recipient Mobile"
          className="border px-4 py-2 rounded-md"
        />
        {errors.receipientmobile && <div className="text-red-600 text-xs">{errors.receipientmobile}</div>}
      </div>

      {/* Ref ID */}
      <div className="flex flex-col mb-4">
        <label className="text-sm font-medium">Ref ID</label>
        <input
          type="text"
          name="refid"
          value={values.refid}
          onChange={handleChange}
          placeholder="Enter Ref ID"
          className="border px-4 py-2 rounded-md"
        />
        {errors.refid && <div className="text-red-600 text-xs">{errors.refid}</div>}
      </div>

      {/* Submit and Clear Buttons */}
      </div>
      <div className="flex justify-start mt-6">
        {/* <button
          type="button"
          onClick={handleClear}
          className="bg-gray-500 text-white py-2 px-4 rounded-md"
        >
          Clear
        </button> */}
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded-md"
        >
          Submit
        </button>
      </div>
    </form>
    </div>
  );
};

export default Booking;
