import { apiConnector } from "../apiConnector";
import { createAddressEndPoints } from "../api";
import toast from "react-hot-toast";
export const createAddress = async (
  landmark,
  street,
  city,
  pincode,
  state,
  token,
  userId
) => {
  let result = [];
  try {
    const response = await apiConnector(
      "POST",
      createAddressEndPoints.ADD_ADDRESS,
      {
        landmark: landmark,
        street: street,
        city: city,
        pincode: pincode,
        state: state,
      },
      {
        Authorization: `Bearer ${token}`,
      },
      { userId: userId }
    );
    result = response?.data;
    toast.success("Address Added Successfully");
  } catch (error) {
    console.log(error);
  }
  return result;
};

export const getAllAddress = async (userId, token) => {
  let result = [];
 
  try {
    const response = await apiConnector(
      "GET",
      createAddressEndPoints.GET_ALL_ADDRESS,
      {},
      {
        Authorization: `Bearer ${token}`,
      },
      { userId: userId }
    );
    result = response.data;

    return result;
  } catch (error) {
    console.log("error", error);
  }
};
export const removeAddress = async (addressId, token, setIsButtonEnabled) => {
  let result;
  try {
    const response = await apiConnector(
      "DELETE",
      createAddressEndPoints.DELETE_ADDRESS,
      {},
      {
        Authorization: `Bearer ${token}`,
      },
      { addressId: addressId }
    );
    result = response.data;
    toast.success("Address Deleted Successfully");
    return result;
  } catch (error) {
    console.log("error", error);
    toast.error(
      "Addresss Cannot be Deleted, It is linked with Previous Orders"
    );
    setIsButtonEnabled(true);
    setTimeout(() => {
      setIsButtonEnabled(false);
    }, 2000);
  }
};
export const getAddressById = async (addressId, token) => {
 
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      createAddressEndPoints.GET_ADDRESS_BY_ID,
      {},
      {
        Authorization: `Bearer ${token}`,
      },
      { addressId: addressId }
    );
    result = response?.data;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};
export const editAddressById = async (
  landmark,
  street,
  city,
  pincode,
  state,
  addressId,
  token
) => {
  let result = [];

  try {
    const response = await apiConnector(
      "PUT",
      createAddressEndPoints.UPDATE_ADDRESS_BY_ID,
      {
        landmark: landmark,
        street: street,
        city: city,
        pincode: pincode,
        state: state,
      },
      {
        Authorization: `Bearer ${token}`,
      },
      {
        addressId: addressId,
      }
    );
  
    result = response?.data;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};
