import BannerLogo1 from "../images/banner_logo_1.png"
import BannerLogo2 from "../images/banner_logo_2.png"
import BannerLogo3 from "../images/banner_logo_3.png"
import BannerLogo4 from "../images/banner_logo_4.png"
import BannerLogo5 from "../images/banner_logo_5.png"

import HomeSlider01 from "../images/Lidkar Banner1.png"
// import HomeSlider02 from "../images/Lidkar Banner2.png"
import HomeSlider03 from "../images/Lidkar Banner3.png"
import HomeSlider04 from "../images/Lidkar Banner4.png"
import HomeSlider05 from  "../images/Lidkar Banner5.jpg"
import HomeSlider06 from  "../images/Lidkar banner6.png"
import HomeSlider07 from "../images/Banner5.png";
import HomeSlider08 from "../images/Banner6.png";
import HomeSlider09 from "../images/Banner7.png";
import QualityAssurance from "../images/QUALITY ASSURANCE.png";
import CustomerSatisfaction from "../images/CUSTOMER SATISFACTION.png";
import Trust from "../images/TRUST & RELIABILITY.png";
import safePayment from "../images/SECURE PAYMENT.png";
import shippingAndReturn from "../images/SAFE SHIPPING & EASY RETURN.png";
import Authencity from "../images/AUTHENTICITYGenuineLeather.png";

export const bannerItems = [
  {
    id: 1,
    title: "AUTHENTICITY 100% Genuine Leather",
    logo: Authencity
  },
  {
    id: 2,
    title: "TRUST & RELIABILITY",
    logo: Trust
  },

  {
    id: 3,
    title: "QUALITY ASSURANCE",
    logo: QualityAssurance
  },
  {
    id: 4,
    title: "CUSTOMER SATISFACTION",
    logo: CustomerSatisfaction
  },
  
  {
    id: 5,
    title: "SECURE PAYMENT",
    logo: safePayment
  },
  {
    id: 6,
    title: "SAFE SHIPPING & DELIVERY",
    logo: shippingAndReturn
  },
    
]

export const HomeSlider = [
  {
    id:1,
    images:HomeSlider03
  },
  // {
  //   id:2,
  //   images:HomeSlider02
  // },
  {
    id:3,
    images:HomeSlider04
  },
  {
    id:4,
    images:HomeSlider06
  },
  {
    id:5,
    images:HomeSlider07
  },
  {
    id:6,
    images:HomeSlider08
  },
  {
    id:7,
    images:HomeSlider09
  }

]