import { CreateLabel, getOrderDetailsById } from '../../services/operations/LogisticsApi';
import React, { useState,useEffect } from 'react';
import { useParams,useLocation, useNavigate } from "react-router-dom";

const Label = () => {
  const location =  useLocation()
 console.log("location", location.state.orderId);
 const navigate = useNavigate();
 const token = JSON.parse(localStorage.getItem("adminToken"));
 console.log("token", token);
 const [recipientAddress,setRecipientAddress] = useState({
  receipientaddressline1: '',
  receipientcity: '',
  receipientpincode: '',

 });
 const [articalId,setArticalId]  = useState('')
  const [values, setValues] = useState({
    senderName: "",
    senderAddressLine1: "",
    senderAddressLine2: "",
    senderAddressLine3: "",
    senderCity: "",
    senderPincode: "",
    senderMobile: "",
    nameOfReceipient: "",
    receipientAddressLine2: "",
    receipientAddressLine3: "",
    receipientMobile: "",
    receipientCountry: "",
    receipientState: "",
    weight: "",
    length: "",
    breadth: "",
    height: "",
    insuredValue: 0,
    codValue: 0,
    vppValue: 0
  });
  useEffect(()=>{
   
    const getOrderDetailsBy = async()=>{
      try{

        const response = await getOrderDetailsById(location.state.orderId, token);
        console.log(response,"getOrderDetailsBy")
        setArticalId(response.orderDTO.articleId)
        setRecipientAddress({
          receipientaddressline1: response?.addressDto?.landmark,
          receipientcity:response?.addressDto?.city,
          receipientpincode:response?.addressDto?.pincode
        })
      }catch (error){
          console.log(error)
      }
    }
    getOrderDetailsBy()
 },[location.state.orderId])
 console.log(recipientAddress,"recipientAddress")
  const [errors, setErrors] = useState({});
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };
  const validate = () => {
    const validationErrors = {};

    if (!values.senderName) validationErrors.senderName = "Sender Name is required.";
    if (!values.senderAddressLine1) validationErrors.senderAddressLine1 = "Sender Address Line 1 is required.";
    if (!values.senderCity) validationErrors.senderCity = "Sender City is required.";
    if (!values.senderPincode) validationErrors.senderPincode = "Sender Pincode is required.";
    if (!values.senderMobile) validationErrors.senderMobile = "Sender Mobile is required.";

    if (!values.nameOfReceipient) validationErrors.nameOfReceipient = "Recipient Name is required.";
    if (!values.receipientAddressLine2) validationErrors.receipientAddressLine2 = "Recipient Address Line 2 is required.";
    if (!values.receipientMobile) validationErrors.receipientMobile = "Recipient Mobile is required.";
    if (!values.receipientCountry) validationErrors.receipientCountry = "Recipient Country is required.";
    if (!values.receipientState) validationErrors.receipientState = "Recipient State is required.";

    if (!values.weight) validationErrors.weight = "Weight is required.";
    if (!values.length) validationErrors.length = "Length is required.";
    if (!values.breadth) validationErrors.breadth = "Breadth is required.";
    if (!values.height) validationErrors.height = "Height is required.";

    // if (!values.insuredValue) validationErrors.insuredValue = "Insured Value is required.";
    // if (!values.codValue) validationErrors.codValue = "COD Value is required.";
    // if (!values.vppValue) validationErrors.vppValue = "VPP Value is required.";

    return validationErrors;
};

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
          const labelObject = {
            articleNumber :articalId,
            service:"SP",
            senderName:values.senderName,
            senderAddressLine1:values.senderAddressLine1,
            senderAddressLine2:values.senderAddressLine2,
            senderAddressLine3:values.senderAddressLine3,
            senderCity:values.senderCity,
            senderPincode:values.senderPincode,
            senderMobile:values.senderMobile,
            nameOfReceipient:values.nameOfReceipient,
            receipientAddressLine2:values.receipientAddressLine2,
            receipientAddressLine3:values.receipientAddressLine3,
            receipientMobile:values.receipientMobile,
            receipientCountry:values.receipientCountry,
            receipientCity:recipientAddress.receipientcity,
            receipientAddressLine1:recipientAddress.receipientaddressline1,
            receipientPincode:recipientAddress.receipientpincode,
            receipientState:values.receipientState,
            weight:Number(values.weight),
            length:Number(values.length),
            breadth:Number(values.breadth),
            height:Number(values.height),
            insuredValue:Number(values.insuredValue),
            codValue:Number(values.codValue),
            vppValue:Number(values.vppValue)
          }
          console.log(labelObject,"labelObject")
          try{
            const response = CreateLabel(labelObject,token);
            console.log(response,"response")
            window.open(response.URL, '_blank', 'noopener,noreferrer');
            navigate("/admin/dashboard")

          }catch(error){
              console.log("error", error)
          }
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    setValues({
      // articleNumber: "EK990625280IN",
      service: "SP",
      senderName: "",
      senderAddressLine1: "",
      senderAddressLine2: "",
      senderAddressLine3: "",
      senderCity: "",
      senderPincode: "",
      senderMobile: "",
      nameOfReceipient: "",
      // receipientAddressLine1: "",
      receipientAddressLine2: "",
      receipientAddressLine3: "",
      // receipientCity: "",
      receipientMobile: "",
      receipientCountry: "",
      // receipientPincode: "",
      receipientState: "",
      weight: "",
      length: "",
      breadth: "",
      height: "",
      // customerID: "",
      // insuredValue: "",
      // codValue: "",
      // vppValue: ""
    });
    setErrors({});
  };

  return (
    <div className='p-3 h-[600px] overflow-y-auto'>
      <form onSubmit={handleSubmit}>
        <h1 className='text-black font-bold text-[21px]'>Label Form</h1>
        <div className='grid grid-cols-2 gap-5 mt-5'>

          
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Article Number <span className="text-red-600">*</span></label>
            <input
              type="text"
              name="articleNumber"
              value={articalId}
              disabled
              // onChange={handleChange}
              placeholder="Enter Article Number"
              className="border px-4 py-2 rounded-md"
            />
            {errors.articleNumber && <div className="text-red-600 text-xs">{errors.articleNumber}</div>}
          </div>

         
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Service <span className="text-red-600">*</span></label>
            <input
              type="text"
              name="service"
              value={values.service}
              onChange={handleChange}
              placeholder="Enter Service"
              className="border px-4 py-2 rounded-md"
            />
            {errors.service && <div className="text-red-600 text-xs">{errors.service}</div>}
          </div>

        
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender Name</label>
            <input
              type="text"
              name="senderName"
              value={values.senderName}
              onChange={handleChange}
              placeholder="Enter Sender Name"
              className="border px-4 py-2 rounded-md"
            />
            {errors.senderName && <div className="text-red-600 text-xs">{errors.senderName}</div>}
          </div>

       
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender Address Line 1</label>
            <input
              type="text"
              name="senderAddressLine1"
              value={values.senderAddressLine1}
              onChange={handleChange}
              placeholder="Enter Sender Address Line 1"
              className="border px-4 py-2 rounded-md"
            />
            {errors.senderAddressLine1 && <div className="text-red-600 text-xs">{errors.senderAddressLine1}</div>}
          </div>

          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender Address Line 2</label>
            <input
              type="text"
              name="senderAddressLine2"
              value={values.senderAddressLine2}
              onChange={handleChange}
              placeholder="Enter Sender Address Line 2"
              className="border px-4 py-2 rounded-md"
            />
            {errors.senderAddressLine2 && <div className="text-red-600 text-xs">{errors.senderAddressLine2}</div>}
          </div>

         
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender Address Line 3</label>
            <input
              type="text"
              name="senderAddressLine3"
              value={values.senderAddressLine3}
              onChange={handleChange}
              placeholder="Enter Sender Address Line 3"
              className="border px-4 py-2 rounded-md"
            />
            {errors.senderAddressLine3 && <div className="text-red-600 text-xs">{errors.senderAddressLine3}</div>}
          </div>

        
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender City</label>
            <input
              type="text"
              name="senderCity"
              value={values.senderCity}
              onChange={handleChange}
              placeholder="Enter Sender City"
              className="border px-4 py-2 rounded-md"
            />
            {errors.senderCity && <div className="text-red-600 text-xs">{errors.senderCity}</div>}
          </div>

        
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender Pincode</label>
            <input
              type="text"
              name="senderPincode"
              value={values.senderPincode}
              onChange={handleChange}
              placeholder="Enter Sender Pincode"
              className="border px-4 py-2 rounded-md"
            />
            {errors.senderPincode && <div className="text-red-600 text-xs">{errors.senderPincode}</div>}
          </div>

       
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Sender Mobile</label>
            <input
              type="text"
              name="senderMobile"
              value={values.senderMobile}
              onChange={handleChange}
              placeholder="Enter Sender Mobile"
              className="border px-4 py-2 rounded-md"
            />
            {errors.senderMobile && <div className="text-red-600 text-xs">{errors.senderMobile}</div>}
          </div>

          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Name</label>
            <input
              type="text"
              name="nameOfReceipient"
              value={values.nameOfReceipient}
              onChange={handleChange}
              placeholder="Enter Recipient Name"
              className="border px-4 py-2 rounded-md"
            />
            {errors.nameOfReceipient && <div className="text-red-600 text-xs">{errors.nameOfReceipient}</div>}
          </div>

     
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Address Line 1</label>
            <input
              type="text"
              disabled
              name="receipientAddressLine1"
              value={recipientAddress.receipientaddressline1}
              // onChange={handleChange}
              placeholder="Enter Recipient Address Line 1"
              className="border px-4 py-2 rounded-md"
            />
            {/* {errors.receipientAddressLine1 && <div className="text-red-600 text-xs">{errors.receipientAddressLine1}</div>} */}
          </div>

        
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Address Line 2</label>
            <input
              type="text"
              name="receipientAddressLine2"
              value={values.receipientAddressLine2}
              onChange={handleChange}
              placeholder="Enter Recipient Address Line 2"
              className="border px-4 py-2 rounded-md"
            />
            {errors.receipientAddressLine2 && <div className="text-red-600 text-xs">{errors.receipientAddressLine2}</div>}
          </div>

          
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Address Line 3</label>
            <input
              type="text"
              name="receipientAddressLine3"
              value={values.receipientAddressLine3}
              onChange={handleChange}
              placeholder="Enter Recipient Address Line 3"
              className="border px-4 py-2 rounded-md"
            />
            {errors.receipientAddressLine3 && <div className="text-red-600 text-xs">{errors.receipientAddressLine3}</div>}
          </div>

       
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient City</label>
            <input
              type="text"
              disabled
              name="receipientCity"
              value={recipientAddress.receipientcity}
              // onChange={handleChange}
              placeholder="Enter Recipient City"
              className="border px-4 py-2 rounded-md"
            />
            {/* {errors.receipientCity && <div className="text-red-600 text-xs">{errors.receipientCity}</div>} */}
          </div>

        
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Mobile</label>
            <input
              type="text"
              name="receipientMobile"
              value={values.receipientMobile}
              onChange={handleChange}
              placeholder="Enter Recipient Mobile"
              className="border px-4 py-2 rounded-md"
            />
            {errors.receipientMobile && <div className="text-red-600 text-xs">{errors.receipientMobile}</div>}
          </div>

        
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Country</label>
            <input
              type="text"
              name="receipientCountry"
              value={values.receipientCountry}
              onChange={handleChange}
              placeholder="Enter Recipient Country"
              className="border px-4 py-2 rounded-md"
            />
            {errors.receipientCountry && <div className="text-red-600 text-xs">{errors.receipientCountry}</div>}
          </div>

       
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient Pincode</label>
            <input
              type="text"
              name="receipientPincode"
              value={recipientAddress.receipientpincode}
              // onChange={handleChange}
              disabled
              placeholder="Enter Recipient Pincode"
              className="border px-4 py-2 rounded-md"
            />
            {/* {errors.receipientPincode && <div className="text-red-600 text-xs">{errors.receipientPincode}</div>} */}
          </div>

    
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Recipient State</label>
            <input
              type="text"
              name="receipientState"
              value={values.receipientState}
              onChange={handleChange}
              placeholder="Enter Recipient State"
              className="border px-4 py-2 rounded-md"
            />
            {errors.receipientState && <div className="text-red-600 text-xs">{errors.receipientState}</div>}
          </div>

         
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Weight</label>
            <input
              type="text"
              name="weight"
              value={values.weight}
              onChange={handleChange}
              placeholder="Enter Weight"
              className="border px-4 py-2 rounded-md"
            />
            {errors.weight && <div className="text-red-600 text-xs">{errors.weight}</div>}
          </div>

        
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Length</label>
            <input
              type="text"
              name="length"
              value={values.length}
              onChange={handleChange}
              placeholder="Enter Length"
              className="border px-4 py-2 rounded-md"
            />
            {errors.length && <div className="text-red-600 text-xs">{errors.length}</div>}
          </div>

        
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Breadth</label>
            <input
              type="text"
              name="breadth"
              value={values.breadth}
              onChange={handleChange}
              placeholder="Enter Breadth"
              className="border px-4 py-2 rounded-md"
            />
            {errors.breadth && <div className="text-red-600 text-xs">{errors.breadth}</div>}
          </div>

      
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Height</label>
            <input
              type="text"
              name="height"
              value={values.height}
              onChange={handleChange}
              placeholder="Enter Height"
              className="border px-4 py-2 rounded-md"
            />
            {errors.height && <div className="text-red-600 text-xs">{errors.height}</div>}
          </div>

        
          {/* <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Customer ID</label>
            <input
              type="text"
              name="customerID"
              value={values.customerID}
              onChange={handleChange}
              placeholder="Enter Customer ID"
              className="border px-4 py-2 rounded-md"
            />
            {errors.customerID && <div className="text-red-600 text-xs">{errors.customerID}</div>}
          </div> */}

         
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">Insured Value</label>
            <input
              type="text"
              name="insuredValue"
              value="0"
              disabled
              // onChange={handleChange}
              placeholder="Enter Insured Value"
              className="border px-4 py-2 rounded-md"
            />
            {/* {errors.insuredValue && <div className="text-red-600 text-xs">{errors.insuredValue}</div>} */}
          </div>

       
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">COD Value</label>
            <input
              type="text"
              name="codValue"
              value="0"
              disabled
              // onChange={handleChange}
              placeholder="Enter COD Value"
              className="border px-4 py-2 rounded-md"
            />
            {/* {errors.codValue && <div className="text-red-600 text-xs">{errors.codValue}</div>} */}
          </div>

          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium">VPP Value</label>
            <input
              type="text"
              name="vppValue"
              value="0"
              disabled
              // onChange={handleChange}
              placeholder="Enter VPP Value"
              className="border px-4 py-2 rounded-md"
            />
            {/* {errors.vppValue && <div className="text-red-600 text-xs">{errors.vppValue}</div>} */}
          </div>

         
        </div>
          <div className="flex justify-start gap-3 mt-4">
            
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={handleClear}
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md"
            >
              Clear
            </button>
          </div>
      </form>
    </div>
  );
};
export default Label
