import React from 'react'

const BrandLidkar = () => {
  return (
    <div className='max-w-[1920px] xl:px-[5rem] px-[1rem] py-2 mx-auto'>
         <h1 className='text-xl font-helvetica mb-4 mt-3 font-semibold'>BRAND LIDKAR</h1>
             <p className='mb-4   text-[14px] text-justify'>
             <span className="font-bold">Lidkar... is a </span>confluence of ancient, traditional, rich artistry, authentic leather and sui generis designs.
</p>
<p className='mb-4   text-[14px] text-justify'>Lidkar is an iconic, world-renowned, premium Indian leather brand, founded in the year 1976.</p>
             <p className='mb-4   text-[14px] text-justify'>
             Lidkar’s legendary lustre is primarily due to its folklore-inspired signature weave patterns inherent to few communities of local kannadiga artisans possessing the know-how and skill-set that has been passed down the generations. 

</p>
             <p className='mb-4   text-[14px] text-justify'>
             Lidkar is the archetypal embodiment of exquisite leather quality that defies the sands of time, finesse and master craftsmanship coupled with innovative, stylish and timeless designs, that has enamoured fashionistas and bewitched leather aficionados worldwide

</p>
             <p className='mb-4   text-[14px] text-justify'>
             Every single merchandise is a work of nuanced contemporary art, meticulously handcrafted by skilled artisans that breathe life into it and bequeath a story to fit your individuality for an everlasting companionship. Every product is in vogue with the fashion trends raging across the globe and a step ahead of the rest with a little more à la mode.

</p>
             <p className='mb-4   text-[14px] text-justify'>
             Lidkar is where India’s finest quality leather blends with eons of knowledge and mastery craftsmanship. Lidkar has a long history of bringing forth exquisite, high-quality and wide-array of leather products, including footwear, handbags, wallets, and belts. 
</p>
             <p className='mb-4   text-[14px] text-justify'>
             Lidkar today celebrates the patronage of leather connoisseurs spanning erstwhile royalty, celebrities and divas of Indian cinema, A-listers from sports, politics, art & culture, fashion world and other prominent personas from all walks of life.
</p>
             <p className='mb-4   text-[14px] text-justify'>
     
</p>
             <p className='mb-4   text-[14px] text-justify'>
             Lidkar is unequivocally a tableau vivant, where luxury meets comfort.
</p>
             <p className='mb-4   text-xl text-justify font-semibold'>
             FASHION MISSION : A perfect amalgamation of the past and the future!


</p>
             <p className='mb-4   text-[14px] text-justify'>
             Lidkar is an brand extraordinaire where the ancient traditional art forms  well-nigh consigned to oblivion are resurrected and infused with a new lease of life to the closely guarded, secretive primaeval leather eruditions that are met with futuristic designs and exigencies.
</p>
             <p className='mb-4   text-[14px] text-justify'>
             Crafting artisanal leather goods, the brand unveils and flaunts to the world, beaucoup distinctive leather weaving designs intrinsic to traditional artisans and leathersmiths, that instantly confers Lidkar it’s iconic mien and a distinctive panache. 
</p>
             <p className='mb-4   text-[14px] text-justify'>
            <span className='font-semibold text-xl'> QUALITY, TIMELESS AND ETERNAL</span><br/>
  A cornucopia of artisans still harness the closely-guarded secrets of traditional art of leather processing that’s been around for eons and passed down the generations as a family heirloom. The corollary is the exquisite natural texture, fine quality, par excellence tensile strength and a durability that defies the sands of time.

</p>
<div>


            
             <p className='mb-2   text-xl text-justify font-semibold'>
             EVOVLING INTO “THE BRAND”
</p>
<p className='mb-1   text-[14px] text-justify '>
              The Salient
</p>
             <p className='mb-1  text-[14px] text-justify'>
             Leather is Legendary
</p>
             <p className='mb-1  text-[14px] text-justify'>
             Leather is Luxury
</p>
             <p className='mb-1  text-[14px] text-justify'>
             Leather is LIDKAR
</p>
</div>
<p className='mb-4   text-[14px] text-justify'>

became the brand's fabled 3L slogan because the handcrafted Lidkar's merchandise are expeditiously distinguishable  and theretofore, the logo was displayed discreetly on the inside of its products.
     </p>       
    </div>
  )
}

export default BrandLidkar