import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebook, FaGoogle, FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoading, setToken, setUser } from "../../../src/redux/slices/authSlice";
import toast from "react-hot-toast";
import { endpoints } from "../../../src/services/api";




const AdminSignin  = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showVerify, setShowVerify] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [typingField, setTypingField] = useState(null);
  const [emailPlaceHolder, setEmailPlaceHolder] = useState("Enter Email");
  const [passwordPlaceHolder, setPasswordPlaceHolder] = useState("Enter Password");
  const [showPassword, setShowPassword] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showUserNotFound, setShowUserNotFound] = useState(false);
 


  const emailRegex = /^[\w-]+(?:\.[\w-]+)*@(?:[a-zA-Z0-9]+\.)+(?:com|in)$/;

  const validateForm = () => {
    const newErrors = {};
    if (!emailId.trim()) {
      newErrors.emailId = "Email should not be empty";
    } else if (!emailRegex.test(emailId)) {
      newErrors.emailId = "Enter a valid Email ID";
    }

    if (!password) {
      newErrors.password = "Password should not be empty";
    }

    return newErrors;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    console.log(emailId,password,'vada')

    try {
      const response = await axios.get(endpoints.ADMIN_SIGN_IN, {
        params: {
          emailId: emailId,
          password: password,
        },
      });
    

   
      localStorage.setItem("adminToken", JSON.stringify(response.data.accessToken));
      localStorage.setItem("adminData", JSON.stringify(response.data));
      dispatch(setLoading(false));

     

    
      

   
        navigate('/admin/dashboard');
        toast.success("Login Successful");
      
    } catch (error) {
    //   console.error("Login error:", error?.response?.data?.message);
    //   if (error?.response?.data?.message === "User not Found") {
    //     setShowUserNotFound(true);
    //   }
      if (error?.response?.data?.message === "Bad credentials") {
        setShowUserNotFound(false);
      }
      // if (error?.response?.data?.message === "User is not an Admin") {
       
      //   toast.error('The Entered User is not have Access to Admin Dashboard.');
      // }

      setShowError(true);
      setIsButtonDisabled(true);

      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 500);
    }
  };

  const handleChange = (setValue, value, field) => {
    setValue(value);
    setTypingField(field);
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    setShowError(false);
  };

  const handleBlur = (placeholderSetter, value, initialValue) => {
    if (!value) {
      placeholderSetter(initialValue);
    }
  };

  const handleFocus = (placeholderSetter) => {
    placeholderSetter("");
  };

  return (
    <div className="mx-auto max-w-lg mb-4 mt-1 p-5">
      <h1 className="text-2xl font-helvetica text-center mb-1 md:text-3xl text-black-700">
        Login
      </h1>

      <div className="bg-white border border-gray-300 rounded-lg p-6 mt-5 mx-auto inline-block w-full">
        <div className="w-full mx-auto mt-1">
          {showError && !showUserNotFound && !showVerify && (
            <div className="text-red-500 text-center">Invalid Credentials</div>
          )}
          {showUserNotFound && (
            <div className="text-red-500 text-center">Email Doesn't Exist</div>
          )}

          <form onSubmit={onSubmit}>
            <div className="flex flex-col justify-center pb-3">
              <label className="font-semibold text-base text-gray-700 mb-4" placeholder="Enter Email">
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={emailId}
                onChange={(e) => handleChange(setEmailId, e.target.value, "emailId")}
                onFocus={() => handleFocus(setEmailPlaceHolder)}
                onBlur={() => handleBlur(setEmailPlaceHolder, emailId, "Enter Email")}
                autoFocus
                className={`rounded-[7px] ${
                  errors.emailId ? "border-red-500" : "border-gray-200"
                } border p-2 placeholder-gray-400 pl-4 cursor-text`}
                placeholder={emailPlaceHolder}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    document.getElementById("passwordInput").focus();
                  }
                }}
              />
              {errors.emailId && (
                <p className="text-red-500 text-xs">{errors.emailId}</p>
              )}
            </div>

            <div className="flex relative flex-col justify-center pb-3">
              <label className="font-semibold text-base text-gray-700 mb-2" placeholder="Enter Password">
                Password <span className="text-red-500">*</span>
              </label>
              <div className="w-full relative">
                <input
                  id="passwordInput"
                  type={showPassword ? "text" : "password"}
                  placeholder={passwordPlaceHolder}
                  value={password}
                  onChange={(e) => handleChange(setPassword, e.target.value, "password")}
                  onFocus={() => handleFocus(setPasswordPlaceHolder)}
                  onBlur={() =>
                    handleBlur(setPasswordPlaceHolder, password, "Enter Password")
                  }
                  className={`rounded-[7px] ${
                    errors.password ? "border-red-500" : "border-gray-200"
                  } w-full border p-2 placeholder-gray-400 pr-10 pl-4 cursor-text`}
                />
                {errors.password && (
                  <p className="text-red-500 text-xs">{errors.password}</p>
                )}
                <div className="absolute right-3 mt-[.5rem] top-3 transform -translate-y-1/2 cursor-pointer">
                  {showPassword ? (
                    <FaEye
                      onClick={() => setShowPassword(false)}
                      className="text-gray-400"
                    />
                  ) : (
                    <FaEyeSlash
                      onClick={() => setShowPassword(true)}
                      className="text-gray-400"
                    />
                  )}
                </div>
              </div>
            </div>


            <button
              type="submit"
              disabled={isButtonDisabled}
              className="bg-[#F9C88F] hover:bg-[#F6B97B] w-full px-2 py-2 rounded-[15px] mt-2 text-black"
            >
              Login
            </button>
          </form>
        </div>
      
      </div>
    </div>
  );
};

export default AdminSignin;
