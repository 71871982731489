import React, { useState } from "react";
import DescriptionTab from "./DescriptionTab";
import ShippingAndReturnTab from "./ShippingAndReturnTab";
import MoreInfoTab from "./MoreInfoTab";



const Tab = ({ product }) => {
  const [activeTab, setActiveTab] = useState(null);

  const toggleContent = (tab) => {
    setActiveTab(activeTab === tab ? null : tab);
  };

  return (
    <div className="w-full sm:w-full lg:w-[65%] lg:mt-10 flex flex-col md:flex-row">
    <div className="w-full lg:w-[60%] flex flex-col items-start">
      <div className="w-full border-t border-gray-300"></div>
  
      <div
        className="flex w-full items-center p-1 justify-between cursor-pointer"
        onClick={() => toggleContent("reviews")}
      >
        <span className="text-black font-helvetica text-sm uppercase">
          Reviews
        </span>
        <button className="font-helvetica text-xl font-bold focus:outline-none">
          {activeTab === "reviews" ? "-" : "+"}
        </button>
      </div>
      <div
        className={`w-full scrollbar overflow-y-auto  ${
          activeTab === "reviews" ? "h-[270px] pr-4 pl-0 pb-4 pt-4 bg-white" : "max-h-0"
        }`}
      >
        {activeTab === "reviews" && <DescriptionTab product={product} />}
      </div>
  
      <div className="w-full border-t border-gray-300"></div>
  
      <div
        className="flex w-full justify-between items-center p-1 rounded-md cursor-pointer"
        onClick={() => toggleContent("shippingAndReturn")}
      >
        <span className="text-black w-[260px] font-helvetica text-sm uppercase">
          Shipping And Return
        </span>
        <button className="font-helvetica text-xl font-bold focus:outline-none">
          {activeTab === "shippingAndReturn" ? "-" : "+"}
        </button>
      </div>
      <div
        className={`w-full overflow-hidden  ${
          activeTab === "shippingAndReturn" ? "pr-4 pl-0 pb-4 pt-4 h-auto  bg-white" : "max-h-0"
        }`}
      >
        {activeTab === "shippingAndReturn" && <ShippingAndReturnTab />}
      </div>
  
      <div className="w-full border-t border-gray-300"></div>
  
      <div
        className="flex w-full justify-between items-center p-1 cursor-pointer"
        onClick={() => toggleContent("moreInfo")}
      >
        <span className="text-black w-[90px] font-helvetica text-sm uppercase">
          More Info
        </span>
        <button className="text-xl font-bold focus:outline-none">
          {activeTab === "moreInfo" ? "-" : "+"}
        </button>
      </div>
      <div
        className={`w-full overflow-hidden  ${
          activeTab === "moreInfo" ? "h-auto pr-4 pl-0 pb-4 pt-4 bg-white" : "max-h-0"
        }`}
      >
        {activeTab === "moreInfo" && <MoreInfoTab />}
      </div>
    </div>
  </div>
  
  );
};

export default Tab;
