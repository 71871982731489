import { useSelector } from 'react-redux';
import { GET_Order_Placed } from '../../src/services/operations/orderApi';
import { FaRegEye } from "react-icons/fa";

import React,{useEffect, useState} from 'react'
import { DataGrid, gridColumnGroupsUnwrappedModelSelector } from '@mui/x-data-grid';
import SingleOrderModel from '../components/models/SingleOrderModel';
import SingleOrderAdminModel from '../components/models/SingleOrderAdminModel';
import PickUp from '../components/Admin/PickUp';
import { useNavigate } from 'react-router-dom';
const Orders = () => {
    const [allOrders,setAllOrders] = useState([]);
    const token = JSON.parse(localStorage.getItem("adminToken"));

  const [visibility, setVisibility] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 9,
    page: 0,
  });
    useEffect(() => {
        const fetchOrders = async () => {
          try {
            const getOrders = await GET_Order_Placed(token);
            const updatedOrders = getOrders.map((item) => ({ ...item, id: item.orderId }));
            console.log(updatedOrders, "updatedOrders")
            setAllOrders(updatedOrders);
          
          } catch (error) {
            console.log("error", error);
          }
        };
      
        fetchOrders();
      }, []);
      const handleView = (orderId) =>{
        return (
              <SingleOrderModel
     orderId={orderId}
     visibility={visibility}
     setVisibility={setVisibility}
   />
        )

      }
      const handlePickup = (id)=>{
        navigate('pickup',{state:{orderId:`${id}`}})

      }
      const handleLabel = (id) =>{
        navigate('label',{state:{orderId:`${id}`}})
           
      }
      const navigate = useNavigate();
     const columns = [
        { 
            field: "serialNo", 
            headerName: "S.No", 
            width: 100,
            headerClassName: "custom-header",
            renderCell: (params) => {
                return params.api.getSortedRowIds().indexOf(params.id) + 1;
            }
            },
          {
            field: "orderNumber",
            headerName: "Order Number",
            flex: 1,
            headerClassName: "custom-header",
            cellClassName: "name-column--cell",
          },
          {
            field: "userName",
            headerName: "User Name",
            flex: 1,
            headerClassName: "custom-header",
            cellClassName: "name-column--cell",
          },
          {
            field: "emailId",
            headerName: "Email Id",
            flex: 1,
            headerClassName: "custom-header",
            cellClassName: "name-column--cell",
          },
          {
            field: "mobileNumber",
            headerName: "Mobile Number",
            flex: 1,
            headerClassName: "custom-header",
            cellClassName: "name-column--cell",
          },
          {
            field: "totalPrice",
            headerName: "Total Price",
            flex: 1,
            headerClassName: "custom-header",
            cellClassName: "name-column--cell",
          },
          {
            field: "paymentStatus",
            headerName: "Payment Status",
            flex: 1,
            headerClassName: "custom-header",
            cellClassName: "name-column--cell",
          },
          {
            field: "PickUp",
            headerName: "Pick Up",
            // flex: 1,
            width:180,
            headerClassName: "custom-header",
            renderCell: (params) => {
                console.log("Params Object:", params.row); // Log the params object here
        
                return params?.row?.articleStatus ? (
                    <button
                        className="w-[140px] h-[21px] mt-4 rounded-md bg-green-500 flex justify-center items-center"
                        onClick={() => handleLabel(params.row.id)}
                    >
                        Generate Label
                    </button>
                ) : (
                    <button
                        className="w-[140px] h-[21px] mt-4 rounded-md bg-gray-500 flex justify-center items-center"
                        onClick={() => handlePickup(params.row.id)}
                    >
                        Generate Pickup
                    </button>
                );
            },
        },
        
          {
            field: "View",
            headerName: "View",
            width: 100,
            headerClassName: "custom-header",
            renderCell: (params) => (
              // <FaRegEye
              //   className="cursor-pointer"
              //   style={{ marginTop: "14px" }}
              //   onClick={() => handleView(params.row.id)}
              // />
              <SingleOrderAdminModel
              orderId={params.row.id}
              visibility={visibility}
              isAdminOrder={"isAdminOrder"}
              setVisibility={setVisibility}
            />
            ),
          },
     ];

 
  return (
    <div>

<DataGrid    
        rows={allOrders} 
        columns={columns} 
        pagination
        paginationMode="client" // Enables client-side pagination
        paginationModel={paginationModel} // For MUI v5 and above
        onPaginationModelChange={setPaginationModel} // Updates pagination state
        rowsPerPageOptions={[5, 10, 20]} // Dropdown options for rows per page

        />
    </div>
  )
}

export default Orders;