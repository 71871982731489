import { Link } from "react-router-dom";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { useSelector } from "react-redux";
import { updateCartItemByColor } from "../../services/operations/cartItemApi";
import { useState } from "react";
const SelectColorModel = ({
  cartDetails,
  cartItemId,
  colorDTO,
  fetchAgain,
}) => {
  const [selectedColor, setSelectedColor] = useState(null);
  const [open, setOpen] = useState(false);
  const { token } = useSelector((state) => state.auth);

  var singleCart = cartDetails?.filter(
    (cart) => cart?.cartItemId === cartItemId
  );

  const colorMap = {
    "Dark Brown": "#5C4033",
    "Dark Blue": "#00008B",
    "Dark Green": "#006400",
    "White": "#FFFFFF",
    "Tan Brown": "#A52A2A",
    "Maroon": "#800000"
  };

  const handleConfirmColor = () => {
    const fetchData = async () => {
      try {
        const response = await updateCartItemByColor(
          token,
          cartItemId,
          selectedColor
        );
        fetchAgain();
        setOpen(false);

      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
      }
    };
    fetchData();
  };
  const handleSelectColor = (id) => {
    setSelectedColor(id);
  };
  return (
    <Dialog open={open} onOpenChange={setOpen} className="w-[300px]">
      <DialogTrigger asChild>
        <p className="text-blue-500 text-lg text-right cursor-pointer">Color</p>
      </DialogTrigger>
      <DialogContent className="max-w-[350px] sm:max-w-[560px] ">
        <DialogHeader>
          <div>
            {singleCart?.map((cartData, index) => (
              <>
                <div className="flex flex-col sm:flex-row justify-start items-center gap-12">
                  <div className="w-[140px] h-[150px] bg-[#EFEFEF] rounded-md ">
                    <img
                      src={cartData?.productDTO?.productImage[0]?.imagePath}
                    />
                  </div>
                  <div className="self-center">
                    <p className="text-[#636363]  font-prompt">
                      {cartData?.productDTO?.categoryDTO?.categoryName}
                    </p>
                    <p className="text-[#303030] font-prompt text-[20px] font-semibold ">
                      {cartData?.productDTO?.productName}
                    </p>
                    <div className="flex gap-6">
                      <p className="text-[27px] text-[#000000] font-prompt font-bold">
                        {" "}
                        {Math.round(cartData?.productDTO?.sellingPrice)}

                      </p>
                      {cartData?.productDTO?.discount != 0 && (
                        <p className="text-[24px] text-[#514F4F] font-prompt">
                          MRP 
                          <span className="line-through text-red-500">
                            {" "}
                            ₹{cartData?.productDTO?.mrpPrice}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-[300px] sm:w-fit">
                  <hr className="mt-5" />
                  <h1 className="font-bold text-[#303030] text-[24px] font-prompt ">
                    Select Color
                  </h1>
                  <div className="flex items-center justify-start   gap-6 mt-3">
                    {cartData?.productDTO?.colors?.map((color, index) => (
                      <p
                        key={index}
                        className={`h-[41px] w-[41px] border rounded-full text-[black] flex items-center justify-center cursor-pointer ${
                          color?.colorId === selectedColor
                            ? " border-2 border-green-800"
                            : "border-slate-500"
                        }`}
                        style={{ background: colorMap[color?.colorName] || color?.colorName }}
                        onClick={() => handleSelectColor(color?.colorId)}
                      >
                        {}
                      </p>
                    ))}
                  </div>

                  <button
                    className="w-full sm:w-[490px] mt-5  h-[30px] py-6  rounded-md bg-[#56243B] font-prompt text-[white] border border-[#56243B] flex items-center justify-center hover:bg-[white] hover:text-[black]"
                    onClick={handleConfirmColor}
                  >
                    DONE
                  </button>
                </div>
              </>
            ))}
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default SelectColorModel;
