import React from 'react';
import video1 from "../images/Video.mp4"
import video2 from "../images/video02.mp4"
const Gallery = () => {
  return (
    <div className="w-full py-2 sm:px-[5rem] sm:py-2 mt-5">
      <div className="grid gap-9 grid-cols-1 sm:grid-cols-2 mt-10 w-full overflow-hidden">
        {/* First Video */}
        <div className="relative w-full">
          <video
            src={video1} // Replace with your video path
            controls
            className="w-full rounded-lg h-[500px] shadow-md"
          ></video>
        </div>

        {/* Second Video */}
        <div className="relative w-full">
          <video
            src={video2} // Replace with your video path
            controls
            className="w-full rounded-lg h-[500px] shadow-md"
          ></video>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
