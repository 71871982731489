import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";


export const navItems = [
  {
    title: 'HOME', path: '/',  icon: <MdKeyboardArrowDown />, className: 'flex mt-1 justify-center items-center gap-3',
    dropdownItems: [
      { title: 'About us'},
      { title: 'History'},
      { title: 'Mission'},
      { title: 'Craftmanship'},
    ],
    onClick: () => window.scrollTo({ top: 0, behavior: "smooth" })
  },
  
  { title: 'SHOP', path: '#', icon: <MdKeyboardArrowDown />, className: 'flex justify-center items-center gap-3' },

  {
    title: 'FOOTWEAR',
    icon: <MdKeyboardArrowDown />,
     path: '#',
    className: 'flex  justify-center items-center gap-3',
    dropdownItems: [
      { title: 'Gents' },
      { title: 'Ladies' },
      { title: 'Kids' },
    ]
  },

  
  // { title: 'GARMENTS', icon: <MdKeyboardArrowDown />, path: "/shop/products", className: 'flex justify-center items-center gap-3' },
  { title: 'BAGS', icon: <MdKeyboardArrowDown />, path:"#", className: 'flex justify-center items-center gap-3' },
  {
    title: 'ACCESSORIES', icon: <MdKeyboardArrowDown />,  path:'#', className: 'flex justify-center items-center gap-3', dropdownItems: [
      { title: 'Bags' },
      { title: 'Wallets' },
      { title: 'Key Chain' },
    ]
  },

  { title: 'UNIQUE COLLECTIONS', icon: <MdKeyboardArrowDown />, path: "#", className: 'flex justify-center items-center gap-3' },
  // { title: 'LEATHER FOOTWARE', icon: <MdKeyboardArrowDown />, path: "/shop/products", className: '' },
  
  // { title: 'SPORTS', path: '', icon: null, className: '' },
  // { title: 'KIDS', path: '', icon: null, className: '' },
  { title: 'SPORTS', path: '#', icon: null, className: 'flex justify-center items-center gap-3' },
  { title: 'HEALTH CARE', path: '#', icon: <MdKeyboardArrowDown />, className: 'flex justify-center items-center gap-3' },
  { title: 'CUSTOMIZED PRODUCTS', icon: null, path: "/customized", className: 'flex justify-center items-center gap-3' },
  // {
  //   title: 'GOODS',
  //   icon: <MdKeyboardArrowDown />,
  //   className: 'flex justify-center items-center gap-3',
  //   dropdownItems: [
  //     { title: 'Ladies HandBag' },
  //     { title: 'Sling Bag' },
  //     { title: 'Card Holder' },
  //     { title: 'Belts' },
  //     { title: 'Win Case' },
  //     { title: 'Travel Bags' },
  //     { title: 'Purses' },


  //   ]
  // },
  // { title: 'FIND A STORE', path: '/shop/products', icon: null, className: 'flex justify-center items-center gap-3' },

  // { title: 'CONTACT US', path: '/contact-us', icon: null, className: '' }
];


