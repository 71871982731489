
import React, { useState,useRef } from 'react';

const FindStorePage = () => {
  const [selectedLocation, setSelectedLocation] = useState("bangalore");
  const addressRef = useRef(null); 
  const handleLocationClick = (location) => {
    console.log("location",location)
    setSelectedLocation(location);
    if (addressRef.current) {
      addressRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const locations = {
    bangalore: [
      {
        name: 'Lidkar Leather Jayanagar',
        address: 'No.11, Shopping Complex, Jayanagar.',
        pincode: 'Bangalore - 560011',
        mobile: '9880486220',
        googleMapsLink: 'https://www.google.com/maps?q=Jayanagar+Shopping+Complex,+near+SBM,+4th+T+Block+East,+4th+Block,+Jayanagar,+Bengaluru,+Karnataka+560011',
      },
      {
        name: 'Lidkar Leather Vasanthnagar',
        address: 'LLE No.32 Dr. Babu Jagjivan Ram Lidkar Bhavan, Vasanthnagar.',
        pincode: 'Bangalore - 560052',
        mobile: '9886548880',
        googleMapsLink: 'https://www.google.com/maps?q=XHRW+8M5,+Millers+Tank+Bund+Rd,+Govinda+Chetty+Colony,+Vasanth+Nagar,+Bengaluru,+Karnataka+560051',
      },
      {
        name: 'Lidkar Leather Rajajinagar',
        address: 'No.717, 12th Main Road 42 Cross 3rd Block Terapanth Bhavan Rajajinagar.',
        pincode: 'Bangalore - 560010',
        mobile: '9535186515',
        googleMapsLink: 'https://maps.app.goo.gl/pMVmciwtwLivhmpM8'

      },
      {
        name: 'Lidkar Leather MG Road',
        address: 'Karnataka State Arts and Cafts Emporium, MG Road.',
        pincode: 'Banglore-560001',
        mobile: '8660412905',
        googleMapsLink: 'https://www.google.com/maps?q=45,+Mahatma+Gandhi+Rd,+Shanthala+Nagar,+Ashok+Nagar,+Bengaluru,+Karnataka+560001',      },
      {
        name: 'Lidkar Leather Vajarahalli',
        address: 'LLE Kanakapura Road, Vajarahalli.',
        pincode: 'Banglore-560002',
        mobile: '9980610247',
        googleMapsLink: 'https://maps.app.goo.gl/mRfjbxuKGue5NiBv6',
      },
      {
        name: 'Lidkar Leather Subhashnagar',
        address: 'BBMP Complex, Behind Upparpet Police Station, Subhashnagar.',
        pincode: 'Bangalore - 560009',
        mobile: '8722848750',
        googleMapsLink: 'https://maps.app.goo.gl/PaiSQ1evNRAXTzU4A',
      },
    ],
    shivamogga: [
      {
        name: 'Lidkar Leather Shivamogga',
        address: 'Basava Sadan Complex Sho No 1, Nehru Road, Nehru Road Shimoga.',
        pincode: 'Shivamogga-577201',
        mobile: '8762883941',
        googleMapsLink: 'https://maps.app.goo.gl/qvEqLpR7LD5dgykV9',
      },
    ],
    chitradurga: [
      {
        name: 'Lidkar Leather Chitradurga',
        address: 'Complex B.D Road Near Travellers Bungalow.',
        pincode: 'Chitradurga -577501',
        mobile: '9449181136',
        googleMapsLink: 'https://maps.app.goo.gl/WGqgkQHnGDC4jk426',
      },
    ],
    tumkur: [
      {
        name: 'Lidkar Leather Tumkur',
        address: 'Shop No.22, Red Cross Bhavan, Ashokanagar.',
        pincode: 'Tumkur - 572101',
        mobile: '8105740125',
        googleMapsLink: 'https://maps.app.goo.gl/8ivAp1w76XFaNLdN6',
      },
    ],
    davanagere: [
      {
        name: 'Lidkar Leather Davanagere',
        address: 'No.8, Tennies Court Complex, Gvt. Boys PU Colleage Ground, 2nd Main, Akkamahadevi Road PG Extn.',
        pincode: 'Davanagere-577201',
        mobile: '9480886275',
        googleMapsLink: 'https://maps.app.goo.gl/PQxWGkUD5gbcgnHL7',
      },
    ],
    mysore: [
      {
        name: 'Lidkar Leather Mysore',
        address: 'CTI Building, Sayyajirao Road,Yadavagiri, Mysuru.',
        pincode: 'Davanagere-577201',
        mobile: '7411151118',
        googleMapsLink: 'https://maps.app.goo.gl/v8mYecRB2CurDQrG6',
      },
    ],
    chikkamagaluru: [
      {
        name: 'Lidkar Leather Chikkamagaluru',
        address: 'A.T.I Complex, Mallamdur Road, N.M.C Circle Chikkamagalore.',
        pincode: 'Chikkamagaluru - 577101',
        mobile: '9945445556',
        googleMapsLink: 'https://maps.app.goo.gl/vYCq1n4vM9WHHpyf8',
      },
    ],
    belagavi: [
      {
        name: 'Lidkar Leather Belagavi',
        address: 'SWD Neharu Nagara, Near Ramdev Hotel KLE Road Belagavi.',
        pincode: 'Belagavi - 590001',
        mobile: '9482204802',
        googleMapsLink: 'https://maps.app.goo.gl/XvKBd8Kqaxh5Tex99',
      },
    ],
    hubli: [
      {
        name: 'Lidkar Leather Hubli',
        address: 'Shop No.209, IT park Complex Hosur NH4.',
        pincode: 'Hubli - 580029',
        mobile: '9353863947',
        googleMapsLink: 'https://maps.app.goo.gl/fUuwheY4L3YPx8Fo9',
      },
    ],
    gulbarga: [
      {
        name: 'Lidkar Leather Gulbarga',
        address: 'Janatha Bazaar Complex Super Market.',
        pincode: 'Gulbarga - 582101',
        mobile: '7338387273',
        googleMapsLink: 'https://maps.app.goo.gl/9fmRiqdc33amJaS86',
      },
    ],
    bellary: [
      {
        name: 'Lidkar Leather Bellary',
        address: 'No.24, ‘B’ Block, Buda Complex, Ground Floor.',
        pincode: 'Bellary - 583101',
        mobile: '8660667128',
        googleMapsLink: 'https://maps.app.goo.gl/ryT9oEGNDBG3XySRA',
      },
    ],
    raichur: [
      {
        name: 'Lidkar Leather Raichur',
        address: 'No.2, Mahila Samaja complex, Jail Road.',
        pincode: 'Raichur - 584101',
        mobile: '9980238737',
        googleMapsLink: 'https://maps.app.goo.gl/oYiEzdmbDJsovfvy9',
      },
    ],
  };

  return (
    <div className="max-w-[1920px] mx-auto px-[1rem] lg:px-[5rem] py-2">
      <h1 className="text-center font-Montserrat font-bold text-base mb-3 mt-[41px]">
        OUR EMPORIA
      </h1>

{/* Buttons for Location Selection */}
<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
  <button
    onClick={() => handleLocationClick('bangalore')}
    className={` text-black px-4 py-2 rounded ${selectedLocation === 'bangalore' ? 'bg-[#FBD3A4]' : 'bg-gray-300 text-black'}`}
  >
    Bangalore (6)
  </button>
  <button
    onClick={() => handleLocationClick('shivamogga')}
    className={` text-black px-4 py-2 rounded ${selectedLocation === 'shivamogga' ? 'bg-[#FBD3A4] text-black' : 'bg-gray-300 text-black'}`}
  >
    Shivamogga
  </button>
  <button
    onClick={() => handleLocationClick('chitradurga')}
    className={` text-black px-4 py-2 rounded ${selectedLocation === 'chitradurga' ? 'bg-[#FBD3A4] text-black' : 'bg-gray-300 text-black'}`}
  >
    Chitradurga
  </button>
  <button
    onClick={() => handleLocationClick('tumkur')}
    className={` text-black px-4 py-2 rounded ${selectedLocation === 'tumkur' ? 'bg-[#FBD3A4] text-black' : 'bg-gray-300 text-black'}`}
  >
    Tumkur 
  </button>
  <button
    onClick={() => handleLocationClick('davanagere')}
    className={` text-black px-4 py-2 rounded ${selectedLocation === 'davanagere' ? 'bg-[#FBD3A4] text-black' : 'bg-gray-300 text-black'}`}
  >
    Davanagere 
  </button>
  <button
    onClick={() => handleLocationClick('mysore')}
    className={` text-black px-4 py-2 rounded ${selectedLocation === 'mysore' ? 'bg-[#FBD3A4] text-black' : 'bg-gray-300 text-black'}`}
  >
    Mysore 
  </button>
  <button
    onClick={() => handleLocationClick('chikkamagaluru')}
    className={` text-black px-4 py-2 rounded ${selectedLocation === 'chikkamagaluru' ? 'bg-[#FBD3A4] text-black' : 'bg-gray-300 text-black'}`}
  >
    Chikkamagaluru
  </button>
  <button
    onClick={() => handleLocationClick('belagavi')}
    className={` text-black px-4 py-2 rounded ${selectedLocation === 'belagavi' ? 'bg-[#FBD3A4] text-black' : 'bg-gray-300 text-black'}`}
  >
    Belagavi
  </button>
  <button
    onClick={() => handleLocationClick('hubli')}
    className={`text-black px-4 py-2 rounded ${selectedLocation === 'hubli' ? 'bg-[#FBD3A4] text-black' : 'bg-gray-300 text-black'}`}
  >
    Hubli
  </button>
  <button
    onClick={() => handleLocationClick('gulbarga')}
    className={` text-black px-4 py-2 rounded ${selectedLocation === 'gulbarga' ? 'bg-[#FBD3A4] text-black' : 'bg-gray-300 text-black'}`}
  >
    Gulbarga
  </button>
  <button
    onClick={() => handleLocationClick('bellary')}
    className={` text-black px-4 py-2 rounded ${selectedLocation === 'bellary' ? 'bg-[#FBD3A4] text-black' : 'bg-gray-300 text-black'}`}
  >
    Bellary
  </button>
  <button
    onClick={() => handleLocationClick('raichur')}
    className={` text-black px-4 py-2 rounded ${selectedLocation === 'raichur' ? 'bg-[#FBD3A4] text-black' : 'bg-gray-300 text-black'}`}
  >
    Raichur
  </button>
</div>


      <div ref={addressRef} className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-2 md:p-4">
        {
          locations[selectedLocation]?.map((store, index) => (
            <div key={index} className="p-4 text-start shadow-md border border-gray-300">
             
              <p className="text-black font-Montserrat">{store.address}</p>
              <p className="text-black font-Montserrat">{store.pincode}</p>
              <p className="text-black font-Montserrat">Mob: {store.mobile}</p>
              <a
                href={store.googleMapsLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-yellow-950 underline"
              >
                Directions
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FindStorePage;


